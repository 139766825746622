<template>
  <div>
    <div class="ml-5 text-11 text-gray-light lg:w-88 lg:text-13">
        Выбрать тип оплаты в рассрочку или кредит на 3 или 6 месяцев вы сможете после перехода в сервис Долями. <br/>
        Обращаем ваше внимание, что на стороне сервиса Долями может взиматься дополнительный сервисный сбор.
      <a class="underlinedPositions"
         href="https://dolyame.ru/" target="_blank">Подробнее</a>
    </div>
  </div>
</template>

<script>

export default {
  name: "DolyameCart",

  data: () =>({
    isMoreInfoAboutDolyameShown: false,
    options : {
      month: 'long',
      day: 'numeric',
    },
  }),
  props: {
    cart: null
  },
}

</script>

<style scoped>
.underlinedPositions{
  text-decoration: underline;
  text-underline-offset: 3px;
}
.lineActive {
  width: 66px;
  border-top: 2px solid #C08950;
}
.lineNoActive {
  width: 66px;
  border-top: 2px solid #B6B6B6;
}
</style>
