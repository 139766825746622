import axios from '@/api';
import router from "../../router";

const state = {

    info: null,
    infoStatus: 'loading',
    infos: null,
    infosStatus: 'loading',

    deliveryInfo: null,
    deliveryInfoStatus: 'loading',
    sizeTableInfo: null,
    sizeTableInfoStatus: 'loading',

};

const getters = {
    info: state => {
        return state.info;
    },
    infoStatus: state => {
        return state.infoStatus;
    },
    infos: state => {
        return state.infos;
    },
    infosStatus: state => {
        return state.infosStatus;
    },

    deliveryInfo: state => {
        return state.deliveryInfo;
    },
    deliveryInfoStatus: state => {
        return state.deliveryInfoStatus;
    },
    sizeTableInfo: state => {
        return state.sizeTableInfo;
    },
    sizeTableInfoStatus: state => {
        return state.sizeTableInfoStatus;
    },

};

const actions = {
    fetchInfo({commit, dispatch}, infoSlug) {
        commit('setInfoStatus', 'loading')
        axios.get('/api/info/'+infoSlug+'/')
            .then(res => {
                commit('setInfo', res.data)
                commit('setInfoStatus', 'success')
            })
            .catch(error => {
                if (error.response.status === 404) {
                    router.push({name: 'NotFound'})
                }
                else{
                    commit('setInfoStatus', 'error')
                }
            });
    },
    fetchInfos({commit, dispatch}) {
        axios.get('/api/info/')
            .then(res => {
                commit('setInfos', res.data)
                commit('setInfosStatus', 'success')
            })
            .catch(error => {
                commit('setInfosStatus', 'error')
            });
    },
    fetchDeliveryInfoForProductCard({commit, dispatch}, infoSlug) {
        commit('setDeliveryInfoStatus', 'loading')
        axios.get('/api/info/'+infoSlug+'/')
            .then(res => {
                commit('setDeliveryInfo', res.data)
                commit('setDeliveryInfoStatus', 'success')
            })
            .catch(error => {
                if (error.response.status === 404) {
                    router.push({name: 'NotFound'})
                }
                else{
                    commit('setDeliveryInfoStatus', 'error')
                }
            });
    },
    fetchSizeTableInfoForProductCard({commit, dispatch}, infoSlug) {
        commit('setSizeTableInfoStatus', 'loading')
        axios.get('/api/info/'+infoSlug+'/')
            .then(res => {
                commit('setSizeTableInfo', res.data)
                commit('setSizeTableInfoStatus', 'success')
            })
            .catch(error => {
                if (error.response.status === 404) {
                    router.push({name: 'NotFound'})
                }
                else {
                    commit('setSizeTableInfoStatus', 'error')
                }
            });
    },

};

const mutations = {
    setInfo(state, info) {
        state.info = info;
    },
    setInfoStatus(state, status) {
        state.infoStatus = status;
    },
    setInfos(state, infos) {
        state.infos = infos;
    },
    setInfosStatus(state, status) {
        state.infosStatus = status;
    },

    setDeliveryInfo(state, info) {
        state.deliveryInfo = info;
    },
    setDeliveryInfoStatus(state, status) {
        state.deliveryInfoStatus = status;
    },
    setSizeTableInfo(state, info) {
        state.sizeTableInfo = info;
    },
    setSizeTableInfoStatus(state, status) {
        state.sizeTableInfoStatus = status;
    },

};

export default {
    state, getters, actions, mutations,
}
