<template>
    <div>
        <div>
            <div>
                <div class="relative">
                    <div @click="showSizeSelection" class="w-full h-9 px-5  text-14 cursor-pointer border border-gray-lightest
          flex items-center justify-between lg:w-98">
                        <div v-if="selectedSizeTitle" :class="selectedSize.data.relations.availableStocks.mainStockQuantity > 0
             || selectedSize.data.relations.availableStocks.hasPreorderStock ? 'text-black' : 'text-gray-main-2'">
                            {{ selectedSizeTitle }}
                        </div>
                        <div v-else class="text-14">
                            Выберите размер
                        </div>
                        <div>
                            <svg :class="[isSelectSizeActive || isNonActiveButtonAddToCartClicked ? 'transform rotate-180' : '']"
                                 width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 5.5L10 1" stroke="black" stroke-linecap="square"/>
                                <path d="M1 1.00049L5.5 5.50049" stroke="black" stroke-linecap="square"/>
                            </svg>
                        </div>
                    </div>

                    <div v-if="isSelectSizeActive || isNonActiveButtonAddToCartClicked" v-click-outside="onClickOutside"
                         class="absolute z-10 w-full bg-white border-b border-l border-r border-gray-lightest lg:w-98">
                        <div>
                            <div v-for="(size, size_index) in product.data.attributes.sizes.data"
                                 @click="selectSize(size, size_index)">
                                <div class="w-full flex items-center justify-between h-9 px-5 text-14 focus:outline-none border border-white
                     hover:border hover:border-black cursor-pointer">
                                    <div :class="[size.data.relations.availableStocks.mainStockQuantity > 0
                        || size.data.relations.availableStocks.hasPreorderStock ? 'text-black' : 'text-gray-main-2',
                        size.data.relations.availableStocks.hasPreorderStock ? '':'w-1/5']">
                                        {{size.data.attributes.title}}
                                    </div>
                                    <div v-if="size.data.relations.availableStocks.hasRetailStocks
                  && size.data.relations.availableStocks.mainStockQuantity < 1
                  && !size.data.relations.availableStocks.hasPreorderStock"
                                         class="text-gray-main-2 whitespace-nowrap" >
                                        (в наличии в магазине)
                                    </div>
                                    <div :class="size.data.relations.availableStocks.hasPreorderStock ?'text-gray-main-2 text-right lg:whitespace-nowrap'
                  :'w-2/5 text-gray-main-2 text-right lg:whitespace-nowrap'">
                                        <div v-if="size.data.relations.availableStocks.mainStockQuantity <= 0 || product.data.attributes.coming_soon">
                                            <span v-if="size.data.relations.availableStocks.hasPreorderStock">предзаказ или подписка</span>
                                            <span v-else>подписаться</span>
                                        </div>
                                        <div v-else-if="size.data.relations.availableStocks.mainStockQuantity <= 3 && !product.data.attributes.coming_soon">
                                            мало
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import vClickOutside from 'v-click-outside';

export default {
    name: "ProductSizeInfoBlock",
    data: () => ({
        isSelectSizeActive: false,
        selectedSize: null,
        selectedSizeTitle: null,
        selectedSizeId: null,
    }),
    props: {
        product: null,
        isNonActiveButtonAddToCartClicked: null,
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    methods: {
        showSizeSelection() {
            this.isSelectSizeActive = !this.isSelectSizeActive
        },
        selectSize(size, size_index) {
            if(size.data.attributes.quantity > 0) {
                this.selectedSizeTitle = size.data.attributes.title
                this.selectedSize = size
                this.selectedSizeId = size_index
                //this.$set(this.product, 'size', size)
                this.$emit('selectSizeAction', size)
                this.isSelectSizeActive = false;
            }
            else {
                this.selectedSizeId = size_index
                this.selectedSize = size
                this.selectedSizeTitle = size.data.attributes.title
                //this.$set(this.product, 'size', size)
                this.$emit('selectSizeAction', size)
                this.isSelectSizeActive = false;
            }
            this.sizeIsAlreadySelected()
        },
        onClickOutside() {
            this.isSelectSizeActive = false;
            this.sizeIsAlreadySelected()

        },
        sizeIsAlreadySelected() {
            this.$emit('sizeIsSelected',{
                isNonActiveButtonAddToCartClicked: false
            })
        }
    },
    mounted() {
        //this.$set(this.product, 'size', null)
        this.$emit('selectSizeAction', null)
    }
}

</script>

<style scoped>
</style>
