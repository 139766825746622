<template>
    <div>
        <div>
            <div>
                <div class="my-7 text-16">
                    <div>Предложение о порядке продажи товаров дистанционным способом в интернет-магазине
                        presentandsimple.com (в соответствие с Правилами продажи товаров дистанционным способом).
                    </div>
                    <div><br><strong>1. Термины и определения</strong></div>
                    <div>1.1. Интернет-магазин (Сайт) торговая площадка Продавца, расположенная в сети интернет
                        по адресу presentandsimple.com, на которой осуществляется продажа товаров дистанционным
                        способом.
                    </div>
                    <div>1.2. Заказ должным образом оформленный запрос Покупателя Продавцу на заключение
                        договора розничной купли-продажи Товаров.
                    </div>
                    <div>1.3. Продавец ООО "ПРЕЗЕНТ СИМПЛ"</div>
                    <div>Юр. адрес: 109202, г. Москва, вн. тер. г. муниципальный округ Нижегородский, ул. 3-я
                        Карачаровская, д. 18А, стр. 2, помещ. 1, ком. 5, часть 2.
                    </div>
                    <div>1.4. Покупатель (Клиент) посетитель Сайта, разместивший Заказ в Интернет-магазине;
                    </div>
                    <div>1.5. Товар товар, представленный к продаже на Сайте.</div>
                    <div>1.6. Служба доставки третье лицо, оказывающее по договору с Продавцом услуги по
                        доставке заказов клиентам.
                    </div>
                    <div>1.7. Акция мероприятие, направленное на увеличение объемов продаж, привлечение внимания
                        потребителей к продукции, проводимое в соответствии с правилами, размещенными на Сайте, и
                        ограниченное по срокам проведения.
                    </div>
                    <div><br/><strong>2. Общие положения</strong></div>
                    <div>2.1. Настоящее предложение, а также информация о товаре, представленная на сайте, являются
                        публичной офертой в соответствии со ст. 435 и ч. 2 ст. 437 ГК РФ.
                    </div>
                    <div>2.2. К отношениям между покупателем (клиентом) и продавцом применяются положения ГК РФ о
                        розничной купле-продаже (2 глава 30), а также Закона РФ от 07.02.1992 N 2300-1 О
                        защите прав потребителей и Постановления Правительства РФ от 27.09.2007 N 612 Об
                        утверждении Правил продажи товаров дистанционным способом и иные правовые акты, принятые
                        в соответствии с ними.
                    </div>
                    <div>2.3. Продавец осуществляет продажу Товаров через Интернет-магазин любому Покупателю,
                        оформившему Заказ на условиях настоящей оферты.
                    </div>
                    <div>2.4. Настоящая оферта считается принятой Покупателем с момента оформления первого Заказа на
                        Сайте (с использованием регистрации на Сайте в качестве постоянного покупателя Продавца либо без
                        таковой регистрации, в том числе при оформлении Заказа через оператора).
                    </div>
                    <div>2.5. Договор розничной купли-продажи считается заключенным с момента выдачи Продавцом
                        Покупателю кассового или товарного чека либо иного документа, подтверждающего оплату товара.
                    </div>
                    <div><br/><strong>3. Регистрация на сайте и оформление заказа</strong></div>
                    <div>3.1. Заказ может быть оформлен Клиентом через оператора по телефону или самостоятельно через
                        форму Заказа на Сайте.
                    </div>
                    <div>3.2. Регистрация на Сайте не является обязательной для оформления Заказа. Покупатель может по
                        собственному желанию пройти процедуру регистрации на Сайте для доступа к дополнительным опциям
                        личного кабинета (история и отслеживание заказов, получение сведений об Акциях Продавца и пр.).
                    </div>
                    <div>3.3. При регистрации на сайте клиент предоставляет в обязательном порядке информацию о себе:
                        имя, адрес электронной почты и пароль для доступа к Сайту, иные сведения указываются на
                        усмотрение Покупателя.
                    </div>
                    <div>3.4. Продавец не несет ответственность за точность и правильность информации, предоставляемой
                        клиентом при регистрации на Сайте и/или оформлении Заказа.
                    </div>
                    <div>3.5. Клиент обязуется не сообщать третьим лицам логин и пароль, указанные при регистрации на
                        Сайте. В случае возникновения у клиента подозрений относительно безопасности его логина и пароля
                        или возможности их несанкционированного использования третьими лицами, клиент обязуется
                        незамедлительно уведомить об этом Продавца.
                    </div>
                    <div>3.6. После оформления заказа клиенту предоставляется информация об ожидаемой дате передачи
                        заказа в службу доставки. Указанная дата зависит от наличия заказанных товаров на складе
                        Продавца и времени, необходимого на обработку заказа и правил службы доставки. Ожидаемая дата
                        передачи Заказа в Службу доставки сообщается Покупателю по электронной почте или при звонке
                        Покупателю. При своем несогласии с планируемой датой доставки Покупатель должен уведомить
                        Продавца об аннулировании Заказа.
                    </div>
                    <div>3.7. Если Товар, заказанный Покупателем, отсутствует в наличии у Продавца, Заказ в отношении
                        данного Товара аннулируется, о чем Покупатель уведомляется посредством информационного сообщения
                        по электронному адресу, указанному при регистрации, либо на телефонный номер Клиента либо
                        звонком оператора.
                    </div>
                    <div>3.8. Продавец вправе аннулировать Заказы Клиента при наличии оснований полагать, что Клиент не
                        намерен осуществить покупку Товара либо указал недостоверные сведения (у Клиента уже оформлены и
                        не получены другие заказы на общую сумму от 70 000 рублей, три раза и более Клиент отказывался
                        получать Товары по размещенному Заказу по причинам, не связанным с наличием недостатков в
                        Товарах, либо Клиент отсутствовал по адресу доставки либо не отвечал на звонки службы доставки и
                        иное).
                    </div>
                    <div><br/><strong>4. Доставка</strong></div>
                    <div>4.1. Способы доставки товаров указаны на Сайте в соответствующем разделе. Обязанность Продавца
                        передать товар Покупателю считается исполненной в момент доставки Товара Получателю либо в
                        заранее оговоренный пункт самовывоза.
                    </div>
                    <div>4.2. Доставка Товара по местонахождению Покупателя, осуществляется силами службы доставки,
                        назначенной Продавцом по адресу, указанному Покупателем. При принятии Заказа от службы доставки,
                        Покупатель обязан осмотреть доставленный Товар и проверить его на соответствие заявленному
                        количеству, ассортименту и комплектности Товара, а также проверить срок службы доставленного
                        Товара и целостность упаковки. В случае отсутствия претензий к доставленному Товару Получатель
                        расписывается в доставочной квитанции либо ином аналогичном документе, предоставляемом службой
                        доставки, и оплачивает Заказ (в отсутствие предоплаты). Подпись в доставочных документах
                        свидетельствует о том, что претензий к Товару получателем не заявлено и Продавец полностью и
                        надлежащим образом выполнил свою обязанность по передаче Товара.
                    </div>
                    <div>4.3. Заказ вручается Покупателю либо назначенному им получателю, либо лицу, предоставившему
                        документ, подтверждающий оформление Заказа и оплатившему Товар (при отсутствии предварительной
                        оплаты). Во избежание случаев мошенничества, при вручении предоплаченного Заказа у получателя
                        может быть затребован документ, удостоверяющий личность. При отсутствии данного документа либо
                        несовпадения указанных в нем сведений с деталями заказа или данными Покупателя, в передаче
                        данного Товара может быть отказано.
                    </div>
                    <div>4.4. Неполучение Заказа Клиентом в течение срока бронирования, предусмотренного для
                        соответствующего пункта самовывоза, считается его отказом от Заказа и является основанием для
                        аннулирования заказа Продавцом и возврата денежных средств Клиенту (при наличии предоплаты).
                    </div>
                    <div><br/><strong>5. Оплата товара</strong></div>
                    <div>5.1. Цена Товара указывается на Сайте в рублях РФ.</div>
                    <div>5.2. Цена Товара и окончательная сумма Заказа с учетом всех применимых скидок, указывается на
                        этапе оформления Заказа и действительна на момент нажатия кнопки Подтвердить заказ.
                        Цена на заказанный Клиентом Товар (кроме предзаказанных Товаров) изменению не подлежит.
                    </div>
                    <div>5.3. К Заказу применяется способ оплаты, выбранный Покупателем при оформлении Заказа из
                        вариантов, предложенных Продавцом на Сайте.
                    </div>
                    <div>5.4. При предоплате Товаров Заказ принимается в обработку только после зачисления денежных
                        средств на расчетный счет Продавца. При этом Товар не резервируется, и Продавец не гарантирует
                        доступность товара на момент поступления платежа. Если Товар не может быть доставлен в сроки,
                        указанные при оформлении Заказа и Клиент не согласен с увеличением сроков доставки Заказа.
                    </div>
                    <div><br/><strong>6. Возврат товара и денежных средств</strong></div>
                    <div>6.1. Возврат Товара, реализуемого Продавцом, осуществляется в соответствии со следующими
                        условиями возврата.
                    </div>
                    <div>6.2. Возврат Товара надлежащего качества</div>
                    <div>6.2.1. Покупатель вправе отказаться от заказанного Товара в любое время до его получения, а
                        после получения Товара в течение 14 (четырнадцать) дней в розничном магазине, и 7 (семь)
                        дней (Интернет-магазин), не считая дня покупки, за исключением Товаров, изготовленных на заказ,
                        по творческому заданию, утвержденному пользователем, Покупателем. Возврат Товара надлежащего
                        качества возможен в случае, если сохранены его товарный вид, потребительские свойства.
                    </div>
                    <div>6.2.2. При отказе Покупателя от Товара Продавец возвращает ему стоимость возвращенного Товара,
                        за исключением расходов Продавца, связанных с доставкой возвращенного пользователем, Покупателем
                        Товара, в течение 10 дней с даты поступления возвращенного Товара на склад Продавца вместе с
                        заполненным Покупателем заявлением на возврат денежных средств.
                    </div>
                    <div>6.2.3. Если на момент обращения пользователя, Покупателя аналогичный товар отсутствует в
                        продаже у Продавца, Покупатель вправе отказаться от исполнения настоящей Публичной оферты и
                        потребовать возврата уплаченной за указанный Товар денежной суммы. Продавец обязан вернуть
                        уплаченную за возвращенный товар денежную сумму в течение 10 дней со дня возврата Товара.
                    </div>
                    <div>6.3. Возврат Товара ненадлежащего качества:</div>
                    <div>6.3.1. Под товаром ненадлежащего качества подразумевается товар, который неисправен и не может
                        обеспечить исполнение своих функциональных качеств. Полученный Товар должен соответствовать
                        описанию на Сайте. Отличие элементов дизайна или оформления от заявленного на Сайте описания не
                        является признаком ненадлежащего качества.
                    </div>
                    <div>6.3.2. Внешний вид и комплектность Товара, а также комплектность всего Заказа должны быть
                        проверены Получателем в момент доставки Товара.
                    </div>
                    <div>6.3.3. При доставке Товара Покупатель ставит свою подпись в квитанции о доставке в графе:
                        Заказ принял, комплектность полная, претензий к количеству и внешнему виду товара не имею,
                        либо в ином аналогичном документе, выпускаемого Продавцом, в графе, предусматривающей
                        проставление Покупателем отметки об отсутствии у него претензий к комплектности, количеству и
                        качеству Товара. После получения Заказа претензии к внешним дефектам товара, его количеству,
                        комплектности и товарному виду не принимаются.
                    </div>
                    <div>6.3.4. Если Покупателю был передан Товар ненадлежащего качества и иное не было заранее
                        оговорено Продавцом, Покупатель вправе воспользоваться положениями ст. 18 Права
                        потребителя при обнаружении в товаре недостатков Закона Российской Федерации от
                        07.02.1992 N 2300-1 О защите прав потребителей.
                    </div>
                    <div>6.3.5. Требования о возврате уплаченной за товар денежной суммы подлежат удовлетворению в
                        течение 10 дней со дня предъявления соответствующего требования (ст. 22 Закона РФ О
                        защите прав потребителей).
                    </div>
                    <div>6.3.6. Возврат денежных средств осуществляется посредством возврата стоимости оплаченного
                        Товара на банковскую карту, указанную при заказе Товара.
                    </div>
                    <div>6.4. Способы возврата:</div>
                    <div>6.4.1. Транспортной компанией, или почтовой службой, по желанию Покупателя. Покупатель
                        отправляет возвращаемый товар посылкой с объявленной ценностью, без наложенного платежа.
                        Посылки, отправленные наложенным платежом, не принимаются к возврату. В посылку необходимо
                        вложить заполненное заявление на возврат, которое прилагается к Товару при доставке Покупателю.
                        Скачать бланк заявления о возврате можно в разделе Возврат
                        https://presentandsimple.com/info/return .
                    </div>
                    <div><br/><strong>7. Ответственность</strong></div>
                    <div>7.1. Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие
                        ненадлежащего использования Товаров, приобретенных в Интернет-магазине.
                    </div>
                    <div><br/><strong>8. Защита персональных данных</strong></div>
                    <div>8.1. Персональные данные пользователя/Покупателя обрабатывается в соответствии с Федеральным
                        законом О персональных данных № 152-ФЗ.
                    </div>
                    <div>8.2. При регистрации на Сайте пользователь предоставляет следующую информацию: Фамилия, Имя,
                        Отчество, контактный номер телефона, адрес электронной почты, дату рождения, пол, адрес доставки
                        товара с почтовым индексом города.
                    </div>
                    <div>8.3. Предоставляя свои персональные данные Продавцу, посетитель Сайта/пользователь/Покупатель
                        соглашается на их обработку Продавцом, в том числе в целях выполнения Продавцом обязательств
                        перед посетителем Сайта/пользователем/Покупателем в рамках настоящей Публичной оферты,
                        продвижения Продавцом товаров и услуг, проведения электронных и sms опросов, контроля
                        результатов маркетинговых акций, клиентской поддержки, организации доставки товара Покупателям,
                        проведение розыгрышей призов среди посетителей Сайта/пользователей/ Покупателей, контроля
                        удовлетворенности посетителя Сайта/пользователя/Покупателя, а также качества услуг, оказываемых
                        Продавцом.
                    </div>
                    <div>8.4. Под обработкой персональных данных понимается любое действие (операция) или совокупность
                        действий (операций), совершаемых с использованием средств автоматизации или без использования
                        таких средств с персональными данными, включая сбор, запись, систематизацию, накопление,
                        хранение, уточнение (обновление, изменение) извлечение, использование, передачу (в том числе
                        передачу третьим лицам, не исключая трансграничную передачу, если необходимость в ней возникла в
                        ходе исполнения обязательств), обезличивание, блокирование, удаление, уничтожение персональных
                        данных.
                    </div>
                    <div>8.5. Продавец имеет право отправлять информационные, в том числе рекламные сообщения, на
                        электронную почту и мобильный телефон пользователя/Покупателя с его согласия, выраженного
                        посредством совершения им действий, однозначно идентифицирующих этого абонента и позволяющих
                        достоверно установить его волеизъявление на получение сообщения. Пользователь/Покупатель вправе
                        отказаться от получения рекламной и другой информации без объяснения причин отказа путем
                        информирования Продавца о своем отказе по телефону, либо посредством направления
                        соответствующего заявления на электронный адрес Продавца.
                    </div>
                    <div>8.6. Продавец вправе использовать технологию cookies. Cookies не
                        содержат конфиденциальную информацию. Посетитель/пользователь/Покупатель настоящим дает согласие
                        на сбор, анализ и использование cookies, в том числе третьими лицами для целей формирования
                        статистики и оптимизации рекламных сообщений.
                    </div>
                    <div>8.7. Продавец получает информацию об ip-адресе посетителя Сайта. Данная информация не
                        используется для установления личности посетителя.
                    </div>
                    <div>8.8. Продавец не несет ответственности за сведения, предоставленные пользователем/Покупателем
                        на Сайте в общедоступной форме.
                    </div>
                    <div>8.9. Продавец вправе осуществлять записи телефонных разговоров с пользователем/Покупателем. При
                        этом Продавец обязуется: предотвращать попытки несанкционированного доступа к информации,
                        полученной в ходе телефонных переговоров, и/или передачу ее третьим лицам, не имеющим
                        непосредственного отношения к исполнению Заказов, в соответствии с п. 4 ст. 16 Федерального
                        закона Об информации, информационных технологиях и о защите информации
                    </div>
                    <div><br/><strong>9. Срок действия Публичной оферты</strong></div>
                    <div>9.1. Настоящая Публичная оферта вступает в силу с момента ее акцепта посетителем
                        Сайта/Покупателем, и действует до момента отзыва акцепта Публичной оферты.<br/><br/></div>
                    <div><strong>10. Дополнительные условия</strong></div>
                    <div>10.1. Продавец вправе переуступать либо каким-либо иным способом передавать свои права и
                        обязанности, вытекающие из его отношений с Покупателем, третьим лицам.
                    </div>
                    <div>10.2. Интернет-магазин и предоставляемые сервисы могут временно частично или полностью
                        недоступны по причине проведения профилактических или иных работ или по любым другим причинам
                        технического характера.
                    </div>
                    <div>10.3. К отношениям между пользователем/Покупателем и Продавцом применяются положения
                        Российского законодательства.
                    </div>
                    <div>10.4. В случае возникновения вопросов и претензий со стороны пользователя/Покупателя он может
                        обратиться к Продавцу по телефону или иным доступным способом. Все возникающее споры стороны
                        будут стараться решить путем переговоров, при не достижении соглашения спор будет передан на
                        рассмотрение в судебный орган в соответствии с действующим законодательством Российской
                        Федерации.
                    </div>
                    <div>10.5. Признание судом недействительности какого-либо положения настоящей Публичной оферты не
                        влечет за собой недействительность остальных положений.
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: "OfferDoc",
}

</script>

<style scoped>
</style>







