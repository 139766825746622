<template>
    <div class="block pt-5">
        <div class="flex items-center">
            <div class="block w-full">
                <div class="flex justify-between">
                    <div class="uppercase text-13 text-black text-left flex-1 lg:text-16">
                        Доставка
                    </div>
                    <div v-if="this.cart.data.attributes.hasDeliveryMethod" class="flex items-center">
                        <button @click="closeForm" class="lg:hidden">
                            <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 1L1 4" stroke="black" stroke-linecap="square"/>
                                <path d="M7 3.99976L4 0.999756" stroke="black" stroke-linecap="square"/>
                            </svg>
                        </button>
                        <button @click="closeForm" class="hidden lg:block">
                            <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 1.00049L1 5.50049" stroke="black" stroke-linecap="square"/>
                                <path d="M10 5.50024L5.5 1.00024" stroke="black" stroke-linecap="square"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="mt-10">
                    <!-- Страна -->
                    <div class="my-4 lg:my-2 flex flex-col items-baseline gap-2 ">
                        <div class="w-30 uppercase text-11 lg:text-13">Страна</div>
                        <div class="relative w-full h-9 mr-20 lg:h-11">
                            <input class="appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13"
                                   v-model="country"
                                   type="text"
                                   name="country"
                                   @focus="focusOnly('country')"
                                   :class="[submitted && v$.country.$invalid || !v$.country.sameAs && this.submit.country ? 'border-red-light' : 'border-gray-lighter'] ">
                            <div v-if="submitted && v$.country.$invalid || !v$.country.sameAs && this.submit.country">
                                <span v-if="!v$.country.required" class="absolute text-red-light text-11 ml-1">Выберите страну из списка</span>
                            </div>
                            <div v-if="focus.country" class="absolute bg-white w-full px-2 left-0 mr-6 shadow z-20">
                                <div v-if="deliverySuggestions!=null">
                                    <div class="bg-white cursor-pointer w-full h-9 flex items-center text-11 lg:text-13"
                                         v-for="suggestion in deliverySuggestions.suggestions"
                                         @click="selectCountry(suggestion)"
                                    >
                                        {{suggestion.value}}
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                    <!-- Город -->
                    <div class="my-4 lg:my-2 flex flex-col items-baseline gap-2 ">
                        <div class="w-30 mr-5 uppercase text-11 lg:text-13 lg:mr-4">Населенный пункт</div>
                        <div class="relative w-full h-9 mr-20 lg:h-11 ">
                            <input class="appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13"
                                   v-model="city"
                                   type="text"
                                   name="city"
                                   @focus="focusOnly('city')"
                                   :class="[submitted && v$.city.$invalid
                                    || !v$.city.sameAs && this.submit.city ? 'bo    rder-red-light' : 'border-gray-lighter'] ">
                            <div v-if="submitted && v$.city.$invalid">
                                <span class="absolute text-red-light text-11 ml-1">Выберите город из списка</span>
                            </div>
                            <div v-if="focus.city" class="absolute bg-white w-full px-2 left-0 mr-6 shadow z-20">
                                <div v-if="deliverySuggestions!=null">
                                    <div class="bg-white cursor-pointer w-full h-10 flex items-center text-11 lg:text-13"
                                         v-for="suggestion in deliverySuggestions.suggestions"
                                         @click="selectCity(suggestion)"
                                    >
                                        {{suggestion.value}}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- Выбор Курьер или Самовывоз -->
                    <div class="w-full flex items-center">
                        <div class="w-30"></div>
                        <div class="w-full mr-20">
                            <div class=" flex justify-between">
                                <label class="flex items-center">
                                    <div class="flex items-center">
                                        <input type="radio" class="rounded-[50%] appearance-none h-2 w-2 border border-black rounded-full checked:bg-black checked:border-transparent focus:outline-none checked"
                                               :class="[submitted && v$.selectedDeliveryTypeCourierOrPickup.$invalid ? 'border-red-light' : 'border-black']"
                                               value="deliveryTypeCourier"
                                               v-model="selectedDeliveryTypeCourierOrPickup"
                                        >
                                    </div>
                                    <div class="ml-2 text-11 text-black lg:text-13">
                                        Курьер
                                    </div>
                                </label>
                                <label class="flex items-center">
                                    <div class="flex items-center">
                                        <input type="radio" class="rounded-[50%] appearance-none h-2 w-2 border border-black rounded-full checked:bg-black checked:border-transparent focus:outline-none checked"
                                               :class="[submitted && v$.selectedDeliveryTypeCourierOrPickup.$invalid ? 'border-red-light' : 'border-black']"
                                               value="deliveryTypePickup"
                                               v-model="selectedDeliveryTypeCourierOrPickup"
                                        >
                                    </div>
                                    <div class="ml-2 text-11 text-black lg:text-13">
                                        Самовывоз
                                    </div>
                                </label>
                            </div>
                            <div v-if="submitted && v$.selectedDeliveryTypeCourierOrPickup.$invalid">
                                <span v-if="!v$.selectedDeliveryTypeCourierOrPickup.required" class="absolute text-red-light text-11 ml-1">Выберите способ доставки</span>
                            </div>
                        </div>

                    </div>


                    <div v-if="!v$.country.$invalid && !v$.city.$invalid">
                      <div v-if="selectedDeliveryTypeCourierOrPickup === 'deliveryTypeCourier'">
                        <!-- Улица -->
                        <div class="my-4 lg:my-3 flex items-center">
                          <div class="w-30 uppercase text-11 lg:text-13">Улица</div>
                          <div class="relative w-full ml-7 h-9 mr-20 lg:h-11">
                            <input class="appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13 t-test"
                                   v-model="street"
                                   type="text"
                                   name="street"
                                   @focus="focusOnly('street')"
                                   :class="[submitted && v$.street.$invalid || !v$.street.sameAs && this.submit.street ? 'test-c border-red-light' : 'border-gray-lighter'] ">
                            <div v-if="submitted && v$.street.$invalid || !v$.street.sameAs && this.submit.street">
                              <span class="absolute text-red-light text-11 ml-1">Выберите улицу из списка</span>
                            </div>
                            <div v-if="focus.street" class="absolute bg-white w-full px-2 left-0 mr-6 shadow z-20">
                              <div v-if="deliverySuggestions!=null">
                                <div class="bg-white cursor-pointer w-full h-9 flex items-center text-11 lg:text-13"
                                     v-for="suggestion in deliverySuggestions.suggestions"
                                     @click="selectStreet(suggestion)"
                                >
                                  {{suggestion.value}}
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>

                        <!-- Дом -->
                        <div class="my-4 lg:my-2 flex items-center "
                             v-if="show.pick_up_point==false && show.pick_up_point_cdek==false && show.moscow_showroom==false&& show.spb_showroom==false && show.moscow_okeania==false && show.moscow_afimoll==false && show.ekb_showroom==false">
                          <div class="w-30 uppercase text-11 lg:text-13">Дом</div>
                          <div class="relative w-full ml-7 h-9 mr-20 lg:h-11">
                            <input class="appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13"
                                   v-model="house"
                                   type="text"
                                   name="house"
                                   @focus="focusOnly('house')"
                                   :class="[submitted && v$.house.$invalid || !v$.house.sameAs && this.submit.house ? 'border-red-light' : 'border-gray-lighter'] ">
                            <div v-if="submitted && v$.house.$invalid || !v$.house.sameAs && this.submit.house">
                              <span class="absolute text-red-light text-11 ml-1">Выберите дом из списка</span>
                            </div>
                            <div v-if="focus.house" class="absolute bg-white w-full px-2 left-0 mr-6 shadow z-20">
                              <div v-if="deliverySuggestions!=null">
                                <div class="bg-white cursor-pointer w-full h-9 flex items-center text-11 lg:text-13"
                                     v-for="suggestion in deliverySuggestions.suggestions"
                                     @click="selectHouse(suggestion)"
                                >
                                  {{suggestion.data.house}}
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>

                        <div class="my-4 lg:my-2 flex items-center "
                             v-if="show.pick_up_point==false && show.pick_up_point_cdek==false && show.moscow_showroom==false&& show.spb_showroom==false && show.moscow_okeania==false && show.moscow_afimoll==false && show.ekb_showroom==false">
                          <div class="w-30 uppercase text-11 lg:text-13">Корпус</div>
                          <div class="relative w-full ml-7 h-9 mr-20 lg:h-11">
                            <input class="appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13"
                                   v-model="building"
                                   type="text"
                                   name="building">
                          </div>
                        </div>
                        <div class="my-4 lg:my-2 flex items-center "
                             v-if="show.pick_up_point==false && show.pick_up_point_cdek==false && show.moscow_showroom==false&& show.spb_showroom==false && show.moscow_okeania==false && show.moscow_afimoll==false && show.ekb_showroom==false">
                          <div class="w-30 uppercase text-11 lg:text-13">Квартира</div>
                          <div class="relative w-full ml-7 h-9 mr-20 lg:h-11">
                            <input class="appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13"
                                   v-model="apartment"
                                   type="text"
                                   name="apartment" :class="[submitted && v$.apartment.$invalid ? 'border-red-light' : 'border-gray-lighter'] ">
                            <div v-if="submitted && v$.apartment.$invalid">
                              <span class="absolute text-red-light text-11 ml-1">Укажите квартиру</span>
                            </div>
                          </div>
                        </div>

                        <!-- Индекс -->
                        <div class="my-4 lg:my-2 flex items-center "
                             v-if="show.pick_up_point==false && show.pick_up_point_cdek==false && show.moscow_showroom==false&& show.spb_showroom==false && show.moscow_okeania==false && show.moscow_afimoll==false && show.ekb_showroom==false">
                          <div class="w-30 uppercase text-11 lg:text-13">Индекс</div>
                          <div class="relative w-full ml-7 h-9 mr-20 lg:h-11">
                            <input class="appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13"
                                   v-model="postal_code"
                                   type="text"
                                   name="postal_code"
                                   :class="[submitted && v$.postal_code.$invalid ? 'border-red-light' : 'border-gray-lighter'] ">
                            <div v-if="submitted && v$.postal_code.$invalid">
                              <span class="absolute text-red-light text-11 ml-1">Укажите индекс</span>
                            </div>

                          </div>
                        </div>
                      </div>

                      <!-- Способы доставки -->
                      <div v-if="deliveryRegionDetailsStatus === 'success'" class="my-6 flex flex-row items-top ">
                        <div v-if="deliveryRegionDetails.data.length > 0"
                             class="w-full flex items-center">
                          <div class="flex flex-col ">
                            <div v-if="deliveryRegionDetailsStatus === 'success' && selectedDeliveryTypeCourierOrPickup === 'deliveryTypePickup'">
                              <div v-for="deliveryTypeItem in deliveryRegionDetails.data"
                                   :key="deliveryTypeItem.id">
                                <div v-if="deliveryTypeItem.relationships.deliveryType.data.relationships.deliveryCompany.data.id === 1 || deliveryTypeItem.relationships.deliveryType.data.relationships.deliveryCompany.data.id === 3 || deliveryTypeItem.relationships.deliveryType.data.relationships.deliveryCompany.data.id === 10">
                                  <label class="mt-1 w-full flex items-center">
                                    <div class="flex items-center">
                                      <input type="radio" class="appearance-none w-2 h-2 border border-black rounded-full checked:bg-black checked:border-transparent focus:outline-none checked"
                                             :class="[submitted && v$.selectedDeliveryRegionDetail.$invalid ? 'border-red-light' : 'border-black']"
                                             name="deliveryRegionDetail "
                                             :value=deliveryTypeItem
                                             v-model="selectedDeliveryRegionDetail"
                                      >
                                    </div>
                                    <div class="ml-3 text-11 text-black lg:text-13">
                                      {{deliveryTypeItem.relationships.deliveryType.data.attributes.title}}, {{deliveryTypeItem.attributes.cost}} Р, от {{deliveryTypeItem.attributes.deliveryDaysFrom}} до {{deliveryTypeItem.attributes.deliveryDaysTo}} дней.
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div v-if="deliveryRegionDetail != null" class="appearance-none rounded-none w-full text-11 text-gray-light px-2 mt-3 lg:text-13">
                                <div v-html="deliveryRegionDetail.attributes.description"></div>
                              </div>
                            </div>
                            <div v-else-if="deliveryRegionDetailsStatus === 'success' && selectedDeliveryTypeCourierOrPickup === 'deliveryTypeCourier'">
                              <div v-for="deliveryTypeItem in deliveryRegionDetails.data"
                                   :key="deliveryTypeItem.id">
                                <div v-if="deliveryTypeItem.relationships.deliveryType.data.relationships.deliveryCompany.data.id !== 1 && deliveryTypeItem.relationships.deliveryType.data.relationships.deliveryCompany.data.id !== 3 && deliveryTypeItem.relationships.deliveryType.data.relationships.deliveryCompany.data.id !== 10">
                                  <label class="mt-1 w-full flex items-center">
                                    <div class="">
                                      <input type="radio" class="appearance-none w-2 h-2 border border-black rounded-full checked:bg-black checked:border-transparent focus:outline-none checked"
                                             :class="[submitted && v$.selectedDeliveryRegionDetail.$invalid ? 'border-red-light' : 'border-black']"
                                             name="deliveryRegionDetail"
                                             :value=deliveryTypeItem
                                             v-model="selectedDeliveryRegionDetail"
                                      >
                                    </div>

                                    <div class="ml-3 text-11 text-black lg:text-13">
                                      {{deliveryTypeItem.relationships.deliveryType.data.attributes.title}}, {{deliveryTypeItem.attributes.cost}} Р, от {{deliveryTypeItem.attributes.deliveryDaysFrom}} до {{deliveryTypeItem.attributes.deliveryDaysTo}} дней.
                                    </div>
                                  </label>
                                </div>
                              </div>
                              <div v-if="deliveryRegionDetail != null" class="appearance-none rounded-none w-full text-11 text-gray-light px-2 mt-3 lg:text-13">
                                <div v-html="deliveryRegionDetail.attributes.description"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else class="text-13">
                          Доставка в выбранный регион недоступна
                        </div>
                      </div>
                      <!-- Пункт самовывоза -->
                      <div v-if="show.pick_up_point === true && selectedDeliveryTypeCourierOrPickup === 'deliveryTypePickup'" >
                        <div v-if="pick_up_point && pick_up_point.length > 1" class="my-4 lg:my-2 flex items-center ">
                          <div class="w-30 uppercase text-11 lg:text-13">Адрес пункта самовывоза</div>
                          <div class="w-full ml-7 h-9 mr-20 lg:h-11">
                            <input readonly="readonly" class="appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13"
                                   v-model="pick_up_point"
                                   type="text"
                                   name="pick_up_point"
                                   :class="[submitted && v$.pick_up_point.$invalid ? 'border-red-light' : 'border-gray-lighter'] "
                            >
                          </div>
                          <div v-if="submitted && v$.pick_up_point.$invalid">
                            <span v-if="!v$.pick_up_point.required" class="absolute text-red-light text-11 ml-1">Укажите адрес пункта самовывоза</span>
                          </div>
                        </div>
                        <button class="my-4 h-9 px-3 lg:my-2 flex items-center border border-black">
                          <a class="uppercase text-11 lg:mx-auto lg:text-13 "
                             href="#" @click="getBoxberryPickUpPoints">Выбрать ПВЗ</a>
                        </button>
                      </div>
                      <div v-if="show.pick_up_point_cdek === true && selectedDeliveryTypeCourierOrPickup === 'deliveryTypePickup'" >
                        <div v-if="pick_up_point && pick_up_point.length > 1" class="my-4 lg:my-2 flex items-center ">
                          <div class="w-30 uppercase text-11 lg:text-13">Адрес пункта самовывоза</div>
                          <div class="w-full ml-7 h-9 mr-20 lg:h-11">
                            <input readonly="readonly" class="appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13"
                                   v-model="pick_up_point"
                                   type="text"
                                   name="pick_up_point_cdek"
                                   :class="[submitted && v$.pick_up_point.$invalid ? 'border-red-light' : 'border-gray-lighter'] "
                            >
                          </div>
                          <div v-if="submitted && v$.pick_up_point.$invalid">
                            <span v-if="!v$.pick_up_point.required" class="absolute text-red-light text-11 ml-1">Укажите адрес пункта самовывоза</span>
                          </div>
                        </div>
                        <CdekPickPointDelivery :country="this.delivery.country"
                                               :city="this.deliverySelectedSuggestion.data.city"
                                               :pick_up_point_cdek="this.pick_up_point_cdek"
                                               :pick_up_point_id="this.pick_up_point_id"
                                               @getCdekPickUp='onCdekPickUp'/>
                      </div>

                      <div v-if="deliveryRegionDetail !== null" class="mb-7">
                        <div v-if=" deliveryRegionDetail.relationships.deliveryType.data.id === 1">
                          <CartDeliveryDateIntervalBlock :deliveryRegionDetail="this.deliveryRegionDetail"/>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-col items-baseline gap-2 mt-3">
                        <div class="w-30 text-11 text-gray-light lg:text-13">Комментарий</div>
                        <div class="w-full h-9 mr-20 lg:h-11">
                        <textarea
                            class="appearance-none
                            text-11 text-gray-light
                            resize-none
                            rounded-none
                            w-full h-12 border
                            px-2 focus:outline-none focus:border-black lg:text-13
                            border-gray-lighter"
                                  v-model="comment"
                                  type="text"
                                  name="comment">
                    </textarea>
                        </div>
                    </div>
                    <div class="my-7">
                        <button @click="sendDeliveryInfo()" class="w-full h-9 py-1 px-5 border custom-border bg-transparent text-black text-11 r focus:outline-none lg:text-11 lg:text-13">
                            Далее
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import _ from "lodash";
import { useVuelidate } from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import DeliveryAbroadIsAvailableConditionPopup from "../popup/DeliveryAbroadIsAvailableConditionPopup";
import DeliveryRestrictionPopup from "../popup/DeliveryRestrictionPopup";
import DeliveryWarningMessagePopup from "../popup/DeliveryWarningPopup";
import CartDeliveryDateIntervalBlock from "../Cart 2.0/CartDeliveryDateIntervalBlock.vue";
import CdekPickPointDelivery from "../Cart 2.0/CdekPickPointDelivery.vue";

export default {

    name: "DeliveryCartBlock",
    components: {
        CdekPickPointDelivery,
        CartDeliveryDateIntervalBlock,
        DeliveryRestrictionPopup, DeliveryAbroadIsAvailableConditionPopup, DeliveryWarningMessagePopup},
    props:{
        cart:null
    },

    data: () =>({
        expressDeliveryInfo:false,
        isChangeDeliveryMethodActive:false,
        isChangePaymentMethodActive:false,
        submitted: false,
        cdek_city_name:null,
        submit: {
          country: false,
          city: false,
          street: false,
          house: false,
        },

        focus : {
            country: false,
            city: false,
            street: false,
            house: false,
            building: false,
            apartment: false,
            pick_up_point: false,
            pick_up_point_cdek: false,
        },
        show : {
            pick_up_point: false,
            pick_up_point_cdek: false,
            moscow_showroom: false,
            spb_showroom: false,
            ekb_showroom: false,
            moscow_okeania: false,
            moscow_afimoll: false,
        },
        isAbroadDeliveryIsNotAvailablePopupVisible: false,
        isDeliveryRestrictionPopupVisible: false,
        isDeliveryWarningPopupVisible: false,
    }),
    computed: {
        ...mapGetters({
            deliverySuggestions: 'deliverySuggestions',
            delivery: 'delivery',
            clicked: 'clicked',
            deliverySelectedSuggestion: 'deliverySelectedSuggestion',
            deliveryTypeCourierOrPickup: 'deliveryTypeCourierOrPickup',

            deliveryRegionDetail: 'deliveryRegionDetail',
            deliveryRegionDetails: 'deliveryRegionDetails',
            deliveryRegionDetailsStatus: 'deliveryRegionDetailsStatus',

            deliveryRestrictedMessageStatus: 'deliveryRestrictedMessageStatus',
            deliveryWarningMessageStatus: 'deliveryWarningMessageStatus',
        }),

        selectedDeliveryTypeCourierOrPickup: {
            get(){
                return this.deliveryTypeCourierOrPickup
            },
            set(deliveryTypeCourierOrPickup){
                this.setDeliveryTypeCourierOrPickup(deliveryTypeCourierOrPickup);
                this.deliverySelectedSuggestion.data.street_with_type = null;
                this.delivery.house = null;
                this.delivery.building = null;
                this.delivery.street = null;
                this.delivery.apartment = null;
                this.delivery.pick_up_point = null;
                this.delivery.pick_up_point_cdek = null;
                this.delivery.pick_up_point_id = null;
                this.delivery.time_interval_id = null;
                this.delivery.time_interval = null;
                this.delivery.date = null;
            }
        },

        clickedCountry: {
          get() {
            return this.clicked.country;
          },
          set: _.debounce(function (country) {
            this.setClickedCountry(country);
          }, 500),
        },
        clickedCity: {
          get() {
            return this.clicked.city;
          },
          set: _.debounce(function (city) {
            this.setClickedCity(city);
          }, 500),
        },
        clickedStreet: {
          get() {
            return this.clicked.street;
          },
          set: _.debounce(function (street) {
            this.setClickedStreet(street);
          }, 500),
        },
        clickedHouse: {
          get() {
            return this.clicked.house;
          },
          set: _.debounce(function (house) {
            this.setClickedHouse(house);
          }, 500),
        },
        country: {
            get(){
                return this.delivery.country;
            },
            set: _.debounce(function(country){
                this.setDeliveryCountry(country);
                this.getDadataCountry(country)
                this.submit.country = true
            }, 500),
        },
        city: {
            get(){
                return this.delivery.city;
            },
            set: _.debounce(function(city){
                this.setDeliveryCity(city);
                this.getDadataCity(city)
                this.submit.city = true
            }, 500),
        },
        street: {
            get(){
                return this.delivery.street;
            },
            set: _.debounce(function(street){
                this.clicked.street = false
                this.setDeliveryStreet(street);
                this.getDadataStreet(street)
                this.submit.street = true
            }, 500),
        },
        house: {
            get(){
                return this.delivery.house;
            },
            set: _.debounce(function(house){
                this.clicked.house = false
                this.setDeliveryHouse(house);
                this.getDadataHouse(house)
                this.submit.house = true
            }, 500),
        },
        building: {
            get(){
                return this.delivery.building;
            },
            set: _.debounce(function(building){
                this.setDeliveryBuilding(building);
            }, 500),
        },
        apartment: {
            get(){
                return this.delivery.apartment;
            },
            set: _.debounce(function(apartment){
                this.setDeliveryApartment(apartment);
            }, 500),
        },
        pick_up_point: {
            get(){
                return this.delivery.pick_up_point;
            },
            set: _.debounce(function(pick_up_point){
                this.setDeliveryPickupPoint(pick_up_point);
            }, 500),
        },
        pick_up_point_cdek: {
            get(){
                return this.delivery.pick_up_point;
            },
            set: _.debounce(function(pick_up_point){
                this.setDeliveryPickupPoint(pick_up_point);
            }, 500),
        },
        pick_up_point_id: {
            get(){
                return this.delivery.pick_up_point_id;
            },
            set: _.debounce(function(pick_up_point_id){
                this.setDeliveryPickupPointId(pick_up_point_id);
            }, 500),
        },
        postal_code: {
            get(){
                return this.delivery.postal_code;
            },
            set: _.debounce(function(postal_code){
                this.setDeliveryPostalCode(postal_code);
            }, 500),
        },
        comment: {
            get(){
                return this.delivery.comment;
            },
            set: _.debounce(function(comment){
                this.setDeliveryComment(comment);
            }, 300),
        },
        selectedDeliveryRegionDetail: {
            get(){
                return this.deliveryRegionDetail;
            },
            set(deliveryRegionDetail){
                this.setDeliveryRegionDetail(deliveryRegionDetail);
                this.sendSelectedDeliveryTypeToGTM();
                this.show.pick_up_point = deliveryRegionDetail.relationships.deliveryType.data.id === 5;
                this.show.pick_up_point_cdek = deliveryRegionDetail.relationships.deliveryType.data.id === 22;
                this.show.moscow_showroom = deliveryRegionDetail.relationships.deliveryType.data.id === 7;
                this.show.spb_showroom = deliveryRegionDetail.relationships.deliveryType.data.id === 13;
                this.show.moscow_okeania = deliveryRegionDetail.relationships.deliveryType.data.id === 17;
                this.show.ekb_showroom = deliveryRegionDetail.relationships.deliveryType.data.id === 16;
                this.show.moscow_afimoll = deliveryRegionDetail.relationships.deliveryType.data.id === 18;
                this.expressDeliveryInfo = deliveryRegionDetail.delivery_region_detail_id === 35;
                this.delivery.pick_up_point = null;
                this.delivery.pick_up_point_cdek = null;
                this.delivery.pick_up_point_id = null;
                this.delivery.time_interval_id = null;
                this.delivery.time_interval = null;
                this.delivery.date = null;
            }
        },
    },

    setup () { return { v$: useVuelidate() } },
    
    validations() {

        if (this.show.moscow_showroom || this.show.spb_showroom || this.show.moscow_okeania || this.show.moscow_afimoll || this.show.ekb_showroom) {
            return {
                selectedDeliveryRegionDetail: {required},
                selectedDeliveryTypeCourierOrPickup: {required},
                country: {
                  required,
                  sameAs: function () {
                    return this.clicked.country === true
                  }
                },
                city: {
                  required,
                  sameAs: function () {
                    return this.clicked.city === true
                  }
                },
                street: {  },
                house: {  },
                apartment: { },
                postal_code: {  },
                pick_up_point: { },
                pick_up_point_cdek: { },
            }
        } else if (this.show.pick_up_point || this.show.pick_up_point_cdek) {
            return {

                selectedDeliveryRegionDetail: {required},
                selectedDeliveryTypeCourierOrPickup: {required},
                country: {
                  required,
                  sameAs: function () {
                    return this.clicked.country === true
                  }
                },
                city: {
                  required,
                  sameAs: function () {
                    return this.clicked.city === true
                  }
                },
                street: {  },
                house: {  },
                apartment: { },
                postal_code: {  },
                pick_up_point: { required },
                pick_up_point_cdek: { },
            }
        } else {
            return {
                selectedDeliveryRegionDetail: { required },
                selectedDeliveryTypeCourierOrPickup: { required },
                country: {
                  required,
                  sameAs: function () {
                    return this.clicked.country === true
                  }
                },
                city: {
                  required,
                  sameAs: function () {
                    return this.clicked.city === true
                  }
                },
                street: {
                  required,
                  sameAs: function () {
                    return this.clicked.street === true
                  }
                },
                house: {
                  required,
                  sameAs: function () {
                    return this.clicked.house === true
                  }
                },
                apartment: { required},
                postal_code: { required },
                pick_up_point: {  },
                pick_up_point_cdek: {  },

            }
        }
    },

    methods: {
        ...mapActions({

            setClickedCity: 'setClickedCity',
            setClickedCountry: 'setClickedCountry',
            setClickedStreet: 'setClickedStreet',
            setClickedHouse: 'setClickedHouse',

            getDadataCity: 'getDadataCity',
            getDadataCountry: 'getDadataCountry',
            getDadataStreet: 'getDadataStreet',
            getDadataHouse: 'getDadataHouse',
            selectFromSuggestion: 'selectFromSuggestion',

            fetchDeliveryRestrictedMessage: 'fetchDeliveryRestrictedMessage',
            fetchDeliveryWarningMessage: 'fetchDeliveryWarningMessage',
            setDeliveryTypeCourierOrPickup: 'setDeliveryTypeCourierOrPickup',
            refreshCartTotal: 'refreshCartTotal',
            setDeliveryRegionDetail: 'setDeliveryRegionDetail',
            setDeliveryCountry: 'setDeliveryCountry',
            setDeliveryCity: 'setDeliveryCity',
            setDeliveryStreet: 'setDeliveryStreet',
            setDeliveryHouse: 'setDeliveryHouse',
            setDeliveryBuilding: 'setDeliveryBuilding',
            setDeliveryApartment: 'setDeliveryApartment',
            setDeliveryPickupPoint: 'setDeliveryPickupPoint',
            setDeliveryPickupPointId: 'setDeliveryPickupPointId',
            setDeliveryPostalCode: 'setDeliveryPostalCode',
            getPaymentTypesVuex: 'getPaymentTypes',
            setDeliveryComment: 'setDeliveryComment',

            flushDeliverySuggestions: 'flushDeliverySuggestions',

            deliveryTypeVuex: 'setDeliveryType',
        }),
        onCdekPickUp(data){
            if(data.pick_up_point_cdek !== null){
                this.pick_up_point_cdek = data.pick_up_point_cdek;
                this.pick_up_point_id = data.pick_up_point_id;
                this.cdek_city_name = data.cdek_delivery
            }
        },
        changeDeliveryMethod(){
            this.isChangeDeliveryMethodActive = true
        },
        sendDeliveryInfo(){
            this.submitted = true;
            this.v$.$touch();

            if(this.v$.$invalid) {
            } else {
                this.$emit('sendDeliveryInfo',{
                   deliveryRegionDetailId: this.deliveryRegionDetail.id,
                   country: this.deliverySelectedSuggestion.data.country,
                   region: this.deliverySelectedSuggestion.data.region_with_type,
                   countryCode: this.deliverySelectedSuggestion.data.country_iso_code,
                   city: (this.cdek_city_name !== null && this.cdek_city_name !== this.deliverySelectedSuggestion.data.city) ? this.cdek_city_name : this.deliverySelectedSuggestion.data.city_with_type,
                   street: this.deliverySelectedSuggestion.data.street_with_type,
                   house: this.delivery.house,
                   building: this.delivery.building,
                   postalCode: this.delivery.postal_code,
                   apartment: this.delivery.apartment,
                   comment: this.delivery.comment,
                   pickUpPoint: this.delivery.pick_up_point,
                   pickUpPointId: this.delivery.pick_up_point_id,
                   deliveryDate: this.delivery.date,
                   deliveryTimeInterval: this.delivery.time_interval_id,

                  isChangeDeliveryMethodActive : false,
                  isChangePaymentMethodActive : true
                })
            }
        },
        closeForm(){
            this.$emit('sendDeliveryInfo',{
                isChangeDeliveryMethodActive : false,
            })
        },
        closePopup(){
            this.expressDeliveryInfo=false
        },

        getStreetSuggestions: _.debounce(function () {
            if (this.input.street.length < 1) {
                return;
            } else {
                this.getDadataStreet(this.input.street)
            }
        }, 500),

        selectHouse (house) {
            this.focus.house=false
            this.selectFromSuggestion(house).then(res => {
              this.clicked.house = true
              this.submit.house = false
              this.delivery.postal_code = this.deliverySelectedSuggestion.data.postal_code
              this.delivery.house = this.deliverySelectedSuggestion.data.house
            })
        },

        selectStreet (street) {
            this.focus.street=false
            this.selectFromSuggestion(street).then(res => {
              this.clicked.street = true
              this.submit.street = false
              this.delivery.country_iso_code = this.deliverySelectedSuggestion.data.country_iso_code
              this.delivery.street = this.deliverySelectedSuggestion.data.street_with_type
            })
        },

        selectCity (city) {
            this.focus.city=false
            this.selectFromSuggestion(city).then(res => {
              this.clicked.country = true
              this.clicked.city = true
              this.submit.city = false
              this.$emit('getDeliveryTypes',{
                iso: this.deliverySelectedSuggestion.data.country_iso_code,
                country: this.deliverySelectedSuggestion.data.country,
                reg: this.deliverySelectedSuggestion.data.region,
                city: this.deliverySelectedSuggestion.data.city
              })
            })
            this.delivery.postal_code = this.deliverySelectedSuggestion.data.postal_code
            this.delivery.city = this.deliverySelectedSuggestion.data.city_with_type
            this.delivery.country = this.deliverySelectedSuggestion.data.country
            this.delivery.street = ""
            this.delivery.house = ""
            this.delivery.country_iso_code = this.deliverySelectedSuggestion.data.country_iso_code
        },

        selectCountry (country) {
            this.focus.country=false
            this.selectFromSuggestion(country).then(res => {
              this.clicked.country = true
              this.submit.country = false
            })
            this.delivery.postal_code = this.deliverySelectedSuggestion.data.postal_code
            this.delivery.country = this.deliverySelectedSuggestion.value
            this.delivery.country_iso_code = this.deliverySelectedSuggestion.data.country_iso_code
            this.delivery.city = ""
            this.delivery.street = ""
            this.delivery.house = ""
        },

        focusOnly(active) {
            this.flushDeliverySuggestions();
            for (const property in this.focus) {
                this.focus[property] = false;
            }
            this.focus[active] = true;
        },

        sendSelectedDeliveryTypeToGTM(){
            this.$gtm.trackEvent({
                event: 'flushVariables',
                ecommerce: null,
            });

            let products = []

            for(let item of this.cart.data.relationships.orderLines.data){
                products.push({
                    name: item.relationships.product.data.attributes.title,
                    id: item.relationships.product.data.attributes.article_number,
                    price: item.attributes.price,
                    category: item.relationships.product.data.attributes.category.data.attributes.title,
                    quantity: item.attributes.userQuantity,
                    variant: item.relationships.product.data.attributes.article_number + "" + item.attributes.sizeId,
                })
            }
            this.$gtm.trackEvent({
                event: "add_shipping_info",
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: 2,
                            option: this.selectedDeliveryRegionDetail.relationships.deliveryType.data.attributes.title
                        },
                        products: products
                    }

                }
            });
        },

        boxberry_callback_function(result){

            this.pick_up_point= result.address;
            this.pick_up_point_id = result.id
        },

        getBoxberryPickUpPoints(){
            boxberry.open(
                this.boxberry_callback_function,
                process.env.MIX_BOXBERRY_KEY,
                this.deliverySelectedSuggestion.data.city
            )
        },
        closePopups() {
            this.isAbroadDeliveryIsNotAvailablePopupVisible = false;
            this.isDeliveryRestrictionPopupVisible = false;
            this.isDeliveryWarningPopupVisible = false;
        },
    },
    mounted() {
        const insertScript = document.createElement('script')
        insertScript.setAttribute('src', 'https://points.boxberry.de/js/boxberry.js')
        document.body.appendChild(insertScript)

    }

}
</script>

<style lang="css">
.custom-border {
    border: 1px solid black;
}
</style>
