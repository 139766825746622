import axios from '@/api';

const state = {
    subscriptionPopupStatus: JSON.parse(localStorage.getItem('subscriptionPopupStore')) || null,
    subscriptionPopupDate: JSON.parse(localStorage.getItem('subscriptionPopupDateStore')) || null,

    newsSubscriptionPopup:null,
    newsSubscriptionPopupStatus:'loading',

};

const getters = {

    subscriptionPopupStatus: state => {
        return state.subscriptionPopupStatus;
    },
    subscriptionPopupDate: state => {
        return state.subscriptionPopupDate;
    },
    newsSubscriptionPopup: state => {
        return state.newsSubscriptionPopup;
    },
    newsSubscriptionPopupStatus: state => {
        return state.newsSubscriptionPopupStatus;
    },


};

const actions = {
    closeSubscriptionPopup({commit, state}){

        commit('setSubscriptionPopupStatus', 'closed');
        commit('setSubscriptionPopupDate', new Date());

    },
    subscribeFromPopup({commit, state}, contactData) {

        axios.post('/api/subscribe', contactData)
            .then(res => {
                commit('setSubscriptionPopupStatus', 'success')
            })
            .catch(error => {
                commit('setSubscriptionPopupStatus', 'error')

            });
    },

    fetchNewsSubscriptionPopup({commit, dispatch}, ) {
        commit('setNewsSubscriptionPopupStatus', 'loading')
        axios.get('/api/news-subscription-popup')
            .then(res => {
                commit('setNewsSubscriptionPopup', res.data)
                commit('setNewsSubscriptionPopupStatus', 'success')
            })
            .catch(error => {
                commit('setNewsSubscriptionPopupStatus', 'error')

            });

    },




};

const mutations = {
    setSubscriptionPopupStatus(state, status) {
        state.subscriptionPopupStatus = status ;
        localStorage.setItem("subscriptionPopupStore", JSON.stringify(state.subscriptionPopupStatus))
    },
    setSubscriptionPopupDate(state, subscriptionPopupDate ) {
        state.subscriptionPopupDate = subscriptionPopupDate ;
        localStorage.setItem("subscriptionPopupDateStore", JSON.stringify(state.subscriptionPopupDate))
    },

    setNewsSubscriptionPopup(state, newsSubscriptionPopup) {
        state.newsSubscriptionPopup = newsSubscriptionPopup;
    },
    setNewsSubscriptionPopupStatus(state, status) {
        state.newsSubscriptionPopupStatus = status;
    }

};

export default {
    state, getters, actions, mutations
}
