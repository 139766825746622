import axios from '@/api';


const state = {
    giftCertificate: null,
    giftCertificateStatus: 'not loaded',
    giftCertificateErrors: null,
    giftCertificateBlock:null,
    giftCertificateBlockStatus:'loading',
};

const getters = {
    giftCertificate: state => {
        return state.giftCertificate;
    },
    giftCertificateStatus: state => {
        return state.giftCertificateStatus;
    },
    giftCertificateErrors: state => {
        return state.giftCertificateErrors;
    },
    giftCertificateBlock: state => {
        return state.giftCertificateBlock;
    },
    giftCertificateBlockStatus: state => {
        return state.giftCertificateBlockStatus;
    },
};

const actions = {

    getGiftCertificateBlock ({commit, dispatch}, giftBlockId){
        commit('setGiftCertificateBlockStatus', 'loading')
        axios.get('/api/gift-certificates-block/'+ giftBlockId )
            .then(res => {
                commit('setGiftCertificateBlock', res.data)
                commit('setGiftCertificateBlockStatus', 'success')
            })
            .catch(() => {
                commit('setGiftCertificateBlockStatus', 'error')
            });
    },

    async fetchGiftCertificate ({commit, dispatch}, giftCertificateCode){
        commit('SET_GIFT_CERTIFICATE_STATUS', 'loading')
        await axios.get('/api/gift-certificates/'+ giftCertificateCode )
            .then(res => {
                commit('SET_GIFT_CERTIFICATE', res.data)
                commit('SET_GIFT_CERTIFICATE_STATUS', 'success')
            })
            .catch((error) => {
                commit('SET_GIFT_CERTIFICATE', null)
                commit('SET_GIFT_CERTIFICATE_STATUS', 'error');
                commit('SET_GIFT_CERTIFICATE_ERROR', error.response.data)
            });
    },
    clearGiftCertificate({commit}){
        commit('SET_GIFT_CERTIFICATE', null);
        commit('SET_GIFT_CERTIFICATE_STATUS', 'not loaded');
    }

};

const mutations = {

    SET_GIFT_CERTIFICATE(state, giftCertificate) {
        state.giftCertificate = giftCertificate;
    },
    SET_GIFT_CERTIFICATE_STATUS(state, status) {
        state.giftCertificateStatus = status;
    },
    SET_GIFT_CERTIFICATE_ERROR(state, errors) {
        state.giftCertificateErrors = errors;
    },

    setGiftCertificateBlock(state, giftCertificateBlock) {
        state.giftCertificateBlock = giftCertificateBlock;
    },
    setGiftCertificateBlockStatus(state, status) {
        state.giftCertificateBlockStatus = status;
    }
};

export default {
    state, getters, actions, mutations,
}
