<template>
    <div class="relative">
        <div class="flex items-center cursor-pointer"
             @click="areOptionsVisible=!areOptionsVisible">
            <div v-for="size in sizes"
                 :key="size.id"
                 class="h-10 flex items-center justify-center cursor-pointer align-middle bg-white">
                <div v-if="size.data.size_id === selectedSize">
                    {{size.data.attributes.title}}
                </div>
            </div>
            <div class="ml-2 lg:hidden">
                <svg :class="[areOptionsVisible ? 'transform rotate-180' : '']"
                     width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 4L7 1" stroke="black" stroke-linecap="square"/>
                    <path d="M1 1.00024L4 4.00024" stroke="black" stroke-linecap="square"/>
                </svg>
            </div>
            <div class="hidden lg:block lg:ml-2">
                <svg :class="[areOptionsVisible ? 'transform rotate-180' : '']"
                     width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5 5.5L10 1" stroke="black" stroke-linecap="square"/>
                    <path d="M1 1.00037L5.5 5.50037" stroke="black" stroke-linecap="square"/>
                </svg>
            </div>
        </div>
        <div class="w-14 mt-10 left-0 top-0 absolute z-30 bg-white cursor-pointer border-t border-l border-r border-gray-lighter lg:w-21"
        v-if="areOptionsVisible">
            <div v-for="size in sizes"
                  :key="size.data.size_id"
                  :class="[size.data.relations.availableStocks.mainStockQuantity > 0 ? 'h-6 flex items-center justify-center cursor-pointer hover:bg-black hover:text-white bg-white align-middle border-b border-gray-lighter lg:h-10'
                        : 'h-6 flex text-gray-lightest items-center justify-center cursor-pointer align-middle border-b border-gray-lighter lg:h-10']"
                 @click="selectOption(size)">
                {{size.data.attributes.title}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Select",
    props: {
        sizes: null,
        selectedSize: null,
    },
    data: () => ({
        areOptionsVisible: false,
    }),

    methods: {
        selectOption (option) {
            if(option.data.relations.availableStocks.mainStockQuantity > 0) {
                console.log(option.data.size_id)
                this.$emit('changeSize', option.data.size_id);
            }
            this.hideSelect();
        },
        hideSelect() {
            this.areOptionsVisible = false;
        }
    },
    mounted () {
        document.addEventListener('click', this.hideSelect.bind(this), true);
    },
    beforeDestroy () {
        document.removeEventListener('click', this.hideSelect)
    }
}
</script>

<style scoped>

</style>
