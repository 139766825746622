<template>
    <div>
        <div v-if="filteredStatus !== 'error'">
            <FiltersAndSorting
                :categoryId="categoryId"
                :categoryTitle="categoryTitle"
                @applyFiltersAndSorting="filteredAndSortedProducts"
            />
            <AutoLoaderWrapper @action="loadMoreProducts">
                <div v-if="filteredStatus === 'success' || filteredProducts.data?.length > 0" class="px-0.5">   
                    <ProductBlock
                        v-for="(product, index) in filteredProducts.data"
                        :key="product.data?.product_id"
                        :class="[
                            product.data.attributes.full_screen
                                ? 'grid justify-center mb-12 '
                                : 'w-1/2 lg:w-1/4 mx-auto inline-block align-top px-0.5 mb-12',
                        ]"
                        :product="product"
                        :gtm-position="index"
                        :gtm-list="gtmList"
                        :block-type="blockType"
                    />
                </div>
            </AutoLoaderWrapper>
            <Loading v-if="filteredStatus === 'loading'" />
        </div>
        <NotFound v-else />
    </div>
</template>
<script>
import ProductBlock from "./ProductBlock";
import Filters from "./FiltersAndSorting/Filters.vue";
import NoProductsByFilters from "./FiltersAndSorting/NoProductsByFilters.vue";
import {mapActions, mapGetters} from "vuex";
import Loading from "./Loading.vue";
import NotFound from "../views/errors/NotFound.vue";
import FiltersAndSorting from "./FiltersAndSorting/FiltersAndSorting.vue";
import Pagination from "./FiltersAndSorting/Pagination.vue";
import AutoLoaderWrapper from './FiltersAndSorting/AutoLoaderWrapper.vue'

export default {
    name: "NonSwipableCategoryBlock",
    data: () => ({
        filtered: null,
        currentPage: 1
    }),
    props: {
        block: null,
        category: null,
        categoryId: null,
        gtmList: null,
        gtmPageType: null,
        blockType: null,
        categoryTitle: null,
    },
    computed: {
        ...mapGetters({
            filteredStatus: "filteredStatus",
            filteredProducts: "filteredProducts",
            pagination: "pagination",
            isEndContent: 'isEndContent',
            pagination: 'pagination'
        }),
    },
    components: {
        Pagination,
        FiltersAndSorting,
        NotFound,
        Loading,
        NoProductsByFilters,
        Filters,
        ProductBlock,
        AutoLoaderWrapper
    },
    methods: {
        ...mapActions({
            sortAndFilter: 'sortAndFilter',
            addPaginationPageIntoPayload: 'addPaginationPageIntoPayload',
        }),
        filteredAndSortedProducts(data) {
            this.filtered = null;
            if (data.products.data !== null) {
                this.filtered = data.products.data;
            } else {
                this.filtered = null;
            }
        },
        goToAnotherPage(data) {
            this.addPaginationPageIntoPayload({
                'pageNumber': data,
                'categoryId': this.categoryId
            })
        },
        loadMoreProducts() {
            if(this.filteredStatus !== 'loading' && !this.isEndContent) {
                this.addPaginationPageIntoPayload({
                    'pageNumber': this.currentPage + 1,
                    'categoryId': this.categoryId
                })
            } 
        },
        sendListToGTM() {
            let impressions = [];

            this.$gtm.trackEvent({
                event: "flushVariables",
                ecommerce: null,
            });

            for (let i = 0; i < this.category.length; i++) {
                impressions.push({
                    id: this.category[i].data.attributes.article_number,
                    name: this.category[i].data.attributes.title,
                    price:
                        this.category[i].data.attributes.discount != null
                            ? this.category[i].data.attributes.cost -
                            this.category[i].data.attributes.discount.data
                                .attributes.amount
                            : this.category[i].data.attributes.cost,
                    category:
                    this.category[i].data.attributes.category.data
                        .attributes.title,
                    position: i,
                    list: this.gtmList,
                });
            }

            this.$gtm.trackEvent({
                event: "view_item_list",
                pageType: this.gtmPageType,
                ecommerce: {
                    impressions: impressions,
                },
            });
        },
    },
    watch: {
        category() {
            this.sendListToGTM();
        },
        pagination(newVal) {
            this.currentPage = newVal.currentPage
        }
    },
    mounted() {
        if (this.category != null) {
            this.sendListToGTM();
        }
    },
};
</script>

<style scoped></style>
