import axios from '@/api';
import {commit} from "lodash/seq";

const state = {
    //PreorderCart
    preorderCart: null,
    preorderCartStatus: 'loading',
    preorderCartHasOrderLine: false,
    paymentTypesPreorder: null,
};

const getters = {
    preorderCart: state => {
        return state.preorderCart;
    },
    preorderCartStatus: state => {
        return state.preorderCartStatus;
    },
    preorderCartHasOrderLine: state => {
        return state.preorderCartHasOrderLine;
    },
    paymentTypesPreorder: state => {
        return state.paymentTypesPreorder;
    },

};

const actions = {

    //Cart
    async getPreorderCart({commit, dispatch}) {
        await axios.get('/api/preorder-cart')
            .then(res => {
                commit('setPreorderCart', res.data)
            })
            .catch(error => {
                //commit('setErrorInTheCart', 'error')
            });
    },
    async userDataPreorder({commit, dispatch}, {name, surname, email, phone}){
        await axios.patch('/api/user/attributes', {"email" : email, "name" : name, "surname" : surname})
            .then(res => {
                dispatch('getPreorderCart')
            })
    },
    getPaymentTypesPreorder({commit, dispatch} ){
        axios.get('/api/preorder-cart/get-payment-types')
            .then(res => {
                commit('getPaymentTypesPreorder', res.data)
                dispatch('getPreorderCart')
            })
            .catch(error => {
                    commit('setErrorInTheCart', 'error')
                }
            )
    },
    setPaymentTypePreorder({commit, dispatch}, index ){
        axios.patch('/api/preorder-cart/payment-method', {"paymentTypeId":index})
            .then(res => {
                dispatch('getPreorderCart')
            })
            .catch(error => {
                //commit('setErrorInTheCart', 'error')
            })
    },

    addOrderLineToPreorderCart({commit, dispatch}, [productId, productSizeId]){
        axios.post('/api/preorder-cart/order-line', {
                'productId': productId,
                'sizeId': productSizeId
            })
            .then(res => {
                dispatch('getPreorderCart')
            })
            .catch(error => {
                }
            )
    },
    async checkHasPreorderCartOrderLine({commit, dispatch, state}){
        await dispatch('getPreorderCart')
        if(state.preorderCart.data.relationships.orderLines.data.length > 0) {
            await commit('setPreorderCartHasOrderLine', true)
        }
    },
    replaceOrderLineInPreorderCart({commit, dispatch}, [productId, productSizeId]) {
        let guid = state.preorderCart.data.relationships.orderLines.data[0].id
        axios.post('/api/preorder-cart/order-line/'+guid+'/replace', {
            'productId': productId,
            'sizeId': productSizeId
        })
            .then(res => {
                dispatch('getPreorderCart')
            })
            .catch(error => {
                }
            )
    },
    deletePreorderPaymentType({commit, dispatch}){
        axios.delete('/api/preorder-cart/payment-method')
            .then(res => {
                dispatch('getCart')
            })
            .catch(error => {
            })
    },

    deleteOrderLineFromPreorderCart({commit, dispatch}, orderLineGuid){
        axios.delete('api/preorder-cart/order-line/'+ orderLineGuid, )
            .then(res => {
                dispatch('getPreorderCart')
            })
            .catch(error => {
                    commit('setErrorInTheCart', 'error')
                }
            )
    },
}


const mutations = {
    setPreorderCart(state, preorderCart) {
        state.preorderCart = preorderCart;
    },
    setPreorderCartHasOrderLine(state, boolean) {
        state.preorderCartHasOrderLine = boolean;
    },
    getPaymentTypesPreorder(state, types) {
        state.paymentTypesPreorder = types;
    },
};

export default {
    state, getters, actions, mutations
}
