import axios from '@/api';

const state = {
    lookalikeProducts: null,
    lookalikeProductsStatus: 'loading',
};

const getters = {
    lookalikeProducts: state => {
        return state.lookalikeProducts;
    },
    lookalikeProductsStatus: state => {
        return state.lookalikeProductsStatus;
    }
};

const actions = {
    fetchLookalikeProducts({commit, dispatch}, productId) {
        commit('setLookalikeProductsStatus', 'loading')
        axios.get('/api/lookalike-products/'+productId)
            .then(res => {
                commit('setLookalikeProducts', res.data)
                commit('setLookalikeProductsStatus', 'success')
            })
            .catch(error => {
                    commit('setLookalikeProductsStatus', 'error')

            });
    }

};

const mutations = {
    setLookalikeProducts(state, lookalikeProducts) {
        state.lookalikeProducts = lookalikeProducts;
    },
    setLookalikeProductsStatus(state, status) {
        state.lookalikeProductsStatus = status;
    }
};

export default {
    state, getters, actions, mutations,
}
