import axios from '@/api';
import {commit} from "lodash/seq";

const state = {
    //Cart
    cart:null,
    cartStatus:'loading',
    paymentTypes:[],
    isOrderLineAlreadyExistsInCart:false,
    productWhichIsInCartAlready:null,

    cartUserDataPhoneStatus: 'loading',
    error: null,
};

const getters = {
    cart: state => {
        return state.cart;
    },
    cartStatus: state => {
        return state.cartStatus;
    },
    paymentTypes: state => {
        return state.paymentTypes;
    },
    isOrderLineAlreadyExistsInCart: state => {
        return state.isOrderLineAlreadyExistsInCart;
    },
    productWhichIsInCartAlready: state => {
        return state.productWhichIsInCartAlready;
    },
    cartUserDataPhoneStatus: state => {
        return state.cartUserDataPhoneStatus;
    },
    error: state => {
        return state.error;
    },

};

const actions = {

    //Cart
    async getCart({commit, dispatch}){
        await axios.get('/api/cart')
            .then(res => {
                commit('setCart', res.data)
            })
            .catch(error => {
                commit('setErrorInTheCart', 'error')
            });
    },
    async userData({commit, dispatch}, {name, surname, email}){
        await axios.patch('/api/user/attributes', {email, name, surname})
            .then(res => {
                dispatch('getAuthStatus')
                dispatch('getCart')
                commit('setCartUserDataPhoneStatus', 'success')
                commit('setError', null)
            })
            .catch(error => {
                commit('setCartUserDataPhoneStatus', 'error')
                commit('setError', error.response.data.message)
            });
    },


    resetCartUserDataPhoneStatus({commit}) {
        commit('setCartUserDataPhoneStatus', 'loading')
    },

    //CartOrderLine
    async addOrderLineToCart({commit, dispatch, state}, [productId, productSizeId]){
        await dispatch('checkIsOrderLineAlreadyExistsInCart', [productId, productSizeId])
        if(state.isOrderLineAlreadyExistsInCart === false){
            await dispatch('addOrderLineToCartVuex', [productId, productSizeId])
        } else{
            await dispatch('incrementCartOrderLineItem', state.productWhichIsInCartAlready)
            await commit ('setOrderLineAlreadyExistsInCart', false)
        }
    },

    //CartOrderLine
    addOrderLineToCartVuex({commit, dispatch}, [productId, productSizeId]){
        axios.post('/api/cart/order-line',
            {
                'productId': productId,
                'sizeId': productSizeId
             })
            .then(res => {
                dispatch('getCart')
            })
            .catch(error => {
                }
            )
    },
    checkIsOrderLineAlreadyExistsInCart({state, commit},[productId, productSizeId]) {
        if(state.cart){
            for (let i = 0; i < state.cart.data.relationships.orderLines.data.length; i++) {
                if(productId === state.cart.data.relationships.orderLines.data[i].attributes.productId &&
                    productSizeId === state.cart.data.relationships.orderLines.data[i].attributes.sizeId)
                {
                    commit ('setOrderLineAlreadyExistsInCart', true)
                    commit ('setProductWhichIsInCartAlready', state.cart.data.relationships.orderLines.data[i])
                }
            }
        }
    },

    incrementCartOrderLineItem({commit,dispatch}, product){
        axios.patch('/api/cart/order-line/'+ product.id,
            {'productId'  : product.attributes.productId,
                'sizeId'       : product.attributes.sizeId,
                'userQuantity' : product.attributes.userQuantity + 1
            })

            .then(res => {
                dispatch('getCart')
            })
            .catch(error => {
                commit('setErrorInTheCart', 'error')
                }
            )
    },
    decrementCartOrderLineItem({commit,dispatch}, product){
        axios.patch('/api/cart/order-line/'+ product.id,
            {'productId'  : product.attributes.productId,
                'sizeId'       : product.attributes.sizeId,
                'userQuantity' : product.attributes.userQuantity - 1})
            .then(res => {
                dispatch('getCart')
            })
            .catch(error => {
                commit('setErrorInTheCart', 'error')
                }
            )
    },
    checkAvailableQuantityOfProductsInOrderLine({commit, dispatch}, product) {
        if(product.attributes.userQuantity > product.attributes.maxQuantity) {
            axios.patch('/api/cart/order-line/'+ product.id,
                {'productId'  : product.attributes.productId,
                    'sizeId'       : product.attributes.sizeId,
                    'userQuantity' : product.attributes.maxQuantity})
                .then(res => {
                    dispatch('getCart')
                })
                .catch(error => {
                        commit('setErrorInTheCart', 'error')
                    }
                )
        }
    },
    deleteOrderLineFromCart({commit, dispatch}, orderLineGuid){
        axios.delete('api/cart/order-line/'+ orderLineGuid, )
            .then(res => {
                dispatch('getCart')
            })
            .catch(error => {
                commit('setErrorInTheCart', 'error')
                }
            )
    },
    changeOrderLineSizeInCart({commit, dispatch}, {size, cartOrderLine, cartOrderLineId}){
        axios.patch('/api/cart/order-line/' + cartOrderLineId,
            {
                'productId'    : cartOrderLine.attributes.productId,
                'sizeId'       : size,
                'userQuantity' : cartOrderLine.attributes.userQuantity
            })
            .then(res => {
                dispatch('getCart')
            })
            .catch(error => {
                commit('setErrorInTheCart', 'error')
                }
            )
    },

    //CartPromoCode
    promoCode({commit, dispatch}, promoCode){
        axios.post('/api/cart/promo-code', {'code': promoCode})
            .then(res => {
                dispatch('getCart')
            })

            .catch(error => {
                }
            )
    },
    deletePromoCode({commit, dispatch}, promoCode){
        axios.delete('/api/cart/promo-code', {'code': promoCode})
            .then(res => {
                dispatch('getCart')
                commit('setPromoCodeStatus', 'success')

            })
            .catch(error => {
                }
            )
    },

    //CartGiftCertificate
    addGiftCertificate({commit,dispatch}, giftCertificate){
        axios.post('/api/cart/gift-certificate',{'code' : giftCertificate})
            .then(res => {
                dispatch('getCart')
            })
            .catch(error => {
            })
    },
    addGiftCertificateInCartCheckout({commit,state,dispatch}, giftCertificate){
        axios.post('/api/cart/gift-certificate',{'code' : giftCertificate})
            .then(res => {
                if(state.cart.data.attributes.hasPaymentMethod === false){
                    dispatch('getPaymentTypes')
                }
                dispatch('getCart')
            })
            .catch(error => {
            })
    },
    deleteGiftCertificate({commit, dispatch}, giftCertificate){
        axios.delete('/api/cart/gift-certificate', {'code': giftCertificate})
            .then(res => {
                dispatch('getCart')
            })
            .catch(error => {
                }
            )
    },
    deleteGiftCertificateInCartCheckout({commit, dispatch}, giftCertificate){
        axios.delete('/api/cart/gift-certificate', {'code': giftCertificate})
            .then(res => {
                dispatch('getCart')
                dispatch('getPaymentTypes')
            })
            .catch(error => {
                }
            )
    },

    getPaymentTypes({commit, dispatch} ){
        axios.get('/api/cart/get-payment-types')
            .then(res => {
                commit('getPaymentTypesV', res.data)
                dispatch('getCart')
            })
            .catch(error => {
                commit('setErrorInTheCart', 'error')
                }
            )
    },
    setPaymentType({commit, dispatch}, index){
        axios.patch('/api/cart/payment-method', {"paymentTypeId":index})
            .then(res => {
                dispatch('getCart')
            })
            .catch(error => {
                commit('setErrorInTheCart', 'error')
            })
    },
    deletePaymentType({commit, dispatch}){
        axios.delete('/api/cart/payment-method')
            .then(res => {
                dispatch('getCart')
            })
            .catch(error => {
            })
    },
};

const mutations = {
    setCart(state, cartIt) {
        state.cart = cartIt;
    },
    setErrorInTheCart(state,cartError){
        state.cartStatus = cartError
    },
    getPaymentTypesV(state, payment){
        state.paymentTypes =  payment
    },
    setOrderLineAlreadyExistsInCart(state, isExists){
        state.isOrderLineAlreadyExistsInCart =  isExists
    },
    setProductWhichIsInCartAlready(state, product){
        state.productWhichIsInCartAlready =  product
    },
    setCartUserDataPhoneStatus(state, status){
        state.cartUserDataPhoneStatus =  status
    },
    setError(state, error){
        state.error =  error
    },
};

export default {
    state, getters, actions, mutations
}
