<template>
    <div ref="wrapper">
        <slot></slot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            scrollEventListener: null
        }
    },
    name: 'AutoLoaderWrapper',
    mounted() {
        this.scrollEventListener = document.addEventListener('scroll', () => {
            if(this.windowHeight < window.pageYOffset + window.innerHeight) {
                this.$emit('action');
            }
        })       
    },
    methods: {

    },
    computed: {
        windowHeight() {
            return this.$refs.wrapper?.clientHeight + 100
        }
    }
};
</script>

<style scoped>

</style>
