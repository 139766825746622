<template>
    <div class="mt-10 w-full h-full lg:w-256 lg:mx-auto justify-center">
        <div>
            <div class="mx-auto flex justify-center uppercase text-16 font-light lg:text-24">
                <div class="text-black  cursor-pointer">
                    Документы
                </div>
            </div>
            <div>
                <accordion class="w-full my-10 px-5 mx-auto lg:w-195">
                    <accordion-item class="pb-4 border-b border-gray-lighter">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Политика конфиденциальности</div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template v-slot:accordionContent>
                            <div class="my-7 text-16 text-gray-light">
                                <PrivacyPolicyDoc/>
                            </div>
                        </template>
                    </accordion-item>
                    <accordion-item class="mt-4 pb-4 border-b border-gray-lighter">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Электронное согласие покупателя на обработку персональных данных и участие в
                                        бонусной программе
                                    </div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template v-slot:accordionContent>
                            <div class="my-7 text-16 text-gray-light">
                                <ConsentOfPersonalDataDoc/>
                            </div>
                        </template>
                    </accordion-item>
                    <accordion-item class="mt-4 pb-4 border-b border-gray-lighter">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Публичная оферта
                                    </div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template v-slot:accordionContent>
                            <div class="my-7 text-16 text-gray-light">
                                <OfferDoc />
                            </div>
                        </template>
                    </accordion-item>
                    <accordion-item class="mt-4 pb-4 border-b border-gray-lighter">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Пользовательское соглашение
                                    </div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template v-slot:accordionContent>
                            <div class="my-7 text-16 text-gray-light">
                                <UserAgreementDoc />
                            </div>
                        </template>
                    </accordion-item>
                    <accordion-item class="mt-4 pb-4 border-b border-gray-lighter">
                        <template v-slot:accordion-trigger="slotProps">
                            <div class="flex items-center justify-between text-16">
                                <div class="cursor-pointer">
                                    <div>Условия приобретения и использования подарочных сертификатов
                                    </div>
                                </div>
                                <div class="ml-11">
                                    <svg :class="[slotProps.visible ? 'transform rotate-180' : '']"
                                         width="8" height="5" viewBox="0 0 8 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 3.99976L7 0.999756" stroke="black" stroke-linecap="square"/>
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <template v-slot:accordionContent>
                            <div class="my-7 text-16 text-gray-light">
                                <GiftCertificateUsingRulesDoc />
                            </div>
                        </template>
                    </accordion-item>
                </accordion>
            </div>
        </div>
    </div>
</template>

<script>

import Accordion from "../components/Accordion/Accordion.vue";
import AccordionItem from "../components/Accordion/AccordionItem.vue";
import Loading from "../components/Loading.vue";
import PrivacyPolicyDoc from "../components/Documents/PrivacyPolicyDoc.vue";
import ConsentOfPersonalDataDoc from "../components/Documents/ConsentOfPersonalDataDoc.vue";
import OfferDoc from "../components/Documents/OfferDoc.vue";
import UserAgreementDoc from "../components/Documents/UserAgreementDoc.vue";
import GiftCertificateUsingRulesDoc from "../components/Documents/GiftCertificateUsingRulesDoc.vue";

export default {
    name: "Documents",
    components: {
        GiftCertificateUsingRulesDoc,
        UserAgreementDoc,
        OfferDoc, ConsentOfPersonalDataDoc, PrivacyPolicyDoc, Loading, AccordionItem, Accordion},
}

</script>

<style scoped>
</style>







