<template>
    <div class="">
        <div class="mx-6 flex items-center my-10">
            <svg class="sm:hidden mx-5 fill-current text-gray-main" role="presentation" viewBox="0 0 18 17" height="17px" width="18px">
                <g transform="translate(1 1)" stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linecap="square">
                    <path d="M16 16l-5.0752-5.0752"></path>
                    <circle cx="6.4" cy="6.4" r="6.4"></circle>
                </g>
            </svg>

            <svg class=" hidden sm:block mx-5 fill-current text-gray-main" role="presentation" viewBox="0 0 21 21" height="21px" width="21px">
                <g transform="translate(1 1)" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="square">
                    <path d="M18 18l-5.7096-5.7096"></path>
                    <circle cx="7.2" cy="7.2" r="7.2"></circle>
                </g>
            </svg>

            <input class="appearance-none rounded-none w-full text-gray-main text-lg uppercase tracking-wide appearance-none bg-transparent border-b border-gray-light   mr-3 py-1 px-2 leading-tight focus:outline-none"
                   type="text"
                   placeholder="поиск..."
                   aria-label="search"
                   id="searchTerm"
                   v-model="searchTerm"
                   @input="search"
            >

            <div @click="clearSearch" class="mx-5 cursor-pointer">
                <svg class="fill-current text-gray-main" role="presentation" viewBox="0 0 16 14" width="16px" height="14px">
                    <path d="M15 0L1 14m14 0L1 0" stroke="currentColor" fill="none" fill-rule="evenodd"></path>
                </svg>
            </div>
        </div>
        <div class="w-full ">
            <div v-if="searchTerm.length < 3">
                <div  class="my-10 w-full text-center text-gray-main text-lg font-normal uppercase tracking-wide">
                    Введите поисковый запрос...

                </div>
            </div>
            <div v-else>
                <div v-if="searchResultsSortableBalance.length > 0" class="px-0.5">
                    <div class="my-10 w-full text-center text-gray-main text-lg font-normal uppercase tracking-wide">Результаты поиска по запросу "{{searchTerm}}":</div>
                    <ProductBlock v-for="(product) in searchResultsSortableBalance" :key=product.data.product_id
                                  :product = product
                                  :class="[product.data.attributes.full_screen == true ? 'grid justify-center mb-12 ':'w-1/2 lg:w-1/4 mx-auto inline-block align-top px-0.5 mb-12 ']"
                                  gtm-list="Результаты поиска"
                                  gtm-page-type="search_result"
                                  block-type="non-swipable-category-block"
                    />
                </div>
                <div v-else>
                    <div class="my-10 w-full text-center text-gray-main text-lg font-normal uppercase tracking-wide">По запросу "{{searchTerm}}" ничего не нашлось. Другие идеи для вас: </div>
                </div>

            </div>
            <div data-popmechanic-embed="40994" ></div>
        </div>


    </div>
</template>

<script>
import _ from 'lodash';
import NonSwipableCategoryBlock from "../components/NonSwipableCategoryBlock";
import ProductBlock from "../components/ProductBlock.vue";
import axios from '@/api';

export default {
    name: "Search",
    components: {
        ProductBlock,
        NonSwipableCategoryBlock,
    },
    data: () => ({
        searchTerm: '',
        searchResults: [],
        searchBestResult: [],
        searchResultsSortableBalance:[],
        searchResultsBalance: [],
        searchResultsZeroBalance: [],
        searchResultsForGTM: [],
        middleResult:[],
        products:[],
        i:0
    }),
    methods: {
        search: _.debounce(function (e){
            if (this.searchTerm.length < 3 ) {
                return;
            }
            else {
                axios.post('/api/search-product', {searchTerm: this.searchTerm})
                    .then(response => {

                        this.searchResults = response.data.data;
                        this.searchResultsZeroBalance = [];
                        this.searchResultsBalance = [];
                        this.searchResultsSortableBalance = [];
                        this.searchResultsForGTM = [];
                        this.searchBestResult = [];
                        this.middleResult = [];
                        this.products = [];

                        for (let item of this.searchResults) {
                            this.products.push({
                                id:item.data.attributes.article_number,
                                price:item.data.attributes.cost
                            })
                            this.searchResultsForGTM = {products:this.products}
                            if (item.data.attributes.title == this.searchTerm) {
                                this.searchBestResult.push(item)
                            } else if (item.data.attributes.active_stock_balance > 0 ) {
                                 this.searchResultsBalance.push(item)
                            } else {
                                 this.searchResultsZeroBalance.push(item)
                            }
                        }
                        if (this.searchBestResult.length >0) {
                            this.middleResult=this.searchResultsBalance.concat(this.searchResultsZeroBalance);
                            this.searchResultsSortableBalance= this.searchBestResult.concat(this.middleResult)
                        } else {
                            this.searchResultsSortableBalance = this.searchResultsBalance.concat(this.searchResultsZeroBalance)
                        }
                        this.$gtm.trackEvent({
                            event: "search",
                            searchTerm: this.searchTerm,
                            searchItems: this.searchResultsForGTM,
                            noninteraction: false,
                        });
                    })
                    .catch(error=> {

                    });
            }
        }, 500),
        clearSearch () {
            this.searchTerm = '';
            this.searchBestResult = [];
            this.middleResult = [];
            this.searchResults = [];
            this.searchResultsZeroBalance = [];
            this.searchResultsBalance = [];
            this.searchResultsSortableBalance = [];
            this.searchResultsForGTM = [];
        }
    },
}
</script>

<style scoped>

</style>
