<template>
    <div v-if="footerStatus=='success'" class=" mx-6 sm:mx-12 flex flex-col-reverse sm:flex-row items-top border-t border-gray-lighter">
        <div class="my-6 sm:my-12 w-full sm:w-1/4">
            <h2 class="text-gray-main text-base font-normal normal-case">Present & Simple</h2>
            <div class="mt-6 w-full flex">
                <a href="https://wa.me/78003010424"  class="text-gray-light text-base font-normal" target="_blank">
                    WhatsApp
                </a>
                <a href="https://t.me/presentandsimple_com" class="text-gray-light text-base font-normal ml-3 " target="_blank">
                    Telegram
                </a>
                <a href="https://vk.com/presentandsimplecom" class="text-gray-light text-base font-normal ml-3 " target="_blank">
                    VK
                </a>
            </div>
            <div class="mt-6">
                <a href="tel:+78003010424" class="text-16 text-gray-light">
                    +7 (800) 301-04-24
                </a>
                <div class="text-11 text-gray-lightest">
                    Ежедневно 10:00–19:00 мск
                </div>
            </div>
        </div>


        <div v-for="(item_category, index) in footer.data"
                     :key="item_category.data.footer_category_id"
                     class="my-6 sm:my-12 sm:w-1/4">
            <h2 class="text-gray-main text-base font-normal uppercase">{{item_category.data.attributes.title}}</h2>
            <div v-for="sub_item_category in item_category.data.attributes.footer_items.data"
                 :key="sub_item_category.data.footer_item_id"
                 class="mt-2">
                <router-link class="text-gray-light text-base font-normal" :to="sub_item_category.data.attributes.path">{{sub_item_category.data.attributes.title}}</router-link>
            </div>
        </div>

        <div v-if="footerSubscription" class="my-6 sm:my-12 w-full sm:w-1/4">
            <h2 class="text-gray-main text-base font-normal uppercase">{{footerSubscription.data.attributes.title}}</h2>
            <div class="block text-gray-light text-base font-normal mt-2 ">
                {{footerSubscription.data.attributes.main_text}}
            </div>
            <div class="block text-gray-light text-base font-normal mt-1 ">
                {{footerSubscription.data.attributes.text_before_button}}
            </div>
            <form class="w-full max-w-sm">
                <div class="flex items-baseline pt-2">
                    <input class="appearance-none rounded-none bg-transparent border-b border-gray-light w-full text-gray-main mr-3 py-1 px-2 leading-tight focus:outline-none"
                           type="text"
                           placeholder="example@mail.com"
                           aria-label="E-mail"
                           name="email"
                           v-model="email"
                    >
                        <button class="flex-shrink-0 bg-black border-black text-sm border-4 text-white py-1 px-2 uppercase tracking-wide focus:outline-none"
                                type="button"
                                @click="subscribeFromFooter(email)">
                            Подписаться
                        </button>
                </div>
                <div class="mt-2 text-xs tracking-normal text-gray-light">
                    Нажимая на кнопку «Подписаться», я соглашаюсь на обработку моих персональных данных и ознакомлен(а) с условиями <router-link class="underline" to="/info/privacy-policy" target="_blank">конфиденциальности</router-link>
                </div>
                    <span class="text-xs text-gray-main" v-if="subscribeEmailStatus=='loading'">
                        Оформляем подписку...
                    </span>
                        <span class="text-xs text-green-500"  v-if="subscribeEmailStatus=='success'">
                        Успех!
                    </span>
                        <span class="text-xs text-red-500" v-if="subscribeEmailStatus=='error'">
                        Ошибка
                    </span>
            </form>
        </div>
    </div>
</template>
<script>
    import {mapGetters, mapActions} from "vuex";
    export default {
        name: "Footer",
        methods: {
            ...mapActions({
                setSubscribeEmail: 'setSubscribeEmail',
                subscribe: 'subscribe',
                fetchFooterSubscription: 'fetchFooterSubscription',
            }),
            subscribeFromFooter(email) {
                this.subscribe({
                    'email' : email,
                    'source' : 'footer',
                });
                this.$gtm.trackEvent({
                    event: "subscribeFromFooter",
                    clientEmail: email,
                });
            }
        },
        computed: {
            ...mapGetters({
                subscribeEmail: 'subscribeEmail',
                subscribeEmailStatus: 'subscribeEmailStatus',
                footer: 'footer',
                footerStatus: 'footerStatus',
                footerSubscription: 'footerSubscription',
                footerSubscriptionStatus: 'footerSubscriptionStatus',
            }),
            email: {
                get(){
                    return this.subscribeEmail;
                },
                set(subscribeEmail){
                    this.setSubscribeEmail(subscribeEmail);
                }
            },
        },
        mounted () {
            this.$store.dispatch('fetchFooter');
            this.$store.dispatch('fetchFooterSubscription', 1);
        },
    }
</script>

<style scoped>

</style>
