<template>
    <div v-if="isVisible"
        class="fixed bottom-0 left-0 z-30 w-full bg-black text-white p-4 shadow-lg transform transition-transform duration-300 ease-in-out"
        :class="{ 'translate-y-0': showPopup, 'translate-y-full': !showPopup }">
        <div class="flex justify-between items-center">
            <div class="text-14 lg:text-16 lg:flex-1 lg:text-center">
                <p>Оставаясь на сайте, вы даете согласие на использование
                    <router-link class="underline" to="/documents" target="_blank">Cookie</router-link>
                </p>
            </div>
            <button @click="closePopup" class="text-gray-300 hover:text-white ml-4">
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 10L10 1" stroke="#D9D9D9" stroke-linecap="square"/>
                    <path d="M1 1.00024L10 10.0002" stroke="#D9D9D9" stroke-linecap="square"/>
                </svg>
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: "CookiesAcceptPopup",
    data() {
        return {
            showPopup: false,
            isVisible: false,
        };
    },
    methods: {
        checkCookieConsent() {
            const consent = localStorage.getItem("cookieConsent");
            if (!consent) {
                this.isVisible = true;
                this.$nextTick(() => {
                    this.showPopup = true;
                });
            }
        },
        closePopup() {
            this.showPopup = false
            localStorage.setItem("cookieConsent", "true");
            setTimeout(() => {
                this.isVisible = false;
            }, 300);
        },
    },
    mounted() {
        this.checkCookieConsent();
    },
}
</script>

<style scoped>

</style>

