import axios from '@/api';
import qs from "qs";

const state = {
    payload: {
        filter: {
            mainCategoryId: null,
            sizes: [],
            stocks: [],
            categories: [],
            colors: [],
            price: {
                from: null,
                to: null,
            },
        },
        pagination: {
            page: 1
        },
        sort: "default",
    },
    pagination: null,
    rangeOfPricesValue: [],
    minValue: 0,
    mainCategoryId: null,
    maxValue: 1000000,

    filteredStatus: 'loading',

    filteredProducts: {
        data: [],
        meta: {
            pagination: {
                currentPage: 1
            }
        }
    },
    isEnd: false,

    availableFiltersForCategoryLocal: null,
};

const getters = {
    payload: state => {
        return state.payload;
    },
    filteredProducts: state => {
        return state.filteredProducts;
    },
    availableFiltersForCategoryLocal: state => {
        return state.availableFiltersForCategoryLocal;
    },
    filteredStatus: state => {
        return state.filteredStatus;
    },
    minValue: state => {
        return state.minValue;
    },
    maxValue: state => {
        return state.maxValue;
    },
    rangeOfPricesValue: state => {
        return state.rangeOfPricesValue;
    },
    pagination: state => {
        return state.pagination;
    },
    isEndContent: state => {
        return state.isEnd;
    },
    currentPage: state => {
        return state.filteredProducts.meta.pagination.currentPage;
    }
};


const actions = {
    //Методы для оттображения подчеркивания
    findAllClickedElements({dispatch}, resData) {
        dispatch('findClickedElement', {data: resData, filterType: 'colors', action: 'findClicked'});
        dispatch('findClickedElement', {data: resData, filterType: 'sizes', action: 'findClicked'});
        dispatch('findClickedElement', {data: resData, filterType: 'stocks', action: 'findClicked'});
    },
    unClickAllElements({dispatch}, resData) {
        dispatch('findClickedElement', {data: resData, filterType: 'colors', action: 'unClickedAll'});
        dispatch('findClickedElement', {data: resData, filterType: 'sizes', action: 'unClickedAll'});
        dispatch('findClickedElement', {data: resData, filterType: 'stocks', action: 'unClickedAll'});
    },
    incrementCurrentPage() {
        state.filteredProducts.meta.pagination.currentPage = state.filteredProducts.meta.pagination.currentPage + 1
    },
    findClickedElement({state}, {data, filterType, action}) {
        const filters = data[filterType].data;
        filters.forEach(filter => {
            if (action === 'findClicked') {
                filter.data.clicked = !!state.payload.filter[filterType].find(element => element === filter.data.id);
            } else if (action === 'unClickedAll') {
                filter.data.clicked = false;
            }
        });
    },
    cleanPayloadFilterColors({commit, dispatch}) {
        dispatch('findClickedElement', {
            data: state.availableFiltersForCategoryLocal,
            filterType: 'colors',
            action: 'unClickedAll'
        });
        commit('CLEAN_PAYLOAD_FILTER_COLORS')
    },
    cleanPayloadFilterSizes({commit, dispatch}) {
        dispatch('findClickedElement', {
            data: state.availableFiltersForCategoryLocal,
            filterType: 'sizes',
            action: 'unClickedAll'
        });
        commit('CLEAN_PAYLOAD_FILTER_SIZES')
    },
    cleanPayloadFilterStocks({commit, dispatch}) {
        dispatch('findClickedElement', {
            data: state.availableFiltersForCategoryLocal,
            filterType: 'stocks',
            action: 'unClickedAll'
        });
        commit('CLEAN_PAYLOAD_FILTER_STOCKS')
    },
    resetAllFilters({state, commit, dispatch}) {
        dispatch('unClickAllElements', state.availableFiltersForCategoryLocal);
        commit('CLEAN_PAYLOAD_FILTERS', [state.minValue, state.maxValue])
    },

    putTypeOfSortingIntoPayload({commit, dispatch}, typeOfSorting) {
        commit('PUT_TYPE_OF_SORTING_INTO_PAYLOAD', typeOfSorting)
    },

    generateFilterData({state}, filterType) {
        const filterData = [];
        const filters = state.availableFiltersForCategoryLocal
            ? state.availableFiltersForCategoryLocal[filterType].data
            : null;
        if (filters) {
            filters.forEach(filter => {
                if (filter.data.clicked) {
                    filterData.push(filter.data.id);
                }
            });
        }
        return filterData;
    },

    putColorIntoFilter({state, commit}, color) {
        color.data.clicked = !color.data.clicked;
    },

    putSizeIntoFilter({state}, size) {
        size.data.clicked = !size.data.clicked;
    },

    async putCategoriesIntoFilter({state, commit, dispatch}, categoryId) {
        commit('setCategoriesToPayload', categoryId);
    },

    putStockIntoFilter({state}, stock) {
        stock.data.clicked = !stock.data.clicked;
    },

    async initFilterData({state, dispatch, commit}) {
        state.payload.filter.colors = state.availableFiltersForCategoryLocal
            ? await dispatch('generateFilterData', 'colors')
            : [];
        state.payload.filter.sizes = state.availableFiltersForCategoryLocal
            ? await dispatch('generateFilterData', 'sizes')
            : [];

        state.payload.filter.stocks = state.availableFiltersForCategoryLocal
            ? await dispatch('generateFilterData', 'stocks')
            : [];

        commit("setAvailableFiltersForCategoryLocal", state.availableFiltersForCategoryLocal);
    },
    async filter({state, dispatch, commit}) {
        if(state.isEnd) return
        commit('setFilteredStatus', 'loading')

        try {
            const response = await axios.get('/api/products', {
                params: state.payload,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'brackets'}),
            });

            dispatch('checkForEnd', response.data.meta.pagination);
            dispatch('findAllClickedElements', state.availableFiltersForCategoryLocal);

            commit('setRangeOfPricesValue', [state.payload.filter.price.from, state.payload.filter.price.to]);
            commit('setPayload', state.payload);           
            commit('setFilteredProducts', response.data);
            commit('setPagination', response.data.meta.pagination);
            commit('setFilteredStatus', 'success');
        } catch (error) {
            commit('setFilteredStatus', 'error');
        }
    },
    async getAvailableRanges({state, dispatch, commit}, categoryId) {
        await axios.get('/api/available-ranges?mainCategoryId=' + categoryId)
            .then(response => {
                dispatch('unClickAllElements', response.data);
                commit('setAvailableFiltersForCategoryLocal', response.data);
                commit('setMinValue', state.availableFiltersForCategoryLocal.price.from);
                commit('setMaxValue', state.availableFiltersForCategoryLocal.price.to);
                commit('setRangeOfPricesValue', [state.minValue, state.maxValue]);
                commit('setPricesToPayload', state.rangeOfPricesValue);
            })
    },
    async addPaginationPageIntoPayload({state, dispatch, commit}, {pageNumber, categoryId}) {
        await commit('setPaginationCurrentPageNumber', pageNumber)
        await dispatch('sortAndFilter', categoryId)
    },

    async sortAndFilter({state, dispatch, commit}, categoryId) {
        await commit('setMainCategoryId', categoryId)
        if (!state.payload.pagination || !state.payload.pagination.page) {
            await commit('setPaginationFirstPage');
        }
        if (!state.availableFiltersForCategoryLocal || state.payload.filter.mainCategoryId !== categoryId /*|| [15, 195].includes(categoryId)*/) {
            await commit('setNewPayloadOnFirstLoadOfCategory', categoryId);
            await commit('cleanFiltersAndSortingInPayload', [[]]);
            await dispatch('getAvailableRanges', categoryId)
        } else {
            await commit('setMainCategoryIdToPayload', categoryId);
            await dispatch('initFilterData');
        }
        await dispatch('filter')
    },

    checkForEnd({commit}, pagination) {
        commit('setIsEndStatus', pagination.currentPage >= pagination.totalPages)
    },

    setRangeOfPricesValue({commit}, value) {
        commit('setRangeOfPricesValue', value);
        commit('setPricesToPayload', state.rangeOfPricesValue);
    },

    resetIsEndStatus({state, commit}) {
        state.filteredProducts.meta.pagination.currentPage = 1
        state.payload.pagination.page = 1
        commit('setIsEndStatus', false);
    }
};

const mutations = {
    PUT_TYPE_OF_SORTING_INTO_PAYLOAD(state, typeOfSorting) {
        if (state.payload) {
            state.payload.sort = typeOfSorting;
            state.payload.pagination.page = 1
            //localStorage.setItem('payload', JSON.stringify(state.payload));
        } else {
            console.error('Payload is undefined in mutation');
        }
    },
    setPaginationCurrentPageNumber(state, pageNumber) {
        state.payload.pagination.page = pageNumber
        //localStorage.setItem('payload', JSON.stringify(state.payload));
    },
    setMainCategoryId(state, mainCategoryId) {
        state.mainCategoryId = mainCategoryId
        //localStorage.setItem('mainCategoryId', mainCategoryId)
    },

    setFilteredProducts(state, products) {
        if(products.meta.pagination.currentPage === 1) {
            state.filteredProducts.data = products.data
        } else {
            state.filteredProducts.data = [...state.filteredProducts.data, ...products.data]
        }
        state.filteredProducts.meta = products.meta
    },

    setIsEndStatus(state, status) {
        state.isEnd = status;
    },

    setAvailableFiltersForCategoryLocal(state, filters) {
        state.availableFiltersForCategoryLocal = filters;
        //localStorage.setItem('availableFiltersForCategoryLocal', JSON.stringify(filters));
    },

    setPayload(state, payload) {
        state.payload = payload;
        //localStorage.setItem('payload', JSON.stringify(payload));
    },
    setPagination(state, pagination) {
        state.pagination = pagination
    },

    setMinValue(state, minValue) {
        state.minValue = minValue;
        //localStorage.setItem('minValue', JSON.stringify(minValue));
    },

    setMaxValue(state, maxValue) {
        state.maxValue = maxValue;
        //localStorage.setItem('maxValue', JSON.stringify(maxValue));
    },

    setFilteredStatus(state, status) {
        state.filteredStatus = status;
    },

    setRangeOfPricesValue(state, newValue) {
        if (Array.isArray(newValue) && newValue.length === 2) {
            state.rangeOfPricesValue = newValue
            //localStorage.setItem('rangeOfPricesValue', JSON.stringify(newValue));
        } else {
            console.error('Invalid value for rangeOfPricesValue:', newValue);
        }
    },

    cleanFiltersAndSortingInPayload(state) {
        state.payload.filter.colors = [];
        state.payload.filter.sizes = [];
        state.payload.filter.stocks = [];
        state.payload.filter.categories = [];
        state.payload.filter.price.from = null
        state.payload.filter.price.to = null
        state.payload.pagination.page = 1
        state.payload.sort = 'default'
        //localStorage.setItem('payload', JSON.stringify(state.payload));
    },

    setNewPayloadOnFirstLoadOfCategory(state, categoryId) {
        state.payload.filter.mainCategoryId = categoryId;
        //localStorage.setItem('payload', JSON.stringify(state.payload));
    },

    setMainCategoryIdToPayload(state, mainCategory) {
        state.payload.filter.mainCategoryId = mainCategory;
        //localStorage.setItem('payload', JSON.stringify(state.payload));
    },

    CLEAN_PAYLOAD_FILTER_COLORS(state) {
        state.payload.filter.colors = [];
        //localStorage.setItem('payload', JSON.stringify(state.payload));
    },
    CLEAN_PAYLOAD_FILTER_SIZES(state) {
        state.payload.filter.sizes = [];
        //localStorage.setItem('payload', JSON.stringify(state.payload));
    },
    CLEAN_PAYLOAD_FILTER_STOCKS(state) {
        state.payload.filter.stocks = [];
        //localStorage.setItem('payload', JSON.stringify(state.payload));
    },
    CLEAN_PAYLOAD_FILTERS(state, priceValues) {
        state.payload.filter.colors = [];
        state.payload.filter.sizes = [];
        state.payload.filter.stocks = [];
        state.payload.filter.categories = [];
        state.payload.filter.price.from = priceValues[0]
        state.payload.filter.price.to = priceValues[1]
        state.payload.sort = 'default'
        state.payload.pagination.page = 1
        //localStorage.setItem('payload', JSON.stringify(state.payload));
    },

    setCategoriesToPayload(state, id) {
        state.payload.filter.categories = [id];
        state.payload.pagination.page = 1
        //localStorage.setItem('payload', JSON.stringify(state.payload));
    },
    setPaginationFirstPage(state) {
        state.payload.pagination.page = 1
        //localStorage.setItem('payload', JSON.stringify(state.payload));
    },

    setPricesToPayload(state, rangeOfPrices) {
        state.payload.filter.price.from = rangeOfPrices[0]
        state.payload.filter.price.to = rangeOfPrices[1]
        state.payload.pagination.page = 1

        //localStorage.setItem('payload', JSON.stringify(state.payload));
    },
};
export default {
    state, getters, actions, mutations,
}
