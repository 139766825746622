import axios from '@/api';


const state = {
    //Общий список типов доставки в корзине

    deliveryPreorderRegionDetails: null,
    deliveryPreorderRegionDetailsStatus: null,

    //поля ввода данных в корзине
    deliveryPreorderTypeCourierOrPickup: null,
    deliveryPreorderRegionDetail: null,
    deliveryPreorderCity: null,
    deliveryPreorderAddress: null,

    deliveryPreorderSuggestions: null,

    selectFromSuggestion: false,

    deliveryPreorder: {
        postal_code: "",
        country: "",
        country_iso_code: "",
        city: "",
        street: "",
        house: "",
        building: "",
        apartment: "",
        pick_up_point: "",
        pick_up_point_cdek: "",
        pick_up_point_id: "",
        date: "",
        time_interval_id: "",
        time_interval: "",
        comment:""
    },
    deliveryPreorderSelectedSuggestion: null,

};

const getters = {
    deliveryPreorder: state => {
        return state.deliveryPreorder;
    },

    deliveryPreorderSelectedSuggestion: state => {
        return state.deliveryPreorderSelectedSuggestion;
    },

    deliveryPreorderRegionDetail: state => {
        return state.deliveryPreorderRegionDetail;
    },

    deliveryPreorderTypeCourierOrPickup: state => {
        return state.deliveryPreorderTypeCourierOrPickup;
    },

    deliveryPreorderSuggestions: state => {
        return state.deliveryPreorderSuggestions;
    },

    deliveryPreorderRegionDetails: state => {
        return state.deliveryPreorderRegionDetails;
    },

    deliveryPreorderRegionDetailsStatus: state => {
        return state.deliveryPreorderRegionDetailsStatus;
    },
    deliveryPreorderWarningMessage: state => {
        return state.deliveryPreorderWarningMessage;
    },
    deliveryPreorderWarningMessageStatus: state => {
        return state.deliveryPreorderWarningMessageStatus;
    },
    deliveryPreorderRestrictedMessage: state => {
        return state.deliveryPreorderRestrictedMessage;
    },
    deliveryPreorderRestrictedMessageStatus: state => {
        return state.deliveryPreorderRestrictedMessageStatus;
    },


};

const actions = {

    setPreorderDeliveryRegionDetail({commit, state}, deliveryRegionDetail){
        //Устанавливаем выбранный клиентом тип доставки
        commit('SET_PREORDER_DELIVERY_REGION_DETAIL', deliveryRegionDetail)
    },
    setPreorderDeliveryTypeCourierOrPickup({commit, dispatch}, deliveryTypeCourierOrPickup){
        //Устанавливаем выбранный клиентом тип доставки
        commit('SET_PREORDER_DELIVERY_TYPE_COURIER_OR_PICKUP', deliveryTypeCourierOrPickup);
        dispatch('setPreorderDeliveryRegionDetail', null);
    },

    setPreorderDeliveryCountry({commit, state}, country){
        commit('SET_PREORDER_DELIVERY_COUNTRY', country);
        commit('SET_PREORDER_DELIVERY_CITY', null);
        commit('SET_PREORDER_DELIVERY_STREET', null);
        commit('SET_PREORDER_DELIVERY_HOUSE', null);
        commit('SET_PREORDER_DELIVERY_BUILDING', null);
        commit('SET_PREORDER_DELIVERY_APARTMENT', null);
    },
    setPreorderDeliveryCity({commit, state}, city){
        commit('SET_PREORDER_DELIVERY_CITY', city);
        commit('SET_PREORDER_DELIVERY_STREET', null);
        commit('SET_PREORDER_DELIVERY_HOUSE', null);
        commit('SET_PREORDER_DELIVERY_BUILDING', null);
        commit('SET_PREORDER_DELIVERY_APARTMENT', null);

    },
    setPreorderDeliveryStreet({commit, state}, street){
        commit('SET_PREORDER_DELIVERY_STREET', street);
        commit('SET_PREORDER_DELIVERY_HOUSE', null);
        commit('SET_PREORDER_DELIVERY_BUILDING', null);
        commit('SET_PREORDER_DELIVERY_APARTMENT', null);
    },
    setPreorderDeliveryHouse({commit, state}, house){
        commit('SET_PREORDER_DELIVERY_HOUSE', house);
        commit('SET_PREORDER_DELIVERY_BUILDING', null);
        commit('SET_PREORDER_DELIVERY_APARTMENT', null);
    },
    setPreorderDeliveryBuilding({commit, state}, building){
        commit('SET_PREORDER_DELIVERY_BUILDING', building);
        commit('SET_PREORDER_DELIVERY_APARTMENT', null);
    },
    setPreorderDeliveryApartment({commit, state}, apartment){
        commit('SET_PREORDER_DELIVERY_APARTMENT', apartment);
    },
    setPreorderDeliveryComment({commit, state}, comment){
        commit('SET_PREORDER_DELIVERY_COMMENT', comment);
    },
    setPreorderDeliveryPickupPoint({commit, state}, pick_up_point){
        commit('SET_PREORDER_DELIVERY_PICK_UP_POINT', pick_up_point);
    },
    setPreorderDeliveryPickupPointId({commit, state}, pick_up_point_id){
        commit('SET_PREORDER_DELIVERY_PICK_UP_POINT_ID', pick_up_point_id);
    },
    setPreorderDeliverySelectedDate({commit, state}, dateDelivery){
        commit('SET_PREORDER_DELIVERY_SELECTED_DATE', dateDelivery);
    },
    setPreorderDeliveryTimeIntervalId({commit, state}, deliveryTimeIntervalId){
        commit('SET_PREORDER_DELIVERY_SELECTED_TIME_INTERVAL_ID', deliveryTimeIntervalId);
    },
    setPreorderDeliveryTimeInterval({commit, state}, deliveryTimeInterval){
        commit('SET_PREORDER_DELIVERY_SELECTED_TIME_INTERVAL', deliveryTimeInterval);
    },
    setPreorderDeliveryPostalCode({commit, state}, postal_code){
        commit('SET_PREORDER_DELIVERY_POSTAL_CODE', postal_code);
    },
    async fetchPreorderDeliveryTypes({commit, dispatch}, {iso, country, reg, city}) {
        commit('setDeliveryPreorderRegionDetailsStatus', 'loading');
        await axios.get('/api/preorder-cart/get-delivery-types?countryCode='+iso+'&country='+country+'&region='+reg+'&city='+city)
            .then(res => {
                commit('setPreorderDeliveryRegionDetails', res.data)
                commit('SET_PREORDER_INITIAL_DELIVERY_REGION_DETAILS', res.data)
                commit('setDeliveryPreorderRegionDetailsStatus', 'success')
            })
            .catch(error => {
                commit('setDeliveryPreorderRegionDetailsStatus', 'error')
            });
    },

     setPreorderDeliveryType({commit, dispatch, state}, {deliveryRegionDetailId, country, countryCode,  city, street, house, building, postalCode, region, apartment, comment, pickUpPoint, pickUpPointId, deliveryDate, deliveryTimeInterval}) {
         axios.patch('/api/preorder-cart/delivery-method', {"deliveryRegionDetailId" : deliveryRegionDetailId,"city" : city, "region" : region, "country" : country, "countryCode" :countryCode,
            "street": street, "house":house, "building" :building, "apartment":apartment, "pickUpPoint":pickUpPoint,"comment": comment,"postalCode":postalCode,"pickUpPointId":pickUpPointId, "deliveryDate":deliveryDate, "deliveryTimeIntervalId":deliveryTimeInterval})
            .then(res => {
                dispatch('getPreorderCart')
                dispatch('getPaymentTypesPreorder')
            })
            .catch(error => {
            });
    },

    deletePreorderDeliveryMethod({commit, dispatch}) {
        axios.delete('/api/cart/delivery-method')
            .then(res => {
                dispatch('getCart')
            })
            .catch(error => {
            });
    },
    getPreorderDadataHouse({commit, state}, house) {

        commit('SET_DELIVERY_SUGGESTIONS', null)

        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token 4c564b73ee2bc1fcf73b311e0875088605f754cd"
            },
            body: JSON.stringify({
                query: house,
                from_bound: { value: "house" },
                to_bound: { value: "house" },
                locations: [
                    {
                        country_iso_code: state.deliveryPreorderSelectedSuggestion !=null ? state.deliveryPreorderSelectedSuggestion.data.country_iso_code : "",
                        city_fias_id: state.deliveryPreorderSelectedSuggestion !=null ? state.deliveryPreorderSelectedSuggestion.data.city_fias_id : "",
                        street_fias_id: state.deliveryPreorderSelectedSuggestion !=null ? state.deliveryPreorderSelectedSuggestion.data.street_fias_id : ""
                    }
                ]
            })
        }

        fetch("https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address", options)
            .then(response => response.text())
            .then(result => {
                commit('SET_PREORDER_DELIVERY_SUGGESTIONS', JSON.parse( result))

            })

            .catch(error => {});
    },

    getPreorderDadataStreet ({commit, state}, street) {

        commit('SET_PREORDER_DELIVERY_SUGGESTIONS', null)

        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token 4c564b73ee2bc1fcf73b311e0875088605f754cd"
            },
            body: JSON.stringify({
                query: street,
                from_bound: { value: "street" },
                to_bound: { value: "street" },
                locations: [
                    {
                        country_iso_code: state.deliveryPreorderSelectedSuggestion !=null ? state.deliveryPreorderSelectedSuggestion.data.country_iso_code : "",
                        city_fias_id: state.deliveryPreorderSelectedSuggestion !=null ? state.deliveryPreorderSelectedSuggestion.data.city_fias_id : ""
                    }
                ]
            })
        }

        fetch("https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address", options)
            .then(response => response.text())
            .then(result => {
                commit('SET_PREORDER_DELIVERY_SUGGESTIONS', JSON.parse( result))

            })

            .catch(error => {});
    },

    getPreorderDadataCity ({commit, state}, city) {

        commit('SET_PREORDER_DELIVERY_SUGGESTIONS', null)

        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token 4c564b73ee2bc1fcf73b311e0875088605f754cd"
            },
            body: JSON.stringify({
                query: city,
                from_bound: { value: "city" },
                to_bound: { value: "city" },
                locations: [
                    {
                        country_iso_code: state.deliveryPreorderSelectedSuggestion !=null ? state.deliveryPreorderSelectedSuggestion.data.country_iso_code : "*"
                    }
                ]
            })
        }

        fetch("https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address", options)
            .then(response => response.text())
            .then(result => {
                commit('SET_PREORDER_DELIVERY_SUGGESTIONS', JSON.parse( result))

            })

            .catch(error => {});
    },
    getPreorderDadataCountry ({commit}, country) {

        commit('SET_PREORDER_DELIVERY_SUGGESTIONS', null)

        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token 4c564b73ee2bc1fcf73b311e0875088605f754cd"
            },
            body: JSON.stringify({
                query: country,
                from_bound: { value: "country" },
                to_bound: { value: "country" },
                locations: [
                    {
                        country_iso_code: "*"
                    }
                ]
            })
        }

        fetch("https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address", options)
            .then(response => response.text())
            .then(result => {
                commit('SET_PREORDER_DELIVERY_SUGGESTIONS', JSON.parse( result))

            })

            .catch(error => {});
    },

    selectPreorderFromSuggestion ({commit}, value) {
        commit('SET_PREORDER_SELECTED_DELIVERY_SUGGESTION', value)
        commit('SET_PREORDER_DELIVERY_SUGGESTIONS', null)
    },

    flushPreorderDeliverySuggestions({commit}) {
        commit('SET_PREORDER_DELIVERY_SUGGESTIONS', null)
    }
};

const mutations = {

    SET_PREORDER_DELIVERY_SUGGESTIONS(state, suggesions) {
        state.deliveryPreorderSuggestions = suggesions;
    },

    SET_PREORDER_DELIVERY_COUNTRY(state, country) {
        state.deliveryPreorder.country = country;
    },

    SET_PREORDER_DELIVERY_CITY(state, city) {
        state.deliveryPreorder.city = city;
    },

    SET_PREORDER_DELIVERY_STREET(state, street) {
        state.deliveryPreorder.street = street;
    },

    SET_PREORDER_DELIVERY_HOUSE(state, house) {
        state.deliveryPreorder.house = house;
    },
    SET_PREORDER_DELIVERY_BUILDING(state, building) {
        state.deliveryPreorder.building = building;
    },

    SET_PREORDER_DELIVERY_APARTMENT(state, apartment) {
        state.deliveryPreorder.apartment = apartment;
    },
    SET_PREORDER_DELIVERY_COMMENT(state, comment) {
        state.deliveryPreorder.comment = comment;
    },

    SET_PREORDER_DELIVERY_PICK_UP_POINT(state, pick_up_point) {
        state.deliveryPreorder.pick_up_point = pick_up_point;
    },
    SET_PREORDER_DELIVERY_PICK_UP_POINT_ID(state, pick_up_point_id) {
        state.deliveryPreorder.pick_up_point_id = pick_up_point_id;
    },

    SET_PREORDER_DELIVERY_POSTAL_CODE(state, postal_code) {
        state.deliveryPreorder.postal_code = postal_code;
    },

    SET_PREORDER_DELIVERY_SELECTED_DATE(state, dateDelivery) {
        state.deliveryPreorder.date = dateDelivery;
    },
    SET_PREORDER_DELIVERY_SELECTED_TIME_INTERVAL_ID(state, deliveryTimeIntervalId) {
        state.deliveryPreorder.time_interval_id = deliveryTimeIntervalId;
    },

    SET_PREORDER_DELIVERY_SELECTED_TIME_INTERVAL(state, deliveryTimeInterval) {
        state.deliveryPreorder.time_interval = deliveryTimeInterval;
    },

    SET_PREORDER_DELIVERY_REGION_DETAIL(state, deliveryRegionDetail) {
        state.deliveryPreorderRegionDetail = deliveryRegionDetail ;
    },
    SET_PREORDER_DELIVERY_TYPE_COURIER_OR_PICKUP(state, deliveryTypeCourierOrPickup) {
        state.deliveryPreorderTypeCourierOrPickup = deliveryTypeCourierOrPickup ;
    },

    setPreorderDeliveryRegionDetails(state, deliveryRegionDetails) {
        state.deliveryPreorderRegionDetails = deliveryRegionDetails ;
    },

    SET_PREORDER_INITIAL_DELIVERY_REGION_DETAILS(state, deliveryRegionDetails) {
        state.initialDeliveryPreorderRegionDetails = deliveryRegionDetails ;
    },

    setDeliveryPreorderRegionDetailsStatus(state, status) {
        state.deliveryPreorderRegionDetailsStatus = status ;
    },

    SET_PREORDER_SELECTED_DELIVERY_SUGGESTION (state, suggestion) {
        state.deliveryPreorderSelectedSuggestion = suggestion;
    },
};

export default {
    state, getters, actions, mutations
}
