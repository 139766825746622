<template>
    <div class="flex flex-wrap w-full justify-center items-center">
        <div class="flex flex-wrap max-w-xl">
            <div class="p-2 text-2xl text-gray-light "><h1>Регистрация</h1></div>
            <div class="p-2 w-full">
                <label class="w-full" for="name">Имя</label>
                <input class="appearance-none rounded-none w-full h-10 border px-2 focus:outline-none focus:border-black tracking-normal"
                       type="text"
                       v-model="form.name"
                       :class="[submitted && v$.form.name.$error ? 'border-red-400' : 'border-gray-lighter'] ">
                <div v-if="submitted && !v$.form.name.required" class="text-red-400">Заполните имя</div>
            </div>
            <div class="p-2 w-full">
                <label class="w-full" for="surname">Фамилия</label>
                <input class="appearance-none rounded-none w-full h-10 border  px-2 focus:outline-none focus:border-black tracking-normal"
                       type="text"
                       v-model="form.surname"
                       :class="[ submitted && v$.form.surname.$error ? 'border-red-400' : 'border-gray-lighter'] ">
                <div v-if="submitted && !v$.form.surname.required" class="text-red-400">Заполните фамилию</div>
            </div>

            <div class="p-2 w-full">
                <label for="email">E-mail</label>
                <input class="appearance-none rounded-none w-full h-10 border px-2 focus:outline-none focus:border-black tracking-normal"
                       type="email"
                       v-model="form.email"
                       :class="[submitted && v$.form.email.$error ? 'border-red-400' : 'border-gray-lighter']"
                       @click="this.resetRegisterEmailError">
                <div v-if="submitted && v$.form.email.$error || registerEmailError" class="text-red-400">
                    <span v-if="!v$.form.email.required">Заполните email</span>
                    <span v-if="!v$.form.email.email">Неверный email</span>
                    <span v-if="registerEmailError">{{ registerEmailError[0] }}</span>
                </div>
            </div>

            <div class="p-2 w-full">
                <label for="phone">Телефон</label>

                <vue-tel-input class=""
                               v-model="form.phonenumber"
                               @validate="telValidate"
                               :inputOptions="{
                                           placeholder: '',
                                       }"
                               :preferredCountries="['ru', 'by', 'kz']"
                               :defaultCountry = "'ru'"
                               :styleClasses= styleClasses
                               :mode = "'international'"
                               :required = true
                               >
                >


                </vue-tel-input>
                <div v-if="submitted && v$.form.phone.$error || registerPhoneError" class="text-red-400">
                    <span v-if="!v$.form.phone.required">Заполните телефон</span>
                  <span v-if="registerPhoneError">{{ registerPhoneError[0] }}</span>
                </div>
            </div>
            <div class="p-2 w-full">
                <label for="password">Пароль</label>
                <input class="appearance-none rounded-none w-full h-10 border  px-2 focus:outline-none focus:border-black tracking-normal"
                       type="password"
                       v-model="form.password"
                       name="password"
                       :class="[ submitted && v$.form.password.$error ? 'border-red-400' : 'border-gray-lighter'] " >
                <div v-if="submitted && v$.form.password.$error" class="text-red-400">
                    <span v-if="!v$.form.password.required">Пароль обязателен</span>
                    <span v-if="!v$.form.password.minLength">Пароль должен содержать от 6 символов</span>
                </div>
            </div>

            <div class="p-2 w-full">
                <label for="confirm_password">Подтверждение пароля</label>
                <input class="appearance-none rounded-none w-full h-10 border px-2 focus:outline-none focus:border-black tracking-normal"
                       type="password"
                       v-model="form.password_confirmation"
                       name="password_confirmation"
                       :class="[submitted && v$.form.password_confirmation.$error ? 'border-red-400' : 'border-gray-lighter'] ">

                <div v-if="submitted && v$.form.password_confirmation.$error" class="text-red-400">
                    <span v-if="!v$.form.password_confirmation.required">Подтверждение пароля обязательно</span>
                    <span v-else-if="!v$.form.password_confirmation.sameAsPassword">Пароли должны совпадать</span>
                </div>
            </div>
            <div class="p-2 w-full mt-4" :disabled="v$.$invalid">
                <button @click.prevent="saveForm" type="submit" class="w-full py-3 px-5 bg-black text-white text-sm tracking-wider uppercase focus:outline-none">Зарегистрироваться</button>
                <div class="mt-2 text-xs tracking-normal text-gray-light">
                    Нажимая на кнопку «Зарегистрироваться», я соглашаюсь на обработку моих персональных данных и ознакомлен(а) с условиями <router-link class="underline" to="/info/privacy-policy" target="_blank">конфиденциальности</router-link>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import { required, minLength, email, sameAs } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
    data(){
        return{
            form:{
                name: '',
                surname: '',
                email: '',
                phone: '',
                password:'',
                password_confirmation:'',
                phonenumber: null,
            },
            submitted: false,
        }
    },
    setup () { return { v$: useVuelidate() } },
    validations: {
        form: {
            name: { required },
            surname: { required },
            email: { required, email },
            phonenumber: { required },
            phone: { required },
            password: { required, minLength: minLength(6) },
            password_confirmation: { required, sameAsPassword: sameAs('password') }
        },

    },
    computed: {
        ...mapGetters({
            errors: 'registerErrors',
            status: 'registerStatus',
            user: 'user',
            registerPhoneError: 'registerPhoneError',
            registerEmailError: 'registerEmailError',
        }),
        styleClasses(){
            if(this.submitted && this.v$.form.phone.$error) {
                return 'vue-tel-input-error appearance-none rounded-none w-full h-10 px-2 tracking-normal'
            }
            else{
                return 'appearance-none rounded-none w-full h-10 px-2 tracking-normal'
            }
        },
    },
    methods:{
        ...mapActions({
            register: 'register',
            resetRegisterPhoneError: 'resetRegisterPhoneError',
            resetRegisterEmailError: 'resetRegisterEmailError'
        }),

        async saveForm(){
            this.submitted = true;

            await this.v$.$touch();

            if(this.v$.$invalid)
            {
                return
            }

            await this.register(this.form)

            .then(res => {
                this.$gtm.trackEvent({
                    event: "register",
                    clientId: this.user.id,
                    clientEmail: this.user.email,
                    clientPhone: this.user.phone,
                    noninteraction: false,
                });
                this.$gtm.trackEvent({
                    event: "custom_event",
                    event_category: 'forms',
                    event_action: 'sent_successfully',
                    event_label: 'registration',
                });
            })

            this.$router.replace({ name: 'Account' })
        },
        telValidate(telnumber) {
            this.resetRegisterPhoneError()
            if (telnumber.valid) {
                this.form.phone = telnumber.number
            } else {
                this.form.phone = ''
            }
        },

    }
}
</script>


<style lang="scss">

.vue-tel-input {
    border-radius: 0px !important;
    border: 1px solid #e5e7eb !important;
    box-shadow: none !important;

}
.vue-tel-input:focus {
    border: 1px solid #e6ceb6 !important;
}
.vue-tel-input-error {
    border: 1px solid #F87171 !important;
}
</style>
