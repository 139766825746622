<template>
  <store></store>
</template>

<script>
import Store from './apps/Store.vue'

export default {
  name: 'App',
  components: {
    Store
  }
}
</script>
