<template>
    <div class="">
        <div class="p-6 sm:p-12 w-full h-full lg:w-256 lg:mx-auto text-gray-main text-base font-normal">
          <div v-if="infoStatus=='success'">
            <h1 v-if="info.data.attributes.show_as_title_in_block" class="uppercase">{{info.data.attributes.title}}</h1>
            <div v-html="info.data.attributes.html"></div>
          </div>
            <Loading v-else></Loading>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import Loading from "../components/Loading";

export default {
    name: "InfoBlock",

    components: {
        Loading
    },

    mounted() {
        this.$store.dispatch('fetchInfo', this.$route.params.info);
    },
    computed: {
        ...mapGetters({
            info: 'info',
            infoStatus: 'infoStatus',

        }),
    },
    watch: {
        $route() {
            this.$store.dispatch('fetchInfo', this.$route.params.info)
        }
    }
}
</script>

<style scoped>

</style>
