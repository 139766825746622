<template>
<div class="flex flex-col min-h-screen">
    <AnnouncementBar />
    <Nav />
    <div class="flex-1">
        <Popup
            v-if="isSubscribePopupVisible && newsSubscriptionPopup.data.attributes.active == true"
            @closePopup="closePopup">
            <div class="w-full h-full">
                <div class="-mt-3  text-2xl font-gothic  text-gray-main mr-7">
                    {{newsSubscriptionPopup.data.attributes.title}}
                </div>
                <div class="text-normal font-gothic  text-gray-main">
                    {{newsSubscriptionPopup.data.attributes.main_text}}
                </div>

                <div class="text-normal text-sm font-gothic mt-3 text-gray-main">
                    {{newsSubscriptionPopup.data.attributes.text_before_button}}
                </div>

                <div>
                    <input v-model="email" id="email" type="email" class="h-10 w-full pl-4 mt-1 text-body-18 font-normal font-gothic  border-2 outline-none
                                                  focus:box-border focus:border focus:border-action-brown-dark-digital focus:text-black  hover:border-brown-light
                                                  focus:border-brown-dark appearance-none rounded-none" placeholder="Email" required>
                </div>
                <div class="text-xs font-gothic text-gray-main" v-if="subscriptionPopupStatus=='loading'">
                    Загрузка..
                </div>
                <div class="text-xs font-gothic text-green-500" v-if="subscriptionPopupStatus=='success'">
                    Успех! Не забудьте перейти по ссылке в email, для подтверждения подписки.
                </div>
                <div class="text-xs font-gothic text-red-500" v-if="subscriptionPopupStatus=='error'">
                    Ошибка!
                </div>

                <button class="w-full mt-3  py-3 bg-brown-light text-white text-xl font-normal focus:outline-none" @click="subscribe(email)">
                    Подписаться
                </button>
                <div class="text-xs">
                    Нажимая на кнопку «Подписаться», я соглашаюсь на обработку моих персональных данных и ознакомлен(а) с  <router-link class="underline" to="/info/privacy-policy" target="_blank">условиями конфиденциальности</router-link>
                </div>
            </div>
        </Popup>
        <router-view></router-view>
    </div>
    <div>
        <Popup   v-if="isSubscribeSuccessPopupVisible"
                 @closePopup="closePopup">
            <div class="w-full h-full">
                <div class="text-xl text-center font-gothic text-gray-main ">
                    Успех!
                </div>
                <div class="text-normal font-gothic text-center text-gray-main">
                    Не забудьте подтвердить email в почте
                </div>
                <button class="w-full mt-3  py-3 bg-brown-light text-white text-xl font-normal focus:outline-none" @click="closePopup">
                    Хорошо
                </button>
            </div>
        </Popup>
    </div>
    <AlertMessage />
    <Footer />
    <CookiesAcceptPopup />
</div>
</template>

<script>
    import Nav from "../components/Nav";
    import Footer from "../components/Footer";
    import Popup from "../components/popup/Popup"
    import AnnouncementBar from "../components/AnnouncementBar"
    import {mapActions, mapGetters} from "vuex";
    import AlertMessage from '../components/AlertMessage.vue'
    import CookiesAcceptPopup from "../components/CookiesAcceptPopup.vue";

    export default {
        name: "Store",
        data: () => ({
            email: '',
            isSubscribePopupVisible: false,
            isSubscribeSuccessPopupVisible: false,
        }),
        components: {
            CookiesAcceptPopup,
            AlertMessage,
            Nav, Footer, Popup, AnnouncementBar
        },
        methods: {
            closePopup(){
                this.isSubscribePopupVisible = false
                this.isSubscribeSuccessPopupVisible = false
                this.closeSubscriptionPopup()
            },
            subscribe(email) {
                this.subscribeFromPopup({
                    'email': email,
                    'source': 'popup',
                })
                this.$gtm.trackEvent({
                    event: "subscribeFromPopup",
                    clientEmail: email,
                });

                this.$gtm.trackEvent({
                    event: "custom_event",
                    event_category: "forms",
                    event_action: "sent_successfully",
                    event_label: 'popup_first_to_know',
                });


            },
            ...mapActions({
                subscribeFromPopup: 'subscribeFromPopup',
                closeSubscriptionPopup: 'closeSubscriptionPopup',
                fetchNewsSubscriptionPopup:'fetchNewsSubscriptionPopup',
                getCart:'getCart',
                getPreorderCart:'getPreorderCart',
            })

        },
        computed: {
            ...mapGetters({
                subscriptionPopupStatus: 'subscriptionPopupStatus',
                newsSubscriptionPopup: 'newsSubscriptionPopup',
                newsSubscriptionPopupStatus: 'newsSubscriptionPopupStatus',
                subscriptionPopupDate: 'subscriptionPopupDate',
            }),
        },

        mounted() {
            let now = new Date()
            let from = new Date(this.subscriptionPopupDate)
            let dateDifference = now - from;

            if((this.subscriptionPopupStatus == 'closed' && dateDifference > 86400000) || this.subscriptionPopupStatus == null || this.subscriptionPopupStatus == 'error')  {
                setTimeout(() => this.isSubscribePopupVisible = true, 5000);
            }
            this.$store.dispatch('fetchNewsSubscriptionPopup');
            this.getCart()
            this.getPreorderCart()

        },
        watch: {
            subscriptionPopupStatus: function (val) {
                if(this.subscriptionPopupStatus=='success'){
                    this.isSubscribePopupVisible = false
                    this.isSubscribeSuccessPopupVisible = true
                }
            },
        }
    }
</script>

<style scoped>

</style>
