<template>
    <div>
        <form
            class="flex flex-col gap-6 items-center mt-8"
            @submit.prevent="sendPhone"
            v-if="!isShowInputCode"
        >
            <div class="text-19 text-center font-normal">
                Сменить номер телефона
            </div>
            <div class="max-w-md w-full">
                <vue-tel-input
                    v-model="phone"
                    :inputOptions="{ placeholder: 'Номер телефона' }"
                    :preferredCountries="['ru', 'by', 'kz']"
                    :defaultCountry="'ru'"
                    :mode="'international'"
                    :required="true"
                    :styleClasses="styleClasses"
                >
                </vue-tel-input>
                <p
                    v-if="v$.phone.$invalid && v$.phone.$dirty"
                    class="text-12 text-red-light-2 mt-2"
                >
                    Пожалуйста введите корректный номер телефона
                </p>
                <p v-if="errorMessage"
                class="text-12 text-red-light-2 mt-2">
                    {{ errorMessage }}
                </p>
            </div>
            <button
                type="submit"
                class="max-w-max w-full pl-8 pr-8 h-10 text-white bg-blue-500 font-normal"
            >
                Изменить номер
            </button>
        </form>
        <form class="flex flex-col gap-6 items-center mt-8" v-else>
            <InputCode
                v-model="code"
                @endTime="(val) => ((isEndTimeCode = val), (code = ''))"
                @input="(val) => {changeCode(val)}"
                :error-message="errorMessage"
                :restart-timer="restartTimer"
            />
            <button
                type="button"
                class="max-w-max w-full pl-8 pr-8 h-10 text-white bg-blue-500 font-normal"
                @click="isEndTimeCode ? getCode() : sendCode()"
            >
                {{ isEndTimeCode ? "Получить код" : "Отправить код" }}
            </button>
        </form>
    </div>
</template>
<script>
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import InputCode from "../components/InputCode.vue";
import { mapActions } from "vuex";
import axios from '@/api';

export default {
    name: "ChangePhone",
    components: {InputCode},
    data: () => ({
        phone: null,
        code: "",
        errorMessage: "",
        isShowInputCode: false,
        isEndTimeCode: false,
        restartTimer: false,
    }),
    setup () { return { v$: useVuelidate() } },
    validations() {
        return {
            phone: {
                required,
                regex: function (value) {
                    this.phone = value.replace(/^8/gim, "+7");
                    const resultValidate = value
                        ? /(^\+7[0-7,9]\d{9}$)|(^\+([0-6]|[8-9])\d{6,14}$)/i.test(
                            value
                                .replace(/^8/gim, "+7")
                                .replaceAll(/\s*/gim, ""),
                        )
                        : true;
                    if (!resultValidate) this.v$.$touch();
                    return resultValidate;
                },
            },
        };
    },
    computed: {
        styleClasses() {
            if (this.v$.phone.$error) {
                return (
                    "vue-tel-input-error appearance-none rounded-none w-full h-10 pl-4 mt-3 text-body-18 font-normal " +
                    " text-11 text-gray-light  w-full h-9 focus:border px-2\n" +
                    "                          focus:border-red lg:text-13"
                );
            } else {
                return (
                    "appearance-none rounded-none w-full h-10 pl-4 mt-3 text-body-18 font-normal" +
                    " text-11 text-gray-light w-full h-9 focus:border px-2\n" +
                    "                          focus:border-black lg:text-13"
                );
            }
        },
    },
    methods: {
        ...mapActions({
            deactivateUserPhone: 'deactivateUserPhone'
        }),
        changeCode(code) {
            this.code = code.target.value
        },
        async sendPhone() {
            await this.deactivateUserPhone(this.$route.query?.userId)
            const newPhone = this.phone.replaceAll(/\s*/gim, "");
            if (newPhone.length === 12) {
                await axios
                    .post("/api/verification/phone/init", {
                        phone: newPhone,
                        ...(this.$route.query?.userId && {
                            userId: +this.$route.query.userId,
                        }),
                    })
                    .then(() => {
                        this.isShowInputCode = true;
                        this.errorMessage = "";
                    })
                    .catch((e) => {
                        this.isShowInputCode = false;
                        console.error(e);
                        this.v$.$touch();
                        this.errorMessage = "Данный телефон уже принадлежит другом аакаунту, попробуйте ввести другой номер телефона.";
                    });
            } else {
                this.v$.$touch();
            }
        },
        async sendCode() {
            if (this.code.length === 4) {
                await axios
                    .post("/api/verification/phone/verify", {
                        phone: this.phone.replaceAll(/\s*/gim, ""),
                        code: this.code.toString(),
                        ...(this.$route.query?.userId && {
                            userId: +this.$route.query.userId,
                        }),
                    })
                    .then(() => {
                        this.errorMessage = "";
                        this.isShowInputCode = false;
                        this.$router.push({name: "MainPage"});
                    })
                    .catch((e) => {
                        console.error(e);
                        this.errorMessage = "Вы ввели не верный код";
                    });
            } else {
                this.errorMessage = "Длина кода должна составлять 4 символа";
            }
        },
        async getCode() {
            await axios
                .post("/api/verification/phone/init", {
                    phone: this.phone.replaceAll(/\s*/gim, ""),
                    ...(this.$route.query?.userId && {
                        userId: +this.$route.query.userId,
                    }),
                })
                .then(() => {
                    this.isEndTimeCode = false;
                    this.restartTimer = true;
                    this.errorMessage = "";
                })
                .catch((e) => {
                    this.isShowInputCode = true;
                    this.code = "";
                    console.error(e);
                    this.restartTimer = false;
                    this.errorMessage = "Не удалось получить код";
                });
        },
    },
    mounted() {
        if (!this.$route.query?.userId) this.$router.push({name: "MainPage"});
    },
};
</script>
<style scoped></style>
