import axios from "@/api";

const state = {
    footer: null,
    footerStatus: 'loading',
    footerSubscription:null,
    footerSubscriptionStatus:'loading',
};

const getters = {
    footer: state => {
        return state.footer;
    },
    footerStatus: state => {
        return state.footerStatus;
    },
    footerSubscription: state => {
        return state.footerSubscription;
    },
    footerSubscriptionStatus: state => {
        return state.footerSubscriptionStatus;
    },
};

const actions = {
    fetchFooter({commit, dispatch}) {
        commit('setFooterStatus', 'loading')
        axios.get('/api/footer/')
            .then(res => {
                commit('setFooter', res.data)
                commit('setFooterStatus', 'success')
            })
            .catch(error => {
                commit('setFooterStatus', 'error')
            });
    },

    fetchFooterSubscription({commit,dispatch},footerSubscriptionId){
        commit('setFooterSubscriptionStatus', 'loading')
        axios.get('/api/footer-subscription/' + footerSubscriptionId)
            .then(res=>{
                commit('setFooterSubscription',res.data)
                commit('setFooterSubscriptionStatus','success')
            })
            .catch(error => {
                commit('setFooterSubscriptionStatus', 'error')
            });
    }
};
const mutations = {
    setFooter(state, footer) {
        state.footer = footer;
    },
    setFooterStatus(state, status) {
        state.footerStatus = status;
    },
    setFooterSubscription(state, footerSubscription) {
        state.footerSubscription = footerSubscription;
    },
    setFooterSubscriptionStatus(state, status) {
        state.footerSubscriptionStatus = status;
    },

};

export default {
    state, getters, actions, mutations,
}
