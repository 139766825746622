import axios from '@/api';

const state = {
    navigation: null,
    navigationStatus: 'loading',
};

const getters = {
    navigation: state => {
        return state.navigation;
    },
    navigationStatus: state => {
        return state.navigationStatus;
    },
};

const actions = {
    fetchNavigation({commit, dispatch}) {

        commit('setNavigationStatus', 'loading')
        axios.get('/api/navigation/')
            .then(res => {
                commit('setNavigation', res.data)
                commit('setNavigationStatus', 'success')
            })
            .catch(error => {
                    commit('setNavigationStatus', 'error')
            });
    },

};

const mutations = {
    setNavigation(state, navigation) {
        state.navigation = navigation;
    },
    setNavigationStatus(state, status) {
        state.navigationStatus = status;
    },

};

export default {
    state, getters, actions, mutations,
}
