<template>
    <div>
        <div class="flex-none">
            <div v-if="title !== null">
                <div class="mt-12 mb-4 w-full text-black text-lg font-medium tracking-widest text-left ml-3
                lg:text-2xl lg:mt-15 lg:mb-8">
                    {{ title }}
                </div>
            </div>
            <div class="relative swiper-container w-full mx-auto" ref="categorySwiper">
                <div class="swiper-wrapper">
                    <ProductBlock
                        class="swiper-slide px-0.5 items-center justify-center"
                        v-for="(product, index) in productsForBlock"
                        :key=product.id
                        :product=product
                        :gtm-position=index
                        :gtm-list=title />
                </div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </div>
            <router-link v-if="button_text != null && link !=null"
                         class="mt-7 mb-15 mx:auto flex items-center justify-center lg:mt-15 lg:mb-15" :to="link">
                <button class="py-3 px-4 text-xs border-2 border-gray-main font-medium tracking-widest uppercase
                lg:py-4 lg:px-7 lg:text-sm">
                    {{ button_text }}
                </button>
            </router-link>
        </div>
    </div>

</template>

<script>

import {Swiper, Navigation} from 'swiper';
import 'swiper/swiper-bundle.css';
import ProductBlock from "./ProductBlock";

Swiper.use([Navigation]);

export default {
    name: "SwipableCategoryBlock",
    components: {ProductBlock},
    props: {
        block: null,
        productsForBlock: null,
        title: null,
        button_text: null,
        link: null,
    },
    data: () => ({
        swiper: null,
    }),
    methods: {
        sendListToGTM() {
            let impressions = []
            this.$gtm.trackEvent({
                event: 'flushVariables',
                ecommerce: null,
            });
            for (let i = 0; i < this.productsForBlock.length; i++) {
                impressions.push({
                    id: this.productsForBlock[i].data.attributes.article_number,
                    name: this.productsForBlock[i].data.attributes.title,
                    price: this.productsForBlock[i].data.attributes.discount != null
                        ? this.productsForBlock[i].data.attributes.cost - this.productsForBlock[i].data.attributes.discount.data.attributes.amount
                        : this.productsForBlock[i].data.attributes.cost,
                    category: this.productsForBlock[i].data.attributes.category.data.attributes.title,
                    position: i,
                    list: this.title
                })
            }
            this.$gtm.trackEvent({
                event: 'view_item_list',
                ecommerce: {
                    impressions: impressions
                },
            });
        }
    },
    mounted() {
        this.swiper = new Swiper(this.$refs.categorySwiper, this.swiperSettings);
        if (this.block != null && this.productsForBlock != null) {
            this.sendListToGTM();
        }
    },
    watch: {
        category() {
            this.sendListToGTM();
        }
    },
    computed: {
        swiperSettings() {
            return {
                slidesPerView: 2,
                grabCursor: true,
                // Responsive breakpoints
                breakpoints: {
                    // when window width is >= 1280px
                    1280: {
                        slidesPerView: 3,
                    }
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            }
        },

    },
}
</script>

<style lang="scss" scoped>
:root {
    /*
    вытащила все из документации по css в Swiper
    без --swiper-navigation-size ширину и высоту стрелок нельзя регулировать
    */
}

.swiper-button-prev {
    --swiper-navigation-size: 18px;

    position: absolute;
    top: 50%;
    height: var(--swiper-navigation-size);
    z-index: 30;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #363636 !important;
}

.swiper-button-next {
    color: #363636 !important;
    --swiper-navigation-size: 18px;

    position: absolute;
    top: 50%;
    height: var(--swiper-navigation-size);
    z-index: 30;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
