import axios from '@/api';
import router from "../../router";


const state = {
    pressBlockImages: null,
    pressBlockImagesStatus: 'loading',
};

const getters = {
    pressBlockImages: state => {
        return state.pressBlockImages;
    },
    pressBlockImagesStatus: state => {
        return state.pressBlockImagesStatus;
    },
};

const actions = {
    fetchPressBlockImages({commit, dispatch}) {
        commit('setPressBlockImagesStatus', 'loading')
        axios.get('/api/press-block-image/')
            .then(res => {
                commit('setPressBlockImages', res.data)
                commit('setPressBlockImagesStatus', 'success')
            })
            .catch(error => {

                commit('setPressBlockImagesStatus', 'error')

            });

    },

};

const mutations = {

    setPressBlockImages(state, pressBlockImages) {
        state.pressBlockImages = pressBlockImages;
    },
    setPressBlockImagesStatus(state, status) {
        state.pressBlockImagesStatus = status;
    }
};

export default {
    state, getters, actions, mutations,
}
