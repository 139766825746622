<template>
<div class="">
    <div class="flex flex-col items-baseline">
        <div class="w-16 mr-6 text-11 lg:w-17 lg:text-13">
            Промокод
        </div>
        <div class="w-full">
            <div v-if="this.cart.data.relationships.cartPromoCode"
                 class="mt-5">
                <div class="flex items-center w-full" >
                    <div class="w-full text-11 text-gray-light lg:w-2/3 lg:text-13">
                        <input readonly="readonly" class="w-full h-6 px-2 appearance-none rounded-none text-11 text-gray-light border border-gray-lighter focus:outline-none focus:border-black lg:h-8 lg:text-13"
                               v-model="this.cart.data.relationships.cartPromoCode.data.attributes.code">
                    </div>
                    <div class="top-0">
                        <div @click="deletePromoCode" class="w-20 pl-7 lg:w-25 focus:outline-none">
                            <button class="lg:hidden">
                                <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.999977 8.07107L8.07104 1" stroke="black" stroke-linecap="square"/>
                                    <path d="M0.999978 1.00022L8.07104 8.07129" stroke="black" stroke-linecap="square"/>
                                </svg>
                            </button>
                            <button class="hidden lg:block">
                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 10L10 1" stroke="black" stroke-linecap="square"/>
                                    <path d="M1 1.00024L10 10.0002" stroke="black" stroke-linecap="square"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <span v-if="this.cart.data.relationships.cartPromoCode.data.attributes.errorMessage"
                      class="flex items-center mt-2 text-11 text-red-500 lg:text-13">
                            {{ this.cart.data.relationships.cartPromoCode.data.attributes.errorMessage }}
                </span>
                <span v-if="this.cart.data.relationships.cartPromoCode.data.attributes.errorMessage === null"
                      class="flex items-center mt-2 text-11 text-green-light lg:text-13">
                            Промокод успешно применен
                </span>
            </div>
            <div v-else class="w-full">
                <div class="flex w-full">
                    <div class="flex items-center w-full h-10 lg:w-2/3">
                        <input class="w-full h-6 px-2 appearance-none rounded-none text-11 text-gray-light border border-gray-lighter focus:outline-none focus:border-black lg:h-8 lg:text-13"
                               v-model="promoCodeModel"
                               type="text"
                               name="promoCode"
                        >
                    </div>
                    <div class="flex items-center h-10 pl-2">
                        <button class="w-25 lg:w-30 h-6 py-1 px-2 bg-transparent text-black custom-border text-11 r focus:outline-none lg:h-8 lg:text-13" @click="addPromoCode">
                            Применить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "CartPromoCodeBlock",
    data: () =>({
        promoCodeModel:'',
    }),
    props: {
        cart:null,
        isPromoCodeBlockFromCartCheckout:null,
    },
    methods:{
        ...mapActions({
            promoCodeVuex:'promoCode',
            deletePromoCodeInVuex:'deletePromoCode',
            }
        ),
        addPromoCode() {
            this.promoCodeVuex(this.promoCodeModel)
        },
        deletePromoCode() {
            this.deletePromoCodeInVuex(this.promoCodeModel)
        }
    },
}
</script>

<style lang="css">
.custom-border {
    border: 1px solid black;
}
</style>
