<template>
    <div>
        <div class="w-21 h-10  flex items-end justify-between text-11 text-gray-light lg:text-16">
            <button class="w-8 h-full hover:bg-black hover:text-white focus:outline-none"
                    @click="decrementCartOrderLineItem(item, index)">
                -
            </button>
            <div class="w-9 h-full flex items-center justify-center">{{item.attributes.userQuantity}}</div>
            <button class="w-8 h-full hover:bg-black hover:text-white focus:outline-none"
                    @click="incrementCartOrderLineItem(item, index)">
                +
            </button>
        </div>
        <Popup v-if="quantityIsNotAllowedPopup"
               @closePopup="closePopup">
            <div class="mt-4 w-full justify-center items-center flex-none text-gray-main">
                <div class="flex-none justify-items-center">
                    <div class="mt-4 w-full text-center text-xl justify-center flex-none">
                        Количество товаров, которое вы хотели бы добавить, отсутствует на складе.
                        Мы добавим вам максимум, который можем
                    </div>
                </div>
            </div>
        </Popup>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import Popup from "../popup/Popup.vue";

export default {
    name: "CartIncrementDecrementQuantityOfOrderLine",
    components: {Popup},
    data: () =>({
        quantityIsNotAllowedPopup: false,
    }),
    props:{
        item:null,
        index:null,
        cartItemsForMindbox:null,
    },
    methods: {
        ...mapActions({
            incrementCartOrderLineItemVuex: 'incrementCartOrderLineItem',
            decrementCartOrderLineItemVuex: 'decrementCartOrderLineItem'
        }),
        async decrementCartOrderLineItem(item){
            if (item.attributes.userQuantity > 1) {
               await this.decrementCartOrderLineItemVuex(item)
                   .then( () => {
                       this.$gtm.trackEvent({
                           event: 'flushVariables',
                           ecommerce: null,
                       });
                       this.$gtm.trackEvent({
                           event: "remove_from_cart",
                           ecommerce: {
                               remove: {
                                   actionField: {
                                       list: 'Корзина'
                                   },
                                   products: [{
                                       name: item.relationships.product.data.attributes.title,
                                       id: item.relationships.product.data.attributes.article_number,
                                       price: item.attributes.price,
                                       category: item.relationships.product.data.attributes.category.data.attributes.title,
                                       quantity: item.attributes.userQuantity,
                                       variant: item.relationships.product.data.attributes.article_number + "" + item.attributes.sizeId,
                                   }]
                               }

                           }
                       });
                   })
            }
        },
        async incrementCartOrderLineItem(item){
            if (item.attributes.userQuantity === item.attributes.maxQuantity) {
                this.quantityIsNotAllowedPopup = true
            } else{
                await this.incrementCartOrderLineItemVuex(item)
                    .then(() => {
                        this.$gtm.trackEvent({
                            event: 'flushVariables',
                            ecommerce: null,
                        });
                        this.$gtm.trackEvent({
                            event: "add_to_cart",
                            ecommerce: {
                                add: {
                                    actionField: {
                                        list: 'Корзина'
                                    },
                                    products: [{
                                        name: item.relationships.product.data.attributes.title,
                                        id: item.relationships.product.data.attributes.article_number,
                                        price: item.attributes.price,
                                        category: item.relationships.product.data.attributes.category.data.attributes.title,
                                        quantity: 1,
                                        variant: item.relationships.product.data.attributes.article_number + "" + item.attributes.sizeId,
                                    }]
                                }

                            }
                        });
                    })
            }
        },
        closePopup(){
            this.quantityIsNotAllowedPopup = false
        },
    },
  mounted() {
    this.$store.dispatch('checkAvailableQuantityOfProductsInOrderLine', this.item);
  }
}
</script>

<style scoped>

</style>
