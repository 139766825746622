import axios from '@/api';
import router from "../../router";


const state = {
    pageBlocks: null,
    pageBlocksStatus: 'loading',
    pressBlock: null,
    pressBlocksStatus: 'loading',
};

const getters = {
    pageBlocks: state => {
        return state.pageBlocks;
    },
    pageBlocksStatus: state => {
        return state.pageBlocksStatus;
    },
    pressBlock: state => {
        return state.pressBlock;
    },
    pressBlockStatus: state => {
        return state.pressBlockStatus;
    },
};

const actions = {
    fetchPageBlocks({commit, dispatch}) {
        commit('setPageBlocksStatus', 'loading')
        axios.get('/api/page-blocks/')
            .then(res => {
                commit('setPageBlocks', res.data)
                commit('setPageBlocksStatus', 'success')
            })
            .catch(error => {

                commit('setPageBlocksStatus', 'error')

            });

    },

    fetchPressBlock({commit, dispatch}, pressType) {
        commit('setPressBlockStatus', 'loading')
        axios.get('/api/press-block/' + pressType)
            .then(res=> {
                commit ('setPressBlock', res.data)
                commit ('setPressBlockStatus', 'success')
            })
            .catch(error => {
                commit ('setPressBlockStatus', 'error')
            });
    }

};

const mutations = {

    setPageBlocks(state, pageBlocks) {
        state.pageBlocks = pageBlocks;
    },
    setPageBlocksStatus(state, status) {
        state.pageBlocksStatus = status;
    },
    setPressBlock(state, pressBlock) {
        state.pressBlock = pressBlock;
    },
    setPressBlockStatus(state, status) {
        state.pressBlockStatus = status;
    }
};

export default {
    state, getters, actions, mutations,
}
