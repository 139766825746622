import axios from '@/api';

const state = {
    feedback: null,
    feedbackStatus: 'not-sent',
    feedbackScore: null,
    feedbackText: '',
    feedbackMindboxUserId: null,
};

const getters = {
    feedback: state => {
        return state.feedback;
    },
    feedbackStatus: state => {
        return state.feedbackStatus;
    },
    feedbackScore: state => {
        return state.feedbackScore;
    },
    feedbackText: state => {
        return state.feedbackText;
    },
    feedbackMindboxUserId: state => {
        return state.feedbackMindboxUserId;
    },
};

const actions = {
    setFeedback({commit, state}, feedback){
        commit('SET_FEEDBACK', feedback);
    },
    setFeedbackStatus({commit, state}, feedbackStatus){
        commit('SET_FEEDBACK_STATUS', feedbackStatus);
    },

    setFeedbackScore({commit, state}, score){
        commit('SET_FEEDBACK_SCORE', score);
    },

    setFeedbackText({commit, state}, text){
        commit('SET_FEEDBACK_TEXT', text);
    },

    setFeedbackMindboxUserId({commit, state}, userId){
        commit('SET_FEEDBACK_MINDBOX_USER_ID', userId);
    },

    leaveNpsFeedbackFirstStep({commit, state}, userId) {
        commit('SET_FEEDBACK_STATUS', 'loading');
        axios.post('/api/feedback/nps', {
            data: {
                type: 'feedback-nps',
                attributes: {
                    mindbox_user_id: state.feedbackMindboxUserId,
                    score: state.feedbackScore,
                    text: state.feedbackText,
                },
                relationships: {
                    user: {
                        data: {
                            user_id: userId,
                        }
                    }
                }
            }
        })
        .then(res => {
            commit('SET_FEEDBACK', res.data)
            commit('SET_FEEDBACK_STATUS', 'success-first-step')
        })
        .catch(error => {
            commit('SET_FEEDBACK_STATUS', 'error')
        });
    },

    leaveNpsFeedbackSecondStep({commit, state}, userId) {
        commit('SET_FEEDBACK_STATUS', 'loading');
        axios.put('/api/feedback/nps/' + state.feedback.data.feedback_id, {
            data: {
                type: 'feedback-nps',
                attributes: {
                    text: state.feedbackText
                },
                relationships: {
                    user: {
                        data: {
                            user_id: userId,
                        }
                    }
                }
            }
        })
        .then(res => {
            commit('SET_FEEDBACK', res.data)
            commit('SET_FEEDBACK_STATUS', 'success-second-step')
        })
        .catch(error => {
            commit('SET_FEEDBACK_STATUS', 'error')
        });
    },


};
const mutations = {
    SET_FEEDBACK (state, feedback) {
        state.feedback = feedback;
    },

    SET_FEEDBACK_STATUS (state, feedbackStatus) {
        state.feedbackStatus = feedbackStatus;
    },

    SET_FEEDBACK_SCORE (state, score) {
        state.feedbackScore = score;
    },

    SET_FEEDBACK_TEXT(state, text) {
        state.feedbackText = text;
    },

    SET_FEEDBACK_MINDBOX_USER_ID(state, userId) {
        state.feedbackMindboxUserId = userId;
    },


};

export default {
    state, getters, actions, mutations,
}
