<template>
    <div>
        <div>
            <div>
                <div class="my-7 text-16">
                    <ol>
                        <li>1. Термины</li>
                    </ol>
                    <p>Персональные данные (ПДн) <span>&ndash; информация, относящаяся к Пользователю, в том числе указанная</span><span> </span><span>им</span><span> </span><span>при</span><span> </span><span>оформлении</span><span> </span><span> </span><span>заказа. </span>Обработка
                        персональных данных <span>&ndash; действия (операции), производимые в ручном или автоматическом режиме с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, извлечение, уточнение (обновление, изменение), использование, распространение (в том числе передача), обезличивание, блокирование, полное или частичное уничтожение, а также поручить обработку моих персональных данных: ООО "ПРЕЗЕНТ СИМПЛ". Юридический адрес: 109202, г. Москва, вн. тер. г. муниципальный округ Нижегородский, ул. 3-я Карачаровская, д. 18А, стр. 2, помещ. 1, ком. 5, часть 2.&nbsp;</span>
                    </p>
                    <p><span>Лица, обрабатывающие персональные данные по поручению Продавцом, вправе осуществлять с использованием средств автоматизации или без использования таких средств: запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, блокирование, удаление, полное и частичное уничтожение персональных данных.</span>
                    </p>
                    <p><span>Аналог собственноручной подписи &ndash; проставление отметки (галочки) в окошке &laquo;Согласен с условиями Оферты&raquo;, размещенной на сайте </span><a
                        href="http://naturinika.ru/"><span>https://presentandsimple.com/, </span></a><span>а также при оформлении заказа.</span>
                    </p>
                    <p>&nbsp;</p>
                    <p>2. Согласие на обработку ПДн Покупателя.</p>
                    <p><span>2.1. Оформляя Заказ на сайте https://presentandsimple.com/, Пользователь подтверждает свое осознанное согласие на обработку Продавцом (ООО &laquo;ПРЕЗЕНТ СИМПЛ&raquo;, ОГРН 1237700251040, зарегистрировано по адресу: 109202, г. Москва, вн. тер. г. муниципальный округ Нижегородский, ул. 3-я Карачаровская, д. 18А, стр. 2, помещ. 1, ком. 5, часть 2) своих персональных данных с использованием средств автоматизации и без их использования.</span>
                    </p>
                    <p><span>2.2. Перечень ПДн, на обработку которых дается согласие:</span></p>
                    <ul>
                        <li style="list-style-type: none;">
                            <ul>
                                <li aria-level="3"><span>ФИО;</span></li>
                                <li aria-level="3"><span>Контактный телефон;</span></li>
                                <li aria-level="3"><span>Адрес электронной почты.</span></li>
                                <li aria-level="3"><span>Почтовый адрес.</span></li>
                                <li aria-level="3"><span>Сведения о дате рождения.</span></li>
                                <li aria-level="3"><span>Данные из электронного чека об оплате.</span></li>
                            </ul>
                        </li>
                    </ul>
                    <p><span>2.3. Перечень действий с ПДн, на совершение которых Покупатель даёт согласие на следующие способы использования ПДн:</span>
                    </p>
                    <ul>
                        <li style="list-style-type: none;">
                            <ul>
                                <li aria-level="3"><span>Получение и Хранение ПДн (в электронном виде и на бумажном носителе);</span>
                                </li>
                                <li aria-level="3"><span>Уточнение (обновление, изменение) ПДн;</span></li>
                                <li aria-level="3"><span>Использование ПДн для исполнения настоящего Договора;</span>
                                </li>
                                <li aria-level="3"><span>Передача ПДн Покупателя в порядке, предусмотренном законодательством РФ.</span>
                                </li>
                                <li aria-level="3"><span>В целях организации участия Покупателя в программах лояльности, проведении маркетинговых исследований и иных коммерческих целях;</span>
                                </li>
                                <li aria-level="3"><span>На передачу ПДн третьим лицам в целях организации продвижения продукции, маркетинговых акций, оказания услуг или мероприятий. Такие третьи лица берут на себя обязательства обеспечивать конфиденциальность информации и гарантировать ее защиту, а также обязуются использовать полученную информацию только для целей исполнения взятых на себя обязательств.</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p><span>2.4 Согласие Покупателя на обработку персональных данных предоставляется без ограничения срока его действия.</span>
                    </p>
                    <p><span>2.5. Покупатель, исключительно по личному заявлению, имеет право отозвать (изменить) настоящее</span><span> </span><span>согласие</span><span> </span><span>на</span><span> </span><span>обработку</span><span> </span><span>ПДн.</span>
                    </p>
                    <p><span>2.6. Продавец обрабатывает и обеспечивает конфиденциальность ПДн в соответствии с требованиями действующего законодательства РФ.</span>
                    </p>
                    <p><span>2.7. Покупатель проинформирован о праве на получение информации, касающейся обработки персональных данных, в соответствии с Федеральным законом &laquo;О персональных данных&raquo; от 27.07.2006 № 152-ФЗ, и уведомлен, что может отозвать данное согласие в любой момент посредством направления обращения на адрес электронной почты </span><a
                        href="mailto:ooopresentsimple@yandex.ru"><span>ooopresentsimple@yandex.ru</span></a><span>.</span>
                    </p>
                    <p><span>2.8. Настоящим Покупатель также выражает своё согласие на получение от Продавца рекламных материалов, связанных с продвижением товаров и услуг Продавца, путём осуществления прямых контактов с использованием всех средств связи, включая, но не ограничиваясь: почтовая рассылка, СМС-рассылка, голосовая рассылка, рассылка электронных писем.</span>
                    </p>

                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: "ConsentOfPersonalDataDoc",
}

</script>

<style scoped>
</style>








