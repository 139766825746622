<template>
    <div class="flex flex-col items-center space-y-4 mb-5">
<!--        <button v-if="pagination.currentPage !== this.pagination.totalPages"
                @click="loadMore"
                class="px-4 py-2 border rounded-md text-black border-black">
            Показать ещё
        </button>-->

        <div class="flex items-center space-x-2 text-gray-400">
            <button @click="goToPage(pagination.currentPage - 1)"
                    :disabled="pagination.currentPage === 1"
                    class="p-2 rounded disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-200">
                <span class="sr-only">Назад</span>
                <svg xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke-width="2"
                     stroke="currentColor"
                     class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"/>
                </svg>
            </button>
            <div v-for="page in pagination.totalPages" :key="page">
                <button @click="goToPage(page)"
                        :class="[ 'px-3 py-1 rounded', pagination.currentPage === page
                        ? 'bg-black0 text-black font-semibold'
                        : 'hover:bg-gray-200 text-gray-400']">
                    {{ page }}
                </button>
            </div>
            <button @click="goToPage(pagination.currentPage + 1)"
                    :disabled="pagination.currentPage === pagination.totalPages"
                    class="p-2 rounded disabled:opacity-50 disabled:cursor-not-allowed hover:bg-black">
                <span class="sr-only">Вперёд</span>
                <svg xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke-width="2"
                     stroke="currentColor"
                     class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        pagination: {
            type: Object,
            required: true,
        },
        categoryId: null,
    },
    methods: {
        goToPage(page) {
            if (page < 1 || page > this.pagination.totalPages) return;
            this.$emit('change-page', page);
            this.smoothScroll()
        },
        loadMore() {
            const nextPage = this.pagination.currentPage + 1;
            if (nextPage <= this.pagination.totalPages) {
                this.$emit('change-page', nextPage);
                this.smoothScroll()
            }
        },
        smoothScroll() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    },
};
</script>

<style scoped>

</style>
