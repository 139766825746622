<template>
    <div>
        <input
            @input="handleInputChange"
            type="text"
            maxlength="4"
            placeholder="Введите код из СМС"
            class="mt-2 appearance-none text-11 text-gray-light rounded-none w-full h-9 focus:border px-2 focus:outline-none focus:border-black lg:text-13"
            :class="{ 'border-red': errorMessage }"
        />
        <p v-if="errorMessage" class="text-11 text-red-light-2 mt-2">
            {{ errorMessage }}
        </p>
        <p v-if="isButtonActive" class="text-11 text-black-light-2 mt-2">
            Вы можете повторно отправить код через {{ timeLeft }} секунд
        </p>
    </div>
</template>

<script>
export default {
    name: "InputCode",
    props: {
        duration: {
            type: Number,
            default: 90,
        },
        errorMessage: {
            type: String,
            default: "",
        },
        restartTimer: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        restartTimer(flag) {
            if (flag) this.startTimer(this.$props.duration);
        },
    },
    data: () => ({
        timeLeft: 0,
        isButtonActive: false,
    }),
    methods: {
        handleInputChange(e) {
            //e.target.value = e.target.value.replaceAll(/\D+/gim, "");
            this.$emit("input", e);
        },
        startTimer(duration) {
            this.isButtonActive = true;
            this.timeLeft = duration;
            const endTime = new Date(new Date().getTime() + duration * 1000);
            localStorage.setItem("endTime", endTime);

            const timer = setInterval(() => {
                this.timeLeft--;
                if (this.timeLeft <= 0) {
                    clearInterval(timer);
                    this.isButtonActive = false;
                    localStorage.removeItem("endTime");
                    this.$emit("endTime", true);
                }
            }, 1000);
        },
    },
    mounted() {
        this.startTimer(this.$props.duration);
    },
};
</script>

<style scoped lang="css">
.border-red {
    border: 1px solid rgba(213, 128, 128, 1);
}
</style>
