<template>
    <div class="xl:w-475 xl:mx-auto">
        <div v-if="categoriesStatus === 'success'">
            <div
                class=""
                v-for="category in categories.data"
                :key="'category_' + category.data.category_id"
            >
                <div v-if="category.data.attributes.products.data.length > 0">
                    <router-link
                        :to="{
                            name: 'Category',
                            params: { category: category.links.meta.slug },
                        }"
                    >
                        <div
                            class="my-10 w-full text-gray-main text-2xl font-normal tracking-widest text-center uppercase"
                        >
                            {{ category.data.attributes.title }}
                        </div>
                    </router-link>
                    
                    <div class="px-0.5">
                        <NonSwipableCategoryBlock
                            :category="category.data.attributes.products.data"
                        />
                    </div>
                </div>
            </div>
        </div>
        <Loading v-else />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "../components/Loading";
import NonSwipableCategoryBlock from "../components/NonSwipableCategoryBlock";

export default {
    name: "Catalog",
    components: {
        Loading,
        NonSwipableCategoryBlock
    },
    mounted () {
        this.$store.dispatch('fetchCategories');
    },
    computed: {
        ...mapGetters({
            categories: "categories",
            categoriesStatus: "categoriesStatus",
        }),
    },
};
</script>

<style scoped></style>
