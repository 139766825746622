<template>
    <div class="my-7 text-16">
        <p>Политика в отношении обработки персональных данных ООО &laquo;ПРЕЗЕНТ СИМПЛ&raquo;</p>
        <div>1.Общие положения</div>
        <p>1.1. Настоящая Политика в отношении обработки персональных данных (далее &ndash; &laquo;Политика&raquo;)
            подготовлена в соответствии с п. 2 ч .1 ст. 18.1 Федерального закона Российской Федерации &laquo;О
            персональных данных&raquo; №152-ФЗ от 27 июля 2006 года (далее &ndash; &laquo;Закон&raquo;) и определяет
            позицию ООО &laquo;ПРЕЗЕНТ СИМПЛ&raquo; (далее &ndash; &laquo;Компания&raquo;) в области обработки и защиты
            персональных данных (далее &ndash; &laquo;Данные&raquo;), соблюдения прав и свобод каждого человека и, в
            особенности, права на неприкосновенность частной жизни, личную и семейную тайну.</p>
        <p>2.Область применения</p>
        <p>2.1. Настоящая Политика распространяется на Данные, полученные как до, так и после ввода в действие настоящей
            Политики.</p>
        <p>2.2. Понимая важность и ценность Данных, а также заботясь о соблюдении конституционных прав граждан
            Российской Федерации и граждан других государств, Компания обеспечивает надежную защиту Данных.</p>
        <p>3.Определения</p>
        <p>3.1. Под Данными понимается любая информация, относящаяся к прямо или косвенно определенному или
            определяемому физическому лицу (гражданину), т.е. к такой информации, в частности, относятся: ФИО, год,
            месяц, дата и место рождения, адрес, сведения о семейном, социальном, имущественном положении, сведения об
            образовании, профессии, доходах, номер телефона, адрес электронной почты для связи, информация о кандидатах
            на вакантные должности, оставленная такими кандидатами при заполнение анкеты, включая информацию,
            содержащуюся в резюме кандидата, а также другая информация.</p>
        <p>3.2. Под обработкой Данных понимается любое действие (операция) или совокупность действий (операций) с
            Данными, совершаемых с использованием средств автоматизации и/или без использования таких средств. К таким
            действиям (операциям) относятся: сбор, запись, систематизация, накопление, хранение, уточнение (обновление,
            изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание,
            блокирование, удаление, уничтожение Данных.</p>
        <p>3.3 Под безопасностью Данных понимается защищенность Данных от неправомерного и/или несанкционированного
            доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения Данных, а
            также от иных неправомерных действий в отношении Данных.</p>
        <p>4. Правовые основания и цели обработки Данных</p>
        <p>4.1. Обработка и обеспечение безопасности Данных в Компании осуществляется в соответствии с требованиями
            Конституции Российской Федерации, Закона, Трудового кодекса Российской Федерации, подзаконных актов, других
            определяющих случаи и особенности обработки Данных федеральных законов Российской Федерации, руководящих и
            методических документов ФСТЭК России и ФСБ России.</p>
        <p>4.2. Субъектами Данных, обрабатываемых Компанией, являются:</p>
        <ul>
            <li style="list-style-type: none;">
                <ul>
                    <li style="list-style-type: none;">
                        <ul>
                            <li>кандидаты на вакантные должности;</li>
                            <li>работники Компании, родственники работников Компании, в пределах определяемых
                                законодательством Российской Федерации, если сведения о них предоставляются работником;
                            </li>
                            <li>лица, входящие в органы управления Компании и не являющимися работниками;</li>
                            <li>физические лица, с которыми Компанией заключаются договоры гражданско-правового
                                характера;
                            </li>
                            <li>представители юридических лиц &ndash; контрагентов Компании;</li>
                            <li>клиенты &ndash; потребители, в т.ч. посетители сайта, принадлежащего Компании:
                                https://presentandsimple.com/ (далее &ndash; &laquo;Сайт&raquo;);
                            </li>
                            <li>физические лица, Данные которых обрабатываются в интересах третьих лиц &ndash;
                                операторов Данных на основании договора (поручения операторов Данных);
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
        <p>4.3. Компания осуществляет обработку Данных субъектов в следующих целях:</p>
        <ul>
            <li style="list-style-type: none;">
                <ul>
                    <li style="list-style-type: none;">
                        <ul>
                            <li>осуществления возложенных на Компанию законодательством Российской Федерации функций,
                                полномочий и обязанностей в соответствии с федеральными законами, в том числе, но не
                                ограничиваясь: Гражданским кодексом Российской Федерации, Налоговым кодексом Российской
                                Федерации, Трудовым кодексом Российской Федерации, Семейным кодексом Российской
                                Федерации, Федеральным законом от 01.04.1996 № 27-ФЗ &laquo;Об индивидуальном
                                (персонифицированном) учете в системе обязательного пенсионного страхования&raquo;,
                                Федеральным законом от 27.07.2006 № 152-ФЗ &laquo;О персональных данных&raquo;,
                                Федеральным законом от 28.03.1998 № 53-ФЗ &laquo;О воинской обязанности и военной службе&raquo;,
                                Федеральным законом от 26.02.1997 № 31-ФЗ &laquo;О мобилизационной подготовке и
                                мобилизации в Российской Федерации&raquo;, Федеральным законом от 08.02.1998 №14-ФЗ
                                &laquo;Об обществах с ограниченной ответственностью&raquo;, Федеральным законом от
                                07.02.1992 №2300-1 &laquo;О защите прав потребителей&raquo;, Федеральным законом от
                                21.11.1996 № 129-ФЗ &laquo;О бухгалтерском учете&raquo;, Федеральным законом от
                                29.11.2010 № 326-ФЗ &laquo;Об обязательном медицинском страховании в Российской
                                Федерации&raquo;, а также операторами Данных, уставом и локальными актами Компании.
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
        <p>Работников в целях:</p>
        <ul>
            <li>соблюдения трудового, налогового и пенсионного законодательства Российской Федерации, а именно:
                <ul>
                    <li>содействия работникам в трудоустройстве, обучении и продвижении по службе;</li>
                    <li>расчета и начисления заработной платы;</li>
                    <li>организация деловых поездок (командировок) работников;</li>
                    <li>оформления доверенностей (в том числе для представления интересов Компании перед третьими
                        лицами);
                    </li>
                    <li>обеспечения личной безопасности работников;</li>
                    <li>контроля количества и качества выполняемой работы;</li>
                    <li>обеспечения сохранности имущества;</li>
                    <li>соблюдения пропускного режима в помещениях Компании;</li>
                    <li>учета рабочего времени;</li>
                    <li>пользования различного вида льготами в соответствии с Трудовым кодексом Российской Федерации,
                        Налоговым кодексом Российской Федерации, федеральными законами, а также Уставом и нормативными
                        актами Компании;
                    </li>
                    <li>добровольного страхования жизни, здоровья и/или от несчастных случаев.</li>
                </ul>
            </li>
        </ul>
        <p>Кандидатов на вакантные должности в целях:</p>
        <ul>
            <li>принятия решения о возможности заключения трудового договора с лицами, претендующими на открытые
                вакансии;
            </li>
        </ul>
        <p>Лиц, входящих в органы управления Компании, не являющихся работниками, в целях:</p>
        <ul>
            <li>выполнения требований, предусмотренных законодательством, в т.ч. обязательное раскрытие информации,
                аудит, проверка возможности совершения сделок, в том числе сделок с заинтересованностью и/или крупных
                сделок.
            </li>
        </ul>
        <p>Контрагентов-физических лиц в целях:</p>
        <ul>
            <li>заключения и исполнения договора, одной из сторон которого является физическое лицо; &shy; рассмотрения
                возможностей дальнейшего сотрудничества.
            </li>
        </ul>
        <p>Представителей юридических лиц &ndash; контрагентов Компании в целях:</p>
        <ul>
            <li>ведения переговоров, заключение и исполнение договоров, по которым предоставляются Данные работников
                такого юридического лица для целей исполнения договора по различным направлениям хозяйственной
                деятельности Компании.
            </li>
        </ul>
        <p>Физических лиц, Данные которых обрабатываются в интересах третьих лиц &ndash; операторов Данных на основании
            договора (поручения операторов Данных) в целях:</p>
        <ul>
            <li>исполнения договоров &ndash; поручений операторов Данных; Родственников работников Компании в целях:
            </li>
            <li>исполнения требований законодательства Российской Федерации;</li>
            <li>предоставления дополнительных льгот;</li>
            <li>участия в корпоративных мероприятиях.</li>
        </ul>
        <p>Клиентов &ndash; потребителей в целях:</p>
        <ul>
            <li>предоставления информации по товарам/услугам, проходящим акциям и специальным предложениям;</li>
            <li>анализа качества предоставляемого Компанией сервиса и улучшению качества обслуживания клиентов
                Компании;
            </li>
            <li>информирования о статусе заказа;</li>
            <li>исполнения договора, в т.ч. договора купли-продажи, в.т.ч. заключенного дистанционным способом на Сайте,
                возмездного оказания услуг;
            </li>
            <li>доставки заказанного товара клиенту, совершившему заказ на Сайте, возврата товара.</li>
        </ul>
        <p>5. Принципы и условия обработки Данных</p>
        <p>5.1. При обработке Данных Компания придерживается следующих принципов:</p>
        <ul>
            <li style="list-style-type: none;">
                <ul>
                    <li style="list-style-type: none;">
                        <ul>
                            <li>обработка Данных осуществляется на законной и справедливой основе;</li>
                            <li>Данные не раскрываются третьим лицам и не распространяются без согласия субъекта Данных,
                                за исключением случаев, требующих раскрытия Данных по запросу уполномоченных
                                государственных органов, судопроизводства;
                            </li>
                            <li>определение конкретных законных целей до начала обработки (в т.ч. сбора) Данных;</li>
                            <li>ведется сбор только тех Данных, которые являются необходимыми и достаточными для
                                заявленной цели обработки;
                            </li>
                            <li>объединение баз данных, содержащих Данные, обработка которых осуществляется в целях,
                                несовместимых между собой не допускается;
                            </li>
                            <li>обработка Данных ограничивается достижением конкретных, заранее определенных и законных
                                целей;
                            </li>
                            <li>обрабатываемые Данные подлежат уничтожению или обезличиванию по достижению целей
                                обработки или в случае утраты необходимости в достижении этих целей, если иное не
                                предусмотрено федеральным законом.
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
        <p>5.2.Компания может включать Данные субъектов в общедоступные источники Данных, при этом Компания берет
            письменное согласие субъекта на обработку его Данных.</p>
        <p>5.3.Компания не осуществляет обработку Данных, касающихся расовой, национальной принадлежности, политических
            взглядов, религиозных, философских и иных убеждений, интимной жизни, членства в общественных объединениях, в
            том числе в профессиональных союзах.</p>
        <p>5.4. Компания может осуществлять обработку данных о состоянии здоровья субъекта Данных в следующих
            случаях:</p>
        <ol>
            <li style="list-style-type: none;">
                <ol>
                    <li style="list-style-type: none;">
                        <ul>
                            <li>в соответствии с законодательством о государственной социальной помощи, трудовым
                                законодательством, законодательством Российской Федерации о пенсиях по государственному
                                пенсионному обеспечению, о трудовых пенсиях;
                            </li>
                            <li>для защиты жизни, здоровья или иных жизненно важных интересов работника либо для защиты
                                жизни, здоровья или иных жизненно важных интересов других лиц и получение согласия
                                субъекта Данных невозможно;
                            </li>
                            <li>для установления или осуществления прав работника или третьих лиц, а равно и в связи с
                                осуществлением правосудия;
                            </li>
                            <li>соответствии с законодательством об обязательных видах страхования, со страховым
                                законодательством.
                            </li>
                        </ul>
                    </li>
                </ol>
            </li>
        </ol>
        <p>Биометрические Данные (сведения, которые характеризуют физиологические и биологические особенности человека,
            на основании которых можно установить его личность и которые используются оператором для установления
            личности субъекта Данные) в Компании не обрабатываются.</p>
        <p>Компания не осуществляет трансграничную передачу Данных.</p>
        <p>В случаях, установленных законодательством Российской Федерации, Компания вправе осуществлять передачу Данных
            третьим лицам (федеральной налоговой службе, государственному пенсионному фонду и иным государственным
            органам) в случаях, предусмотренных законодательством Российской Федерации.</p>
        <p>Компания вправе поручить обработку Данных субъектов Данных третьим лицам с согласия субъекта Данных, на
            основании заключаемого с этими лицами договора.</p>
        <p>Лица, осуществляющие обработку Данных на основании заключаемого с Компанией договора (поручения оператора),
            обязуются соблюдать принципы и правила обработки и защиты Данных, предусмотренные Законом. Для каждого
            третьего лица в договоре определяются перечень действий (операций) с Данными, которые будут совершаться
            третьим лицом, осуществляющим обработку Данных, цели обработки, устанавливается обязанность такого лица
            соблюдать конфиденциальность и обеспечивать безопасность Данных при их обработке, указываются требования к
            защите обрабатываемых Данных в соответствии с Законом.</p>
        <p>В целях исполнения требований действующего законодательства Российской Федерации и своих договорных
            обязательств обработка Данных в Компании осуществляется как с использованием, так и без использования
            средств автоматизации. Совокупность операций обработки включает сбор, запись, систематизацию, накопление,
            хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение Данных. В Компании запрещается принятие на основании
            исключительно автоматизированной обработки Данных решений, порождающих юридические последствия в отношении
            субъекта Данных или иным образом затрагивающих его права и законные интересы, за исключением случаев,
            предусмотренных законодательством Российской Федерации.</p>
        <p>Права и обязанности субъектов Данных, а также Компании в части обработки Данных</p>
        <p>Субъект, Данные которого обрабатываются Компанией, имеет право:</p>
        <ul>
            <li style="list-style-type: none;">
                <ul>
                    <li style="list-style-type: none;">
                        <ul>
                            <li>получать от Компании:
                                <ul>
                                    <li>подтверждение факта обработки Данных и сведения о наличии Данных, относящихся к
                                        соответствующему субъекту Данных;
                                    </li>
                                    <li>сведения о правовых основаниях и целях обработки Данных;</li>
                                    <li>сведения о применяемых Компанией способах обработки Данных;</li>
                                    <li>сведения о наименовании и местонахождении Компании;</li>
                                    <li>сведения о лицах (за исключением работников Компании), которые имеют доступ к
                                        Данным или которым могут быть раскрыты Данные на основании договора с Компанией
                                        или на основании федерального закона;
                                    </li>
                                    <li>перечень обрабатываемых Данных, относящихся к субъекту Данных, и информацию об
                                        источнике их получения, если иной порядок предоставления таких Данных не
                                        предусмотрен федеральным законом;
                                    </li>
                                    <li>сведения о сроках обработки Данных, в том числе о сроках их хранения;</li>
                                    <li>сведения о порядке осуществления субъектом Данных прав, предусмотренных Законом;
                                        o наименовании (Ф.И.О.) и адресе лица, осуществляющего обработку Данных по
                                        поручению Компании;
                                    </li>
                                    <li>иные сведения, предусмотренные Законом или другими нормативно-правовыми актами
                                        Российской Федерации;
                                    </li>
                                </ul>
                            </li>
                            <li>требовать от Компании уточнения своих Данных, их блокирования или уничтожения в случае,
                                если Данные являются неполными, устаревшими, неточными, незаконно полученными или не
                                являются необходимыми для заявленной цели обработки;
                            </li>
                            <li>отозвать свое согласие на обработку Данных в любой момент;</li>
                            <li>требовать устранения неправомерных действий Компании в отношении его Данных;</li>
                            <li>обжаловать действия или бездействие Компании в Федеральную службу по надзору в сфере
                                связи, информационных технологий и массовых коммуникаций (Роскомнадзор) или в судебном
                                порядке в случае, если субъект Данных считает, что Компания осуществляет обработку его
                                Данных с нарушением требований Закона или иным образом нарушает его права и свободы;
                            </li>
                            <li>на защиту своих прав и законных интересов, в том числе на возмещения убытков и/или
                                компенсацию морального вреда в судебном порядке.
                            </li>
                        </ul>
                    </li>
                    <li>Компания в процессе обработки Данных обязана:
                        <ul>
                            <li>предоставлять субъекту Данных по его запросу информацию, касающуюся обработки его ПДн,
                                либо на законных основаниях предоставить отказ в течение тридцати дней с даты получения
                                запроса субъекта Данных или его представителя;
                            </li>
                            <li>разъяснить субъекту Данных юридические последствия отказа предоставить Данные, если
                                предоставление Данных является обязательным в соответствии с федеральным законом;
                            </li>
                            <li>до начала обработки Данных (если Данные получены не от субъекта Данных) предоставить
                                субъекту Данных следующую информацию, за исключением случаев, предусмотренных частью 4
                                статьи 18 Закона:<br/>1. наименование либо фамилия, имя, отчество и адрес Компании или
                                ее представителя;<br/>2.цель обработки Данных и ее правовое основание;<br/>3.
                                предполагаемые пользователи Данных;<br/>4. установленные Законом права субъектов Данных;<br/>5.
                                источник получения Данных.
                            </li>
                            <li>принимать необходимые правовые, организационные и технические меры или обеспечивать их
                                принятие для защиты Данных от неправомерного или случайного доступа к ним, уничтожения,
                                изменения, блокирования, копирования, предоставления, распространения Данных, а также от
                                иных неправомерных действий в отношении Данных;
                            </li>
                            <li>опубликовать в сети Интернет и обеспечить неограниченный доступ с использованием сети
                                Интернет к документу, определяющему его политику в отношении обработки Данных, к
                                сведениям о реализуемых требованиях к защите Данных;
                            </li>
                            <li>предоставить субъектам Данных и/или их представителям безвозмездно возможность
                                ознакомления с Данными при обращении с соответствующим запросом в течение 30 дней с даты
                                получения подобного запроса;
                            </li>
                            <li>осуществить блокирование неправомерно обрабатываемых Данных, относящихся к субъекту
                                Данных, или обеспечить их блокирование (если обработка Данных осуществляется другим
                                лицом, действующим по поручению Компании) с момента обращения или получения запроса на
                                период проверки, в случае выявления неправомерной обработки Данных при обращении
                                субъекта Данных или его представителя либо по запросу субъекту Данных или его
                                представителя либо уполномоченного органа по защите прав субъектов персональных данных;
                            </li>
                            <li>уточнить Данные либо обеспечить их уточнение (если обработка Данных осуществляется
                                другим лицом, действующим по поручению Компании) в течение 7 рабочих дней со дня
                                представления сведений и снять блокирование Данных, в случае подтверждения факта
                                неточности Данных на основании сведений, представленных субъектом Данных или его
                                представителем;
                            </li>
                            <li>прекратить неправомерную обработку Данных или обеспечить прекращение неправомерной
                                обработки Данных лицом, действующим по поручению Компании, в случае выявления
                                неправомерной обработки Данных, осуществляемой Компанией или лицом, действующим на
                                основании договора с Компанией, в срок, не превышающий 3 рабочих дней с даты этого
                                выявления;
                            </li>
                            <li>прекратить обработку Данных или обеспечить ее прекращение (если обработка Данных
                                осуществляется другим лицом, действующим по договору с Компанией) и уничтожить Данные
                                или обеспечить их уничтожение (если обработка Данных осуществляется другим лицом,
                                действующим по договору с Компанией) по достижения цели обработки Данных, если иное не
                                предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по
                                которому является субъект Данных, в случае достижения цели обработки Данных;
                            </li>
                            <li>прекратить обработку Данных или обеспечить ее прекращение и уничтожить Данные или
                                обеспечить их уничтожение в случае отзыва субъектом Данных согласия на обработку Данных,
                                если Компания не вправе осуществлять обработку Данных без согласия субъекта Данных;
                            </li>
                            <li>вести журнал учета обращений субъектов ПДн, в котором должны фиксироваться запросы
                                субъектов Данных на получение Данных, а также факты предоставления Данных по этим
                                запросам.
                            </li>
                        </ul>
                    </li>
                    <li>Требования к защите Данных
                        <ul>
                            <li>Компания при обработке Данных принимает необходимые правовые, организационные и
                                технические меры для защиты Данных от неправомерного и/или несанкционированного доступа
                                к ним, уничтожения, изменения, блокирования, копирования, предоставления,
                                распространения Данных, а также от иных неправомерных действий в отношении Данных.
                            </li>
                            <li>К таким мерам в соответствии с Законом, в частности, относятся:
                                <ul>
                                    <li>назначение лица, ответственного за организацию обработки Данных, и лица,
                                        ответственного за обеспечение безопасности Данных;
                                    </li>
                                    <li>разработка и утверждение локальных актов по вопросам обработки и защиты
                                        Данных;
                                    </li>
                                    <li>применение правовых, организационных и технических мер по обеспечению
                                        безопасности Данных:
                                        <ul>
                                            <li>определение угроз безопасности Данных при их обработке в информационных
                                                системах персональных данных;
                                            </li>
                                            <li>применение организационных и технических мер по обеспечению безопасности
                                                Данных при их обработке в информационных системах персональных данных,
                                                необходимых для выполнения требований к защите Данных, исполнение
                                                которых обеспечивает установленные Правительством Российской Федерации
                                                уровни защищенности Данных;
                                            </li>
                                            <li>применение прошедших в установленном порядке процедуру оценки
                                                соответствия средств защиты информации;
                                            </li>
                                            <li>оценка эффективности принимаемых мер по обеспечению безопасности Данных
                                                до ввода в эксплуатацию информационной системы персональных данных;
                                            </li>
                                            <li>учет машинных носителей Данных, если хранение Данных осуществляется на
                                                машинных носителях;
                                            </li>
                                            <li>обнаружение фактов несанкционированного доступа к Данным и принятие мер
                                                по недопущению подобных инцидентов в дальнейшем;
                                            </li>
                                            <li>восстановление Данных, модифицированных или уничтоженных вследствие
                                                несанкционированного доступа к ним;
                                            </li>
                                            <li>установление правил доступа к Данным, обрабатываемым в информационной
                                                системе персональных данных, а также обеспечение регистрации и учета
                                                всех действий, совершаемых с Данными в информационной системе
                                                персональных данных.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>контроль за принимаемыми мерами по обеспечению безопасности Данных и уровнем
                                        защищенности информационных систем персональных данных;
                                    </li>
                                    <li>оценка вреда, который может быть причинен субъектам Данных в случае нарушения
                                        требований Закона, соотношение указанного вреда и принимаемых Компанией мер,
                                        направленных на обеспечение выполнения обязанностей, предусмотренных Законом;
                                    </li>
                                    <li>соблюдение условий, исключающих несанкционированный доступ к материальным
                                        носителям Данных и обеспечивающих сохранность Данных;
                                    </li>
                                    <li>ознакомление работников Компании, непосредственно осуществляющих обработку
                                        Данных, с положениями законодательства Российской Федерации о Данных, в том
                                        числе с требованиями к защите Данных, локальными актами по вопросам обработки и
                                        защиты Данных, и обучение работников Компании.
                                    </li>
                                </ul>
                            </li>
                            <li>Сроки обработки (хранения) Данных
                                <ul>
                                    <li>Сроки обработки (хранения) Данных определяются исходя из целей обработки Данных,
                                        в соответствии со сроком действия договора с субъектом Данных, требованиями
                                        федеральных законов, требованиями операторов Данных, по поручению которых
                                        Компания осуществляет обработку Данных, основными правилами работы архивов
                                        организаций, сроками исковой давности.
                                    </li>
                                    <li>Данные, срок обработки (хранения) которых истек, должны быть уничтожены, если
                                        иное не предусмотрено федеральным законом. Хранение Данных после прекращения их
                                        обработки допускается только после их обезличивания.
                                    </li>
                                </ul>
                            </li>
                            <li>Порядок получения разъяснений по вопросам обработки Данных
                                <ul>
                                    <li>Лица, чьи Данные обрабатываются Компанией, могут получить разъяснения по
                                        вопросам обработки своих Данных, обратившись лично в Компанию или направив
                                        соответствующий письменный запрос по адресу местонахождения Компании: 119435, г.
                                        Москва, Большой Саввинский пер., д. 12, стр. 16, помещ. XIA, ком. 1-5.
                                    </li>
                                    <li>В случае направления официального запроса в Компанию в тексте запроса необходимо
                                        указать:
                                        <ul>
                                            <li>фамилию, имя, отчество субъекта Данных или его представителя;</li>
                                            <li>номер основного документа, удостоверяющего личность субъекта Данных или
                                                его представителя, сведения о дате выдачи указанного документа и
                                                выдавшем его органе;
                                            </li>
                                            <li>сведения, подтверждающие наличие у субъекта Данных отношений с
                                                Компанией;
                                            </li>
                                            <li>информацию для обратной связи с целью направления Компанией ответа на
                                                запрос;
                                            </li>
                                            <li>подпись субъекта Данных (или его представителя). Если запрос
                                                отправляется в электронном виде, то он должен быть оформлен в виде
                                                электронного документа и подписан электронной подписью в соответствии с
                                                законодательством Российской Федерации.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>Особенности обработки и защиты Данных, собираемых Компанией с использованием
                                        сети Интернет
                                        <ul>
                                            <li>Компания обрабатывает и защищает Данные, поступающие от пользователей
                                                Cайта, а также поступающие на адреса корпоративной почты Компании,
                                                заканчивающиеся на _____@ com.
                                            </li>
                                            <li>Сбор Данных</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
        <p>Существуют два основных способа, с помощью которых Компания получает Данные с помощью сети Интернет:</p>
        <ol>
            <li>Предоставление Данных (включая фамилию, имя, должность, место работы, должность, контактный телефон,
                адрес электронной почты, адрес и др.) субъектами Данных путем заполнения соответствующих форм на Сайте и
                посредством направления электронных писем на корпоративные адреса Компании.
            </li>
            <li>Автоматически собираемая информация Компания может собирать и обрабатывать сведения, не являющимися
                персональными данными:
                <ul>
                    <li>информацию об интересах пользователей на Сайте на основе введенных поисковых запросов
                        пользователей Сайта о реализуемых и предлагаемых к продаже товаров Компанией с целью
                        предоставления актуальной информации клиентам Компании при использовании Сайта, а также
                        обобщения и анализа информации, о том какие разделы Сайта и товары пользуются наибольшим спросом
                        у клиентов Компании;
                    </li>
                    <li>обработка и хранение поисковых запросов пользователей Сайта с целью обобщения и создания
                        клиентской статистики об использовании разделов Сайта. Компания автоматически получает некоторые
                        виды информации, получаемой в процессе взаимодействия пользователей с Cайтом, переписки по
                        электронной почте и т. п. Речь идет о технологиях и сервисах, таких как веб-протоколы, куки,
                        веб-отметки, а также приложения и инструменты указанной третьей стороны.
                    </li>
                    <li>Куки. Куки &ndash; это часть данных, автоматически располагающаяся на жестком диске компьютера
                        при каждом посещении веб-сайта. Таким образом, куки &ndash; это уникальный идентификатор
                        браузера для веб-сайта. Куки дают возможность хранить информацию на сервере и помогают легче
                        ориентироваться в веб-пространстве, а также позволяют осуществлять анализ сайта и оценку
                        результатов. Большинство веб-браузеров разрешают использование куки, однако можно изменить
                        настройки для отказа от работы с куки или отслеживания пути их рассылки. При этом некоторые
                        ресурсы могут работать некорректно, если работа куки в браузере будет запрещена.
                    </li>
                    <li>Веб-отметки. На определенных веб-страницах или электронных письмах Компания может использовать
                        распространенную в Интернете технологию &laquo;веб-отметки&raquo; (также известную как &laquo;тэги&raquo;
                        или &laquo;точная GIF-технология&raquo;). Веб-отметки помогают анализировать эффективность
                        веб-сайтов, например, с помощью измерения числа посетителей сайта или количества &laquo;кликов&raquo;,
                        сделанных на ключевых позициях страницы сайта.
                    </li>
                </ul>
            </li>
        </ol>
        <p>При этом веб-отметки, куки и другие мониторинговые технологии не дают возможность автоматически получать
            Данные. Если пользователь Сайта по своему усмотрению предоставляет свои Данные, например, при заполнении
            формы обратной связи или при отправке электронного письма, то только тогда запускаются процессы
            автоматического сбора подробной информации для удобства пользования вебсайтами и/или для совершенствования
            взаимодействия с пользователями.</p>
        <ul>
            <li>Использование Данных</li>
        </ul>
        <p>Компания вправе пользоваться предоставленными Данными в соответствии с заявленными целями их сбора при
            наличии согласия субъекта Данных, если такое согласие требуется в соответствии с требованиями
            законодательства Российской Федерации в области Данных.</p>
        <p>Полученные Данные в обобщенном и обезличенном виде могут использоваться для лучшего понимания потребностей
            покупателей товаров и услуг, реализуемых Компанией и улучшения качества обслуживания.</p>
        <ul>
            <li>Передача Данных</li>
        </ul>
        <p>Компания может поручать обработку Данных третьим лицам исключительно с согласия субъекта Данных. Также Данные
            могут передаваться третьим лицам в следующих случаях:</p>
        <p>1. B качестве ответа на правомерные запросы уполномоченных государственных органов, в соответствии с
            законами, решениями суда и пр.</p>
        <p>2. Данные не могут передаваться третьим лицам для маркетинговых, коммерческих и иных аналогичных целей, за
            исключением случаев получения предварительного согласия субъекта Данных.</p>
        <ul>
            <li>Сайт может содержать ссылки на иные веб-ресурсы, где может находиться полезная и интересная для
                пользователей Сайта информация. При этом действие настоящей Политики не распространяется на такие иные
                сайты. Пользователям, переходящим по ссылкам на другие сайты, рекомендуется ознакомиться с политиками об
                обработке Данных, размещенными на таких сайтах.
            </li>
            <li>Пользователь Сайта может в любое время отозвать свое согласие на обработку Данных, направив электронное
                сообщение по адресу электронной почты: <a href="mailto:office@presentandsimple.com">office@presentandsimple.com</a>,
                либо направив письменное уведомление по адресу Компании: 119435, г. Москва, Большой Саввинский пер, д.
                12, стр. 16, помещ. XIA, ком. 1-5. После получения такого сообщения обработка Данных пользователя будет
                прекращена, а его Данные будут удалены, за исключением случаев, когда обработка может быть продолжена в
                соответствии с законодательством.
            </li>
        </ul>
        <ul>
            <li>Заключительные положения</li>
        </ul>
        <p>Настоящая Политика является локальным нормативным актом Компании. Настоящая Политика является общедоступной.
            Общедоступность настоящей Политики обеспечивается публикацией на Сайте Компании.</p>
        <p>Настоящая Политика может быть пересмотрена в любом из следующих случаев:</p>
        <ul>
            <li>при изменении законодательства Российской Федерации в области обработки и защиты персональных данных;
            </li>
            <li>в случаях получения предписаний от компетентных государственных органов на устранение несоответствий,
                затрагивающих область действия Политики;
            </li>
            <li>по решению руководства Компании;</li>
            <li>при изменении целей и сроков обработки Данных;</li>
            <li>при изменении организационной структуры, структуры информационных и/или телекоммуникационных систем (или
                введении новых);
            </li>
            <li>при применении новых технологий обработки и защиты Данных (в т. ч. передачи, хранения);</li>
            <li>при появлении необходимости в изменении процесса обработки Данных, связанной с деятельностью Компании.
            </li>
        </ul>
        <p>В случае неисполнения положений настоящей Политики Компания и ее работники несут ответственность в
            соответствии с действующим законодательством Российской Федерации.</p>
        <p>Контроль исполнения требований настоящей Политики осуществляется лицами, ответственными за организацию
            обработки Данных Компании, а также за безопасность персональных данных.</p>
        <p>&nbsp;</p>
    </div>
</template>

<script>


export default {
    name: "PrivacyPolicyDoc",
}

</script>

<style scoped>
</style>







