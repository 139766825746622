<template>
    <div
        class="h-12 sm:h-15 w-full sticky top-0 bg-white z-30 shadow flex items-center justify-between lg:px-12">
        <!--Обертка для клика по меню -->

        <div class="h-12 sm:h-15 flex items-center ml-4 cursor-pointer lg:hidden" @click="toggleMenu">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="#5c5c5c"
                 stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu">
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
        </div>

        <div v-if="focus" @click="toggleMenu"
             class="absolute bg-gray-dark bg-opacity-25 text-gray-main w-full h-screen top-0 left-0 z-30 overflow-auto">
            <div class="p-8 bg-white w-85 min-h-screen" @click.stop>
                <button @click="toggleMenu" class="focus:outline-none">
                    <img src="/storage/images/icons/close.svg" alt="close menu">
                </button>

                <accordion class="z-30" v-if="navigationStatus=='success'">
                    <span class="divide-y divide-border-gray-light">
                        <accordion-item v-for="menu_item in navigation.data"
                                        :key="menu_item?.data.attributes.navigation_category_id">
                            <template v-slot:accordion-trigger="slotProps">
                                <div v-if="menu_item?.data.attributes.mobile"  class="mt-1 w-70 h-16 py-5 text-black text-base font-normal tracking-widest uppercase">
                                    <div v-if="menu_item?.data.attributes.navigation_items.data.length"
                                         class="flex items-center justify-between">
                                        <div>{{ menu_item?.data.attributes.title }}</div>
                                        <div class="w-4 h-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 stroke="currentColor"
                                                 :class="[slotProps.visible ? 'transform rotate-90' : '']">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                                                      d="M9 5l7 7-7 7"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-else @click="toggleMenu">
                                        <a :href="menu_item?.data.attributes.path" class="w-full h-full">
                                            {{ menu_item?.data.attributes.title }}
                                        </a>
                                    </div>

                                </div>
                            </template>

                            <template v-slot:accordionContent>
                                <accordion class="">
                                    <accordion-item v-for="sub_item in menu_item?.data.attributes.navigation_items?.data"
                                                    v-bind:key="sub_item?.data.attributes.navigation_item_id">
                                        <template v-slot:accordion-trigger="subSlotProps">
                                            <div class="w-full flex py-3 text-gray-main text-sm font-normal tracking-widest uppercase">
                                                <div v-if="sub_item?.data.attributes.navigation_sub_items.data.length"
                                                     class="w-full flex items-center justify-between">
                                                    <div>{{ sub_item?.data.attributes.title }}</div>
                                                    <div class="w-4 h-4">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24" stroke="currentColor"
                                                             :class="[subSlotProps.visible ? 'transform rotate-90' : '']">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                  stroke-width="1" d="M9 5l7 7-7 7"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div v-else @click="toggleMenu">
                                                    <a :href="sub_item?.data.attributes.path"
                                                       class="w-full h-full">
                                                        {{ sub_item?.data.attributes.title }}
                                                    </a>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-slot:accordionContent>
                                            <div
                                                v-for="sub_sub_item in sub_item?.data.attributes.navigation_sub_items?.data "
                                                :key="sub_sub_item?.data.navigation_sub_item_id" class="">
                                                <div @click="toggleMenu">
                                                    <a :href="sub_sub_item?.data.attributes.path">
                                                        <div class="py-3">
                                                            <div
                                                                class="text-gray-light text-left text-sm font-normal tracking-widest uppercase">
                                                                {{ sub_sub_item?.data.attributes.title }}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </template>
                                    </accordion-item>
                                </accordion>
                            </template>
                        </accordion-item>
                    </span>
                </accordion>

            </div>

        </div>

        <!-- Logo -->
        <div class="inset-center lg:hidden">
            <router-link to="/" class="outline-none focus:outline-none">
                <img class="h-5" src="/storage/images/logo.svg" alt="present simple logo">
            </router-link>
        </div>
        <div class="hidden lg:block h-15 flex items-center">
            <router-link to="/" class="outline-none focus:outline-none h-15 flex items-center">
                <img class="h-6" src="/storage/images/logo.svg" alt="present simple logo">
            </router-link>
        </div>

        <!-- Menu on tablets and up -->
        <div v-if="navigationStatus=='success'"
             class="h-12 sm:h-15 mx-5 hidden lg:flex lg:flex-row uppercase text-base font-normal tracking-widest flex-1">
            <Dropdown v-for="item in navigation.data"
                      :key="item.data.navigation_category_id"
                      :item=item
            />
        </div>

        <!-- Icons -->
        <div class="h-12 sm:h-15 flex items-center">
            <router-link class="hidden sm:block" to="/search">
                <img src="/storage/images/icons/search.svg" alt="search">
            </router-link>
            <button @click="goToLogin">
                <img class="mx-5" src="/storage/images/icons/user.svg" alt="account">
            </button>
            <Login v-if="isPopupActive" :isPopupActive="isPopupActive" @closePopup="closeLoginPopup"/>
            <router-link class="mr-5 lg:mr-0"
                         :to="goToPreorderCartWhenClikedIcon ? {name: 'PreorderCart'} : {name: 'Cart2'}">
                <div class="relative">
                    <img src="/storage/images/icons/shopping-bag.svg" alt="shopping bag">
                    <div class="absolute w-4 h-4 flex items-center justify-center bottom-0 right-0 -mb-1 -mr-1
              rounded-full text-xs text-bg-white bg-black">
                        {{ quantityOfProductsInAllCarts }}
                    </div>
                </div>
            </router-link>
        </div>

    </div>
</template>

<script>
import Accordion from "./Accordion/Accordion";
import AccordionItem from "./Accordion/AccordionItem";

import {mapGetters} from 'vuex';
import Dropdown from "./Dropdown";
import DropdownItem from "./DropdownItem";
import axios from '@/api';
import Login from "./Auth/Login.vue";

export default {
    name: "Nav",
    components: {
        Login,
        Dropdown, DropdownItem,
        Accordion, AccordionItem
    },

    data: () => {
        return {
            focus: false,
            focus2: false,
            isPopupActive: false,


        }
    },
    computed: {
        ...mapGetters({
            cart: 'cart',
            preorderCart: 'preorderCart',
            navigation: 'navigation',
            navigationStatus: 'navigationStatus',
        }),
        quantityOfProductsInAllCarts() {
            return this.cart && this.preorderCart
                ? this.cart.data.attributes.itemsQuantity + this.preorderCart.data.attributes.itemsQuantity
                : 0
        },
        goToPreorderCartWhenClikedIcon() {
            return !!(this.preorderCart && this.cart
                && this.preorderCart.data.attributes.itemsQuantity > 0 && this.cart.data.attributes.itemsQuantity === 0)
        }
    },
    methods: {
        toggleMenu() {
            this.focus = !this.focus;
        },
        closeLoginPopup(data) {
            this.isPopupActive = data.popupActive
        },
        goToLogin() {
            let authStatus = null
            axios.get('/api/auth/status')
                .then(res => {
                    authStatus = res.data.data.authStatus
                    if (authStatus) {
                        this.$router.replace({name: 'Account'});
                    } else {
                        this.isPopupActive = true
                    }
                }).catch(() => {/*
              return next({ redirect: '/404'})*/
            })
        }
    },
    mounted() {
        this.$store.dispatch('fetchNavigation');
    },
}
</script>

<style lang="scss" scoped>

.inset-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


</style>
