<template>
    <div class="relative h-12 sm:h-15 items-left"
         @mouseover="showDropdown=true" @mouseleave="showDropdown=false">

        <div class="h-12 sm:h-15 text-black flex items-center menu-item">
            <a v-if="item.data.attributes.desktop" class="px-4 align-middle "
               :href="item.data.attributes.path">
                {{ item.data.attributes.title }}
            </a>
        </div>

        <nav v-if="showDropdown && item.data.attributes.navigation_items.data.length"
             class="max-h-93vh absolute text-sm text-gray-main top-0 ml-4 w-75 pt-6 pb-3 sm:mt-15 bg-white border border-gray-lighter border-opacity-100 overflow-y-scroll no-scrollbar">

            <accordion class="">
                <accordion-item v-for="sub_item in item.data.attributes.navigation_items.data"
                                :key="sub_item.data.attributes.navigation_item_id"
                                class="w-full px-6 pb-3">
                    <template v-slot:accordion-trigger="slotProps">
                        <div class="w-full cursor-pointer">
                            <div v-if="sub_item.data.attributes.navigation_sub_items.data.length > 0"
                                 class="text-14 text-gray-main tracking-normal flex items-center justify-between">
                                <div>{{ sub_item.data.attributes.title }}</div>
                                <div class="w-4 h-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         stroke="currentColor"
                                         :class="[slotProps.visible ? 'transform rotate-90' : '']">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                                              d="M9 5l7 7-7 7"/>
                                    </svg>
                                </div>
                            </div>
                            <div v-else @click="showDropdown = !showDropdown">
                                <a :href="sub_item.data.attributes.path" class="w-full h-full">
                                    {{ sub_item.data.attributes.title }}
                                </a>
                            </div>
                        </div>
                    </template>
                    <template v-slot:accordionContent>
                        <div v-for="sub_sub_item in sub_item.data.attributes.navigation_sub_items.data "
                             :key="sub_sub_item.data.navigation_sub_item_id">
                            <div @click="showDropdown = !showDropdown">
                                <a :href="sub_sub_item.data.attributes.path" class="w-full h-full">
                                    <div class="pl-2 py-2">
                                        <div
                                            class="text-gray-main text-left text-sm font-normal tracking-wider uppercase">
                                            {{ sub_sub_item.data.attributes.title }}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </template>
                </accordion-item>
            </accordion>


        </nav>
        <nav v-else-if="showDropdown && item.data.attributes.navigation_items.data.length==0" class="w-3/4 ml-4"></nav>

    </div>
</template>

<script>
import Accordion from "./Accordion/Accordion";
import AccordionItem from "./Accordion/AccordionItem";

export default {
    props: {
        item: null
    },
    components: {
        Accordion, AccordionItem
    },
    data() {
        return {
            showDropdown: false,
        };
    },

};
</script>

<style lang="scss" scoped>
nav {
    border-top: 2px solid #5c5c5c;
}


</style>
