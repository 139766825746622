<template>
    <div>
        <div class="relative">
            <div class="lg:h-250 overflow-auto no-scrollbar relative swiper xl:h-360 2xl:h-450 3xl:h-screen" ref="swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-if="product.data.attributes.video_id != null">
                        <div class="">
                            <div class="relative">
                                <div class="absolute w-full h-full items-center z-10"></div>
                                <vimeo-player controls="false" class="z-0" ref="player" :video-id="product.data.attributes.video_id"
                                              @ready="onReady" :options="options">
                                </vimeo-player>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide" v-for="image in product.data.attributes.images.data"
                         :key=image.data.product_image_id>
                        <img class="w-full h-full object-cover" :src="image.data.attributes.path" alt="">
                    </div>
                </div>
            </div>
            <div class="hidden lg:block lg:absolute lg:top-0 lg:z-20">
                <div>
                    <div class="pl-7 pt-7 thumbsSwiper" ref="thumbsSwiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide pb-0.5" v-if="product.data.attributes.video_id != null">
                                <img class="w-15 h-21 mt-2 cursor-pointer object-fill"
                                     :src="product.data.attributes.product_image_preview_video.data.attributes.path">
                            </div>
                            <div class="swiper-slide pb-0.5" v-for="(image, index) in product.data.attributes.images.data" @click="() => { scrollTo(index) }"
                                 :key=image.data.product_image_id>
                                <img class="w-15 h-21 mt-2 cursor-pointer object-fill" :src="image.data.attributes.path">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { Swiper } from "swiper";
import { Pagination } from 'swiper/modules';

Swiper.use([Pagination])

export default {
    name: "ProductImagesBlock",
    props: {
        product: null
    },
    data: () => ({
        swiper: null,
        thumbsSwiper: null,
        selectedSize: null,
        phoneNumber: null,
        isSizeSelectPopupVisible: false,
        isMoreInfoAboutDolyameShown: false,
        isSuccessPopupVisible: false,
        isSizeTablePopup: false,
        isInformAboutSizeSubscriptionVisible: false,
        limit: 4,
        isButtonMoreColorsOpened: true,
        isSizeSelectedByCustomer: false,
        isOpenStockBalancesTable: false,
        checkbox: true,
        options: {
            muted: true,
            autoplay: true,
            loop: true,
            controls: false,
            responsive: true,
            autopause: false
        },
        playerReady: true,
        phoneIsNotCorrect: false,
        amountOfImagesInThumbsSwiper: 0,
    }),
    setup() {
      return {
        modules: [Pagination],
      };
    },
    methods: {
        onReady() {
            this.playerReady = true
        },
        mute() {
            this.$refs.player.mute()
        },
        scrollTo(imgIndex) {
            this.$refs.swiper.scrollTo({ top: this.$refs.swiper.offsetHeight * imgIndex })
        }
    },
    computed: {
        thumbsSwiperSettings() {
            return {
                slidesPerView: this.amountOfImagesInThumbsSwiper,
                direction: 'vertical',
                freeMode: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
            }
        },
        swiperSettings() {
            return {
                direction: 'horizontal',
                mousewheel: true,
                freeMode: false,
                spaceBetween: 0,
                slidesPerView: 1.12,
                grabCursor: true,
                cssMode: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    // when window width is >= 1024px
                    1024: {
                        freeMode: true,
                        slidesPerView: 1,
                        direction: 'vertical',
                        spaceBetween: 0,
                    }
                },
                thumbs: {
                    swiper: this.thumbsSwiper,
                },
            }
        },
    },
    mounted() {
        if (this.product.data.attributes.video_id != null) {
            this.amountOfImagesInThumbsSwiper = this.product.data.attributes.images.data.length + 1
        } else {
            this.amountOfImagesInThumbsSwiper = this.product.data.attributes.images.data.length
        }
        this.thumbsSwiper = new Swiper(this.$refs.thumbsSwiper, this.thumbsSwiperSettings);
        this.swiper = new Swiper(this.$refs.swiper, this.swiperSettings);
    }
}
</script>

<style scoped>
.thumbsSwiper .swiper-slide-thumb-active {
    border-bottom: 1px solid;
    border-bottom-color: black;
}
.crossed {
    background: linear-gradient(to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgba(147, 147, 147, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%),
    linear-gradient(to top right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) calc(50% - 0.8px),
        rgba(147, 147, 147, 1) 50%,
        rgba(0, 0, 0, 0) calc(50% + 0.8px),
        rgba(0, 0, 0, 0) 100%);
}
.vimeo {
    height: 100%;
    width: 100%;
}
</style>
