<template>
    <div>
        <router-link :to="{name: 'Product', params: {category: product.links.meta.categorySlug, product:product.links.meta.productSlug}}">
            <span @click="sendSelectItemToGTM">
                <div class="relative">
                    <div v-if="product.data.attributes.tags != null"
                         :class="[product.data.attributes.full_screen == true ? 'lg:px-50 absolute bottom-0 mb-2 left-0 ml-1 mt-2 lg:top-0 z-10':'absolute bottom-0 mb-2 left-0 ml-1 mt-2 lg:top-0 z-10', product.data.attributes.video_id != null ? 'h-full w-full' : '']">
                        <div class="flex">
                            <ProductTag v-for="tag in product.data.attributes.tags.data"
                                        :key="tag.data.product_tag_id"
                                        :tag = tag
                            />
                        </div>
                    </div>

                    <!-- Проверяем тип блока-->
                    <div v-if=" blockType=='non-swipable-category-block'">
                        <!-- Проверяем на весь ли экран растянута карточка товара, да-выводим без свайпера, нет - карточка со свайпером -->
                        <div v-if="product.data.attributes.full_screen == true ">
                             <img class="lg:px-50"
                                  :src=product.data.attributes.image_for_full_screen.data.attributes.path >
                        </div>
                        <div v-else-if="product.data.attributes.video_id != null">
                            <div class="">
                               <vimeo-player class="vimeo" ref="player" :video-id="product.data.attributes.video_id" :video-url="{name: 'Product', params: {category: product.links.meta.categorySlug, product:product.links.meta.productSlug}}" @ready="onReady" :options="options" ></vimeo-player>
                            </div>
                        </div>
                        <div v-else>
                            <div class="swiper-container lg:hidden overflow-hidden"
                                 ref="swipableFullSizeBlockSwiper">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide"
                                         v-for="(image, index) in product.data.attributes.images.data.slice(0,3)"
                                         :key="image.data.product_image_id">
                                        <div>
                                            <div v-if="!isProductInAnyStock"
                                                 class="absolute top-0 h-full w-full bg-gray-200 bg-opacity-50"></div>
                                            <img :src="image.data.attributes.pathCatalogSize
                                              ? image.data.attributes.pathCatalogSize
                                              : image.data.attributes.path"  class="swiper-lazy">
                                            <div class="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="swiper-pagination" slot="pagination" ></div>-->
                            </div>
                            <!-- На десктопе отображаем при наведении след.фото -->
                            <div class="hidden lg:block">
                                <div>
                                    <div v-if="!isProductInAnyStock"
                                         class="absolute top-0 h-full w-full bg-gray-200 bg-opacity-50 "></div>
                                    <img @mouseover="changeImageOnHover"
                                         @mouseleave="changeImageOnLeave"
                                         :src=this.imageForDesktopMouseOn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                       <img class="2xl:w-full" :src="product.data.attributes.preview_image.data.attributes.pathCatalogSize
                       ? product.data.attributes.preview_image.data.attributes.pathCatalogSize
                       : product.data.attributes.preview_image.data.attributes.path" :alt=product.data.attributes.title>
                    </div>
                </div>
            </span>
        </router-link>
        <div v-if=" blockType=='non-swipable-category-block'"
             :class="[product.data.attributes.full_screen == true ? 'lg:px-50 mt-2 sm:mt-4 ':'mt-2 sm:mt-4 ']">
            <div v-if="!product.data.attributes.not_for_sale">
                <div v-if="!product.data.attributes.active_stock_balance" class="text-green-dark font-bold">
                    <span v-if="product.data.attributes.hasPreorderStockBalance">Предзаказ</span>
                    <span v-else-if="product.data.attributes.coming_soon">Скоро в продаже</span>
                    <span v-else-if="product.data.attributes.retail_stock_balance">Только в магазинах</span>
                    <span v-else>Нет в наличии</span>
                </div>
                <div v-else class="text-brown-dark font-bold">
                    <span v-if="product.data.attributes.not_for_sale === 1 && !product.data.attributes.isPreorder">Только в магазинах</span>
                    <span v-if="product.data.attributes.isPreorder">Предзаказ</span>
                </div>
            </div>

            <router-link :to="{name: 'Product', params: {category: product.links.meta.categorySlug, product:product.links.meta.productSlug}}">
                <span @click="sendSelectItemToGTM">
                    <div class="mt-1 text-gray-main text-xs sm:text-sm font-normal uppercase tracking-widest">
                        {{product.data.attributes.title}}
                    </div>
                </span>
            </router-link>
            <div class="">
                <div v-for="color in product.data.attributes.color_related_products.data" class="inline-block">
                    <div class="pr-2.5">
                        <div v-if="color.data.attributes.color.data.attributes.code" class="w-2.5 h-2.5 rounded-full" :style="'background-color: ' + color.data.attributes.color.data.attributes.code + ';'">
                        </div>
                        <div v-else-if="color.data.attributes.color.data.attributes.image" class="w-2.5 h-2.5 rounded-full overflow-hidden">
                            <img :src="color.data.attributes.color.links.color_image" alt="product color image" class="object-cover">
                        </div>
                        <div v-else class="w-2.5 h-2.5 rounded-full overflow-hidden">
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-gray-light text-xs sm:text-sm font-normal uppercase tracking-widest">
                <div v-if="product.data.attributes.discount != null">
                    {{product.data.attributes.cost - product.data.attributes.discount.data.attributes.amount | formatNumber}} ₽
                    <span class="pl-1 line-through">
                        {{product.data.attributes.cost | formatNumber}} ₽
                    </span>
                </div>
                <div v-else>
                    {{product.data.attributes.cost | formatNumber}} ₽
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductTag from "./ProductTag";
import {mapGetters} from "vuex";
import {Lazy, Pagination, Swiper} from "swiper";
Swiper.use([Pagination, Lazy]);


export default {
    name: "ProductItem",

    props: {
        product: null,
        gtmList: null,
        gtmPosition: null,
        blockType:null,
    },

    data: () => ({
        swiper: null,
        imageForDesktopMouseOn:null,
        options: {
            muted: true,
            autoplay: true,
            loop:true,
            controls:false,
            responsive: true,
            autopause:false
        },
        playerReady: true,
        //TODO выяснить по поводу formatNumber
        formatNumber: 'fn'
    }),
    mounted() {
        this.swiper = new Swiper(this.$refs.swipableFullSizeBlockSwiper, this.swiperSettings);
        this.imageForDesktopMouseOn = this.product.data.attributes.images.data[0].data.attributes.pathCatalogSize
            ? this.product.data.attributes.images.data[0].data.attributes.pathCatalogSize
            : this.product.data.attributes.images.data[0].data.attributes.path

    },
    computed: {

        swiperSettings() {
            return {
                pagination: {
                    el: '.swiper-pagination',
                },
                preloadImages:false,
                lazy: {
                    loadOnTransitionStart: false,
                    loadPrevNext: false,
                },
                watchSlidesProgress: true,
                watchSlidesVisibility: true,
            }
        },
        isProductInAnyStock() {
            return !!(this.product.data.attributes.active_stock_balance
                || this.product.data.attributes.coming_soon
                || this.product.data.attributes.retail_stock_balance
                || this.product.data.attributes.hasPreorderStockBalance);
        }

    },
    methods: {
        changeImageOnHover(){
            this.imageForDesktopMouseOn = this.product.data.attributes.images.data[1].data.attributes.pathCatalogSize
                ? this.product.data.attributes.images.data[1].data.attributes.pathCatalogSize
                : this.product.data.attributes.images.data[1].data.attributes.path
        },
        changeImageOnLeave(){
            this.imageForDesktopMouseOn = this.product.data.attributes.images.data[0].data.attributes.pathCatalogSize
                ? this.product.data.attributes.images.data[0].data.attributes.pathCatalogSize
                : this.product.data.attributes.images.data[0].data.attributes.path
        },
        sendSelectItemToGTM () {

            this.$gtm.trackEvent({
                event: 'flushVariables',
                ecommerce: null,
            });

            this.$gtm.trackEvent({
                event: 'select_item',
                ecommerce: {
                    click: {
                        actionField: {
                            list: this.gtmList
                        },
                        products: [
                            {
                                id: this.product.data.attributes.article_number,
                                name: this.product.data.attributes.title,
                                price: this.product.data.attributes.discount != null ? this.product.data.attributes.cost - this.product.data.attributes.discount.data.attributes.amount : this.product.data.attributes.cost,
                                category: this.product.data.attributes.category.data.attributes.title,
                                position: this.gtmPosition
                            }
                        ]
                    }
                }
            });
        },
        onReady() {
            this.playerReady = true
        },
        mute () {
            this.$refs.player.mute()
        }
    },

    components: {
        ProductTag
    },
}
</script>

<style scoped>
.swiper-pagination >>> .swiper-pagination-bullet-active {
    background-color: white;
}

.swiper-lazy-preloader-black >>> {
    margin-top: 50%;
}

.swiper-pagination  {
    position: absolute;
    width: 10px;
    height: 10px;
    text-align: center;
    padding-right: 30px;
    padding-bottom: 15px;
    transition: .3s opacity;
    transform: translate3d(0,0,0);
    z-index: 10;
}
.swiper-pagination >>> .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
}
.vimeo {
    height: 100%;
    width: 100%;
}

</style>
