<template>
    <div class="my-7 text-16">
        <span> 1. Электронный Подарочный сертификат Present &amp; Simple (далее &mdash; Сертификат) &mdash; документ, состоящий из электронного сообщения, содержащего уникальный ПИН-код, направленного на указанный Покупателем электронный адрес. Сертификат приобретается только на сайте https://presentandsimple.com и активируется и используется только при покупке товаров на сайте https://presentandsimple.com.</span>
        <span> 2. Активация Сертификата и / или использование Сертификата означает согласие Держателя с настоящими правилами. В течение всего срока действия Сертификат остаётся собственностью эмитента &mdash; ИП Иглакова Рината Эдвардовна. Приобретатель обязуется в случае передачи Сертификата третьему лицу (Держателю) уведомить Держателя о &laquo;Правилах продажи товаров с использованием Электронного Подарочного Сертификата &laquo;Present &amp; Simple&raquo;&raquo;.</span>
        <p><span>Средства, вносимые Покупателем, полностью зачисляются на счёт Сертификата. Номинал может быть следующего объёма:</span>
        </p>
        <p><span>5000 рублей</span></p>
        <p><span>10000 рублей</span></p>
        <p><span>15000 рублей</span></p>
        <p><span>20000 рублей</span></p>
        <p><span>30000 рублей</span></p>
        <p><span>40000 рублей</span></p>
        <p><span>50000 рублей</span></p>
        <p><span>100000 рублей</span></p>
        <p><span>и далее суммы, кратные 5000 рублей.</span></p>
        <ol>
            <li><span> 3. Сертификат можно приобрести на интернет-сайте: https://presentandsimple.com. Для приобретения сертификата Покупатель должен заполнить поля для ввода персональных данных, указанные на специальной странице сайта https://presentandsimple.com. Владельцем Сертификата может быть любое физическое лицо.</span>
            </li>
            <li><span> 4. Оплата Сертификата производится в момент его приобретения путём списания денежных средств с банковской карты</span>
            </li>
            <li><span> 5. Сертификат нельзя оплатить с использованием номинала подарочной карты и другого электронного подарочного сертификата Present &amp; Simple, а также бонусов программы лояльности.</span>
            </li>
            <li><span> 6. После оплаты Сертификата в указанное время и дату на оставленный Приобретателем электронный адрес приходит электронное сообщение в виде Сертификата. Сертификат содержит необходимую информацию: номинал, срок действия, номер заказа и ПИН-код. Использовать Сертификат можно при совершении покупок на сайте: оплачивать им как все сумму, так и ее часть.</span>
            </li>
            <li><span> 7. Неотъемлемой частью Сертификата является ПИН-код, числовая последовательность цифр и букв, указанная в электронном письме.</span>
            </li>
            <li><span> 8. Срок действия Сертификата ограничен и составляет один год со дня его активации.</span></li>
            <li><span> 9. Внесение денежных средств на Сертификат возможно только один раз в момент его приобретения. Сумма внесённых денежных средств Покупателем Сертификата соответствует номиналу Сертификата и является авансом, который засчитывается в счёт оплаты приобретаемых товаров. По истечению срока действия Сертификата аванс не возвращается.</span>
            </li>
            <li><span> 10.Сертификат не является именным, и любое физическое лицо, предъявившее Сертификат, может приобрести с его помощью товар на сайте https://presentandsimple.com.</span>
            </li>
            <li><span> 11. Сумма внесённого аванса, указанная на Сертификате, может быть использована Держателем при покупке Товаров однократно. В случае выбора товара на сумму меньше номинала Сертификата, остаток номинала сгорает. В том случае, если стоимость товара превышает номинал Сертификата, разница между стоимостью товара и номиналом Сертификата оплачивается с использованием платёжных банковских карт.</span>
            </li>
            <li><span> 12. Использование Сертификата возможно при покупке товаров только на интернет-сайте https://presentandsimple.com.</span>
            </li>
            <li><span> 13. При оплате выбранного товара с использованием Сертификата Держатель получает электронный чек, свидетельствующий о факте приобретения товара и использования всей части аванса. В том случае если производилась доплата банковской картой или посредством электронного кошелька, то на всю сумму приобретённого товара выдается единый электронный чек, содержащий информацию по операциям с использованием Сертификата и сумму доплаты банковской картой или посредством электронного кошелька.</span>
            </li>
            <li><span> 14. Сертификат не может быть использован для получения наличных денежных средств из кассы магазинов &laquo;Present &amp; Simple&raquo;.</span>
            </li>
            <li><span> 15. Гарантийное обслуживание, обмен и возврат некачественных товаров, приобретённых с использованием Сертификата, осуществляется в порядке, предусмотренном действующим законодательством РФ.</span>
            </li>
            <li><span> 16. ИП Иглакова Рината Эдвардовна не несёт ответственность за утрату Сертификата или его несанкционированное использование.</span>
            </li>
            <li><span> 17. Срок действия Сертификата: 12 месяцев с момента активации.</span></li>
        </ol>
    </div>
</template>

<script>


export default {
    name: "GiftCertificateUsingRulesDoc",
}

</script>

<style scoped>
</style>

