<template>
    <div>
        <DolyameProductCardPopup v-if="isMoreInfoAboutDolyameShown"
                                 @closePopup="closePopup" />
        <!-- Попап сообщить о поступлении товара -->
        <SizeSubscriptionPopup v-if="isInformAboutSizeSubscriptionVisible"
                               :product=" {
                               id: product.data.product_id,
                               articleNumber: product.data.attributes.article_number,
                               sizeId: product.size.data.id,
                           }"
                               @closePopup="closePopup" />
        <!-- Попап при успешном добавлении в корзину -->
        <SuccessAddToCartPopup v-if="isSuccessPopupVisible"
                               @closePopup="closePopup" />
        <!-- Попап при успешном добавлении в корзину preorder -->
        <SuccessAddToCartPreorderPopup v-if="isSuccessPreorderPopupVisible"
                                       @closePopup="closePopup" />

        <ProductIsAlreadyInPreorderCartPopup v-if="isProductAlreadyInPreorderCart"
                                             @closePopup="closePopup"
                                             @replaceProductInPreorderCart = 'replaceProductInPreorderCart(product)'
                                             :product="product" />
        <div class="">
            <div v-if="dolyameFirstPartToPay <= 50000 && productCost <= 200000" class="w-full z-40 lg:text-left">
                <div class="mt-2.5 text-11 text-gray-light-2 tracking-wider">
                    <div>
                        4 платежа по
                        <span>{{dolyameFirstPartToPay | formatNumber}} ₽.</span>
                        <span @click="showMoreInfoAboutDolyame" class="underlinedPositions cursor-pointer">Подробнее</span>
                    </div>
                </div>
            </div>

            <ProductColorInfoBlock :product="product"/>

            <div v-if="sizeAvailableToPreorder || hasProductPreorderSizesWithPreorderDetails" class="text-14 text-green-dark">
                На эту модель можно оформить предзаказ. <br> Ориентировочная дата отправки
                — с {{ preorderDateFrom  }} по {{ preorderDateTo }} включительно
                <br> Как только изделие поступит на склад, вы получите подтверждение с ссылкой для отслеживания посылки.
                Доставка по России для товаров, оформленных по предзаказу, бесплатная.
            </div>
            <div class="w-full mt-4">
                <ProductSizeInfoBlock :product="product"
                                      :isNonActiveButtonAddToCartClicked="isNonActiveButtonAddToCartClicked"
                                      @sizeIsSelected="sizeIsSelected"
                                      @selectSizeAction="selectSizeAction"
                                      />

                <button @click="addProductToCart(product)" v-if="isAvailableAddToCart"
                        class="w-full h-9 mt-2.5 bg-black-main text-14 font-normal text-white
                flex items-center justify-center focus:outline-none hover:bg-gray-main-3 lg:w-98">Добавить в корзину</button>

                <button @click="addProductToPreorderCart(product)" v-if="isAvailableAddRoPreorderCart"
                        class="w-full h-9 mt-2.5 bg-black-main text-14 font-normal text-white
                flex items-center justify-center focus:outline-none lg:w-98">Оформить предзаказ</button>

                <button @click="addToSizeSubscription" v-if="isAvailableSizeSubscription"
                        class="w-full h-9 mt-2.5 text-14 font-normal flex items-center justify-center focus:outline-none lg:w-98"
                        :class="isMakeSizeSubscriptionTheOnlyOperation
                ? 'border border-none bg-black-main text-white' : 'border border-black bg-white text-black'">Сообщить о поступлении</button>

                <div @click="openSizeSelection" v-if="isNoAvailableOperationsWithProduct">
                    <button v-if="this.product.data.attributes.hasPreorderStockBalance"
                            class="w-full h-9 mt-2.5 bg-black-main text-14 font-normal text-white
                flex items-center justify-center focus:outline-none lg:w-98">
                        <span>Оформить предзаказ</span>
                    </button>
                    <button v-else-if="this.product.data.attributes.active_stock_balance"
                            class="w-full h-9 mt-2.5 bg-black-main text-14 font-normal text-white
                flex items-center justify-center focus:outline-none lg:w-98">
                        <span>Добавить в корзину</span>
                    </button>
                    <button v-if="isMakeSizeSubscriptionTheOnlyOperationWhileSizeIsNotChecked
                  || this.product.data.attributes.hasPreorderStockBalance"
                            class="w-full h-9 mt-2.5 text-14 font-normal flex items-center justify-center focus:outline-none lg:w-98"
                            :class="isMakeSizeSubscriptionTheOnlyOperationWhileSizeIsNotChecked && !this.product.data.attributes.hasPreorderStockBalance
                  ? 'border border-none bg-black-main text-white' : 'border border-black bg-white text-black'">
                        <span>Сообщить о поступлении</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ProductColorInfoBlock from "./ProductColorInfoBlock.vue";
import ProductSizeInfoBlock from "./ProductSizeInfoBlock.vue";
import ProductInfoBlock from "./ProductInfoBlock.vue";
import DolyameProductCardPopup from "../DolyameProductCardPopup.vue";
import {mapActions, mapGetters} from "vuex";
import SizeSubscriptionPopup from "../popup/SizeSubscriptionPopup.vue";
import Popup from "../popup/Popup.vue";
import SuccessAddToCartPreorderPopup from "../popup/SuccessAddToCartPreorderPopup.vue";
import SuccessAddToCartPopup from "../popup/SuccessAddToCartPopup.vue";
import ProductIsAlreadyInPreorderCartPopup from "../popup/ProductIsAlreadyInPreorderCartPopup.vue";

export default {
    name: "ProductMainBlock",
    components: {
        //ProductInfoBlock,
        ProductIsAlreadyInPreorderCartPopup,
        SuccessAddToCartPopup,
        SuccessAddToCartPreorderPopup,
        //Popup,
        SizeSubscriptionPopup,
        DolyameProductCardPopup,
        ProductSizeInfoBlock,
        ProductColorInfoBlock
    },
    data: () => ({
        isMoreInfoAboutDolyameShown: false,
        isInformAboutSizeSubscriptionVisible: false,
        isNonActiveButtonAddToCartClicked: false,
        isSuccessPopupVisible: false,
        isProductAlreadyInPreorderCart: false,
        isSuccessPreorderPopupVisible: false,
    }),
    props: {
        product: null,
    },
    methods: {
        ...mapActions({
            addOrderLineToPreorderCart: 'addOrderLineToPreorderCart',
            addOrderLineToCart: 'addOrderLineToCart',
            setSubscribeItemStatus: 'setSubscribeItemStatus',
            replaceOrderLineInPreorderCart: 'replaceOrderLineInPreorderCart',
            checkHasPreorderCartOrderLine: 'checkHasPreorderCartOrderLine',
        }),
        openSizeSelection() {
            this.isNonActiveButtonAddToCartClicked = true
        },
        async addProductToPreorderCart(product) {
            await this.checkHasPreorderCartOrderLine()
            this.flushVariablesInGTM()
            this.preorderCartHasOrderLine ? this.isProductAlreadyInPreorderCart = true
                : this.addOrderLineToPreorderCart([product.data.product_id, product.size.data.size_id])
                    .then(() => {
                        this.isSuccessPreorderPopupVisible = true
                        this.addToCartActionGTM()
                        this.cartChangeMindboxGTM()
                        this.flushVariablesInGTM()
                        this.addToCartEcommerceGTM()
                    })
        },
        replaceProductInPreorderCart(product) {
            this.flushVariablesInGTM()
            this.replaceOrderLineInPreorderCart([product.data.product_id, product.size.data.size_id])
                .then(() => {
                    this.isProductAlreadyInPreorderCart = false
                    this.isSuccessPreorderPopupVisible = true
                    this.addToCartActionGTM()
                    this.cartChangeMindboxGTM()
                    this.flushVariablesInGTM()
                    this.addToCartEcommerceGTM()
                })
        },
        addProductToCart(product) {
            //TODO отключит UTM метки на dev стенде
            //this.flushVariablesInGTM()
            this.addOrderLineToCart([product.data.product_id, product.size.data.size_id])
                .then(res => {
                    this.isSuccessPopupVisible = true
                    //this.addToCartActionGTM()
                    //this.cartChangeMindboxGTM()
                    //this.flushVariablesInGTM()
                    //this.addToCartEcommerceGTM()
                })
        },
        addToSizeSubscription() {
            this.setSubscribeItemStatus(null)
            this.isInformAboutSizeSubscriptionVisible =true
        },
        showMoreInfoAboutDolyame() {
            this.isMoreInfoAboutDolyameShown = true
        },
        closePopup() {
            this.isMoreInfoAboutDolyameShown = false
            this.isInformAboutSizeSubscriptionVisible = false
            this.isSuccessPopupVisible = false
            this.isProductAlreadyInPreorderCart = false
            this.isSuccessPreorderPopupVisible = false
        },
        selectSizeAction(value) {
            this.product.size = value
        },
        sizeIsSelected(data) {
            this.isNonActiveButtonAddToCartClicked = data.isNonActiveButtonAddToCartClicked
        },
        flushVariablesInGTM() {
            this.$gtm.trackEvent({
                event: "flushVariables",
                ecommerce: null,
            });
        },
        addToCartActionGTM() {
            this.$gtm.trackEvent({
                event: "addToCart",
                productTitle: this.product.data.attributes.title,
                productCost: this.product.data.attributes.cost,
                productArticleNumber: this.product.data.attributes.article_number,
                productSize: this.product.size.data.attributes.title,
                noninteraction: false,
            });
        },
        addToCartEcommerceGTM() {
            this.$gtm.trackEvent({
                event: "add_to_cart",
                ecommerce: {
                    add: {
                        actionField: {
                            list: 'Карточка товара'
                        },
                        products: [{
                            name: this.product.data.attributes.title,
                            id: this.product.data.attributes.article_number,
                            price: this.product.data.attributes.discount != null ? this.product.data.attributes.cost - this.product.data.attributes.discount.data.attributes.amount : this.product.data.attributes.cost,
                            category: this.product.data.attributes.category.data.attributes.title,
                            quantity: 1,
                            variant: this.product.data.attributes.article_number + "" + this.product.size.data.size_id,
                        }]
                    }

                }
            });
        },
        cartChangeMindboxGTM() {
            this.$gtm.trackEvent({
                event: "cartChange",
                cartForMindbox: this.cartItemsForMindbox,
                noninteraction: false,
            });
        },
        makeCorrectDate(date) {
            return new Date(date).toLocaleDateString('ru').slice(0,5)
        },
    },
    computed: {
        ...mapGetters ({
            preorderCartHasOrderLine: 'preorderCartHasOrderLine',
            preorderCart: 'preorderCart',
            cart: 'cart'
        }),
        productCost() {
            return this.product.data.attributes.discount
                ? (this.product.data.attributes.cost - this.product.data.attributes.discount.data.attributes.amount)
                : this.product.data.attributes.cost
        },
        dolyameFirstPartToPay() {
            return Math.floor(this.productCost / 4)
        },
        isMakeSizeSubscriptionTheOnlyOperation() {
            return this.product.size && !this.product.size.data.relations.availableStocks.hasPreorderStock
                && !this.product.data.attributes.active_stock_balance
        },
        isMakeSizeSubscriptionTheOnlyOperationWhileSizeIsNotChecked() {
            return !this.product.data.attributes.active_stock_balance || !this.product.data.attributes.active_stock_balance
                && (this.product.data.attributes.coming_soon || this.product.data.attributes.retail_stock_balance)
        },
        isAvailableAddToCart() {
            return this.product.size && this.product.size.data.relations.availableStocks.operations.includes('add-to-cart')
        },
        isAvailableSizeSubscription() {
            return this.product.size && this.product.size.data.relations.availableStocks.operations.includes('subscribe')
        },
        isAvailableAddRoPreorderCart() {
            return this.product.size && this.product.size.data.relations.availableStocks.operations.includes('add-to-preorder-cart')
        },
        isNoAvailableOperationsWithProduct() {
            return !this.product.size
        },
        sizeAvailableToPreorder() {
            return !!(this.product.size && this.product.size.data.relations.availableStocks.preorderDetails
                && this.product.size.data.relations.availableStocks.hasPreorderStock
                && this.product.size.data.relations.availableStocks.preorderDetails.data)
        },
        hasProductPreorderSizesWithPreorderDetails() {
            return !this.product.size && this.product.data.attributes.hasPreorderStockBalance && !this.sizeAvailableToPreorder
                ? this.product.data.attributes.sizes.data.find(item => item.data.relations.availableStocks.hasPreorderStock)
                : null
        },
        preorderDateFrom() {
            return this.sizeAvailableToPreorder
                ? this.makeCorrectDate(this.product.size.data.relations.availableStocks.preorderDetails.data.preOrderDeliveryDateFrom)
                : this.hasProductPreorderSizesWithPreorderDetails
                    ? this.makeCorrectDate(this.hasProductPreorderSizesWithPreorderDetails.data.relations.availableStocks.preorderDetails.data.preOrderDeliveryDateFrom)
                    : null
        },
        preorderDateTo() {
            return this.sizeAvailableToPreorder
                ? this.makeCorrectDate(this.product.size.data.relations.availableStocks.preorderDetails.data.preOrderDeliveryDateTo)
                : this.hasProductPreorderSizesWithPreorderDetails
                    ? this.makeCorrectDate(this.hasProductPreorderSizesWithPreorderDetails.data.relations.availableStocks.preorderDetails.data.preOrderDeliveryDateTo)
                    : null
        },
        cartItemsForMindbox() {
            let result = [];
            if (this.cart.data.relationships.orderLines.data.length) {
                for (let item of this.cart.data.relationships.orderLines.data) {
                    result.push(
                        {
                            "product": {
                                "ids": {
                                    "website": item.relationships.product.data.attributes.article_number + ""
                                        + item.attributes.sizeId,
                                }
                            },
                            "count": item.attributes.orderQuantity,
                            "priceOfLine": item.attributes.priceToPay
                        }
                    );

                }
            }
            if (this.preorderCart.data.relationships.orderLines.data.length) {
                for (let item of this.preorderCart.data.relationships.orderLines.data) {
                    result.push(
                        {
                            "product": {
                                "ids": {
                                    "website": item.relationships.product.data.attributes.article_number + ""
                                        + item.attributes.sizeId,
                                }
                            },
                            "count": item.attributes.orderQuantity,
                            "priceOfLine": item.attributes.priceToPay
                        }
                    );

                }
            }
            return result;
        },
    }

}

</script>

<style scoped>
.underlinedPositions {
    text-decoration: underline;
    text-underline-offset: 3px;
}
</style>
