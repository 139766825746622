import axios from '@/api';


const state = {
    announcementBar: null,
    announcementBarStatus: 'loading',
};

const getters = {
    announcementBar: state => {
        return state.announcementBar;
    },
    announcementBarStatus: state => {
        return state.announcementBarStatus;
    },
};

const actions = {
    fetchAnnouncementBar({commit, dispatch}, announcementType) {
        commit('setAnnouncementBarStatus', 'loading')

        axios.get('/api/announcement-bar/'+ announcementType)
            .then(res => {
                commit('setAnnouncementBar', res.data)
                commit('setAnnouncementBarStatus', 'success')
            })
            .catch(error => {
                commit('setAnnouncementBarStatus', 'error')
            });
    },
};
const mutations = {
    setAnnouncementBar(state, announcementBar) {
        state.announcementBar = announcementBar;
    },
    setAnnouncementBarStatus(state, status) {
        state.announcementBarStatus = status;
    }
};

export default {
    state, getters, actions, mutations,
}
