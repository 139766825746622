<template>
    <div>
        <div v-if="order" class="w-full p-6 lg:w-256 lg:mx-auto">
            <div>Заказ <span> {{order.data.order_id}} </span> успешно создан.
                В течение пары секунд вы будете перемещены на страницу оплаты.
            </div>
            <div>Спасибо!</div>
        </div>
        <div v-else class="w-full p-6 lg:w-256 lg:mx-auto">
            <div>Предзаказ <span> {{preorder.data.order_id}} </span> успешно создан.
                В течение пары секунд вы будете перемещены на страницу оплаты.
            </div>
            <div>Спасибо!</div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'OrderCreatedSuccessfullyPage',
    computed: {
        ...mapGetters ({
            order: 'order',
            preorder: 'preorder'
        }),
    },
    mounted() {
        this.redirectTo = this.order ?
            setTimeout(() => window.location.href = this.order.data.attributes.invoice_url, 3000)
            : this.preorder ? setTimeout(() => window.location.href = this.preorder.data.attributes.invoice_url, 30000)
                : null

        this.$gtm.trackEvent({
            event: 'flushVariables',
            ecommerce: null,
        });
        this.$gtm.trackEvent({
            event: 'payed_success',
        });
    }
}
</script>
<style scoped>

</style>



