<template>
    <div>

    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { required, numeric, maxLength, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
    name: "Test",
    data: () => ({
        phone: null,
        way: "phone",
        passwordEmail: null,
        code: null,
        hasCodeSent: false,
        isButtonActive: false,
        authWayStatus: null,
        authWayErrorMessage: null,
        timeLeft: 0,
        loginErrorMessage: null,
    }),
    props: {},
    setup () { return { v$: useVuelidate() } },
    validations() {
        return {
            phone: {
                required,
                regex: (value) =>
                    value
                        ? /(^\+7[0-7,9]\d{9}$)|(^\+([0-6]|[8-9])\d{6,14}$)/i.test(
                            value,
                        )
                        : true,
            },
            inputValue: {
                required,
                numeric,
                minLength: minLength(4),
                maxLength: maxLength(4),
            },
        };
    },
    methods: {
        ...mapActions({
            initPhoneNumber: "initPhoneNumber",
            signIn: "signIn",
            getAuthStatus: "getAuthStatus",
            initVerificationPhoneNumber: "initVerixficationPhoneNumber",
            verifyPhone: "verifyPhone",
        }),
        telValidate(telnumber, telnumberObject) {
            if (telnumberObject.valid || telnumber.length < 1) {
                this.phone = telnumberObject.number;
                this.phoneIsNotCorrect = false;
            } else {
                this.phone = "";
                this.phoneIsNotCorrect = true;
            }
        },
        startTimer(duration) {
            this.isButtonActive = true;
            this.timeLeft = duration;
            const endTime = new Date(new Date().getTime() + duration * 1000);
            localStorage.setItem("endTime", endTime);

            const timer = setInterval(() => {
                this.timeLeft--;
                if (this.timeLeft <= 0) {
                    clearInterval(timer);
                    this.isButtonActive = false;
                    localStorage.removeItem("endTime");
                }
            }, 1000);
        },
        getCode() {
            this.authWayErrorMessage = null;
            this.loginErrorMessage = null;
            if (
                this.authInfo?.relations.authUser.data.attributes
                    .isPhoneVerified === false
            ) {
                this.initVerificationPhoneNumber(this.phone).then((res) => {
                    this.hasCodeSent = true;
                });
            } else {
                this.v$.$touch();
                if (!this.v$.phone.$invalid) {
                    this.initPhoneNumber(this.phone).then((res) => {
                        this.hasCodeSent = true;
                        this.startTimer(30);
                    });
                }
            }
        },
        authorize() {
            if (
                this.authInfo?.relations.authUser.data.attributes
                    .isPhoneVerified === false
            ) {
                this.verifyPhone({
                    phone: this.phone,
                    code: this.code,
                }).then((res) => {
                    this.isButtonDisabled = false;
                });
            } else {
                this.signIn({
                    phone: this.phone,
                    code: this.code,
                })
                    .then((res) => {
                        this.isButtonDisabled = false;
                    })
                    .catch(() => {
                        this.loginErrorMessage = "Введен неверный код";
                    });
            }
        },
    },
    computed: {
        ...mapGetters({
            initCodeForPhoneNumber: "initCodeForPhoneNumber",
            authStatus: "authStatus",
            authInfo: "authInfo",
        }),
        isUserAuthorized() {
            return (
                this.authInfo?.authStatus && this.authInfo?.relations.authUser
            );
        },
        isUserPhoneVerified() {
            return this.authInfo?.relations.authUser.data.attributes
                .isPhoneVerified;
        },
        styleClasses() {
            if (this.v$.phone.$error) {
                return 'vue-tel-input-error appearance-none rounded-none w-full h-10 pl-4 mt-3 text-body-18 font-normal ' +
                    ' text-11 text-gray-light  w-full h-9 focus:border px-2\n' +
                    '                          focus:border-red lg:text-13'
            } else {
                return 'appearance-none rounded-none w-full h-10 pl-4 mt-3 text-body-18 font-normal' +
                    ' text-11 text-gray-light w-full h-9 focus:border px-2\n' +
                    '                          focus:border-black lg:text-13'
            }
        },
    },
    mounted() {
        const endTime = localStorage.getItem("endTime");
        if (endTime) {
            const remainingTime = Math.floor(
                (new Date(endTime) - new Date()) / 1000,
            );
            if (remainingTime > 0) {
                this.startTimer(remainingTime);
            }
        }
    },
};
</script>

<style scoped></style>
