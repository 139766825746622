import axios from "@/api";

const state = {
    giftCertificateOrder: JSON.parse(localStorage.getItem('GiftCertificateOrderStore')) || null,
};

const getters = {
    giftCertificateOrder: state => {
        return state.giftCertificateOrder;
    },
};

const actions = {
    createGiftCertificateOrder({commit, state}, {
        recipientIsSender, recipientName, recipientEmail, messageToRecipient,
        sendDatetime, amount, senderEmail, senderName
    }) {
        return new Promise((resolve, reject) => {
            axios.post('/api/gift-certificate-orders', {
                "recipientIsSender": recipientIsSender,
                "recipientName": recipientName,
                "recipientEmail": recipientEmail,
                "messageToRecipient": messageToRecipient,
                "sendDatetime": sendDatetime,
                "amount": amount,
                "senderEmail": senderEmail,
                "senderName": senderName
            })
                .then(res => {
                    commit('SET_GIFT_CERTIFICATE_ORDER', res.data);
                    resolve(res)
                })
                .catch(error => {
                    reject(error);
                })
        });
    },

    flushGiftCertificateOrder({commit}) {
        commit('FLUSH_GIFT_CERTIFICATE_ORDER')
    }
};

const mutations = {
    SET_GIFT_CERTIFICATE_ORDER(state, order) {
        state.giftCertificateOrder = order;
        localStorage.setItem("GiftCertificateOrderStore", JSON.stringify(state.giftCertificateOrder))
    },

    FLUSH_GIFT_CERTIFICATE_ORDER: () => {
        localStorage.removeItem("GiftCertificateOrderStore")
    },

};

export default {
    state, getters, actions, mutations
}
