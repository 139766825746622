import axios from '@/api';

const state = {
    order: JSON.parse(localStorage.getItem('OrderStore')) || null,
    preorder: JSON.parse(localStorage.getItem('PreorderOrderStore')) || null,
    clientComment: null,

};

const getters = {
    clientComment: state => {
        return state.clientComment;
    },
    order: state => {
        return state.order;
    },
    preorder: state => {
        return state.preorder;
    },
    orderStatus: state => {
        return state.orderStatus;
    },
    preorderStatus: state => {
        return state.preorderStatus;
    },


};

const actions = {
    commentOrder({commit, state}, comment){
        commit('setClientComment', comment);
    },

    createOrder({commit, state}) {
        return new Promise((resolve, reject) => {
            axios.post('/api/order')
                .then(res => {
                    commit('setOrder', res.data);
                    resolve(res)
                })
                .catch(error => {
                    reject(error);
                })
        });
    },
    createPreorder({commit, state}) {
        return new Promise((resolve, reject) => {
            axios.post('/api/preorder')
                .then(res => {
                    commit('setPreorder', res.data);
                    resolve(res)
                })
                .catch(error => {
                    reject(error);
                })
        });
    },

    flushOrder({commit}) {
        commit('FLUSH_ORDER')
    },
    flushPreorder({commit}) {
        commit('FLUSH_PREORDER')
    }



};

const mutations = {
    setClientComment(state, comment) {
        state.clientComment = comment ;
    },
    setOrder(state, order) {
        state.order = order ;
        localStorage.setItem("OrderStore", JSON.stringify(state.order))
    },
    setPreorder(state, preorder) {
        state.preorder = preorder ;
        localStorage.setItem("PreorderStore", JSON.stringify(state.preorder))
    },

    FLUSH_ORDER: (state, index) => {
        localStorage.removeItem("OrderStore")
    },
    FLUSH_PREORDER: (state, index) => {
        localStorage.removeItem("PreorderStore")
    },

};

export default {
    state, getters, actions, mutations
}
