<template>
    <div class="w-full">
        <!--User-->
        <div v-if="isChangeUserDataActive === false">
            <div class="w-full pb-5 border-b border-gray-lighter">
                <div class="flex items-center justify-between">
                    <div class="uppercase text-13 text-black text-left flex-1 lg:text-16">
                        Получатель
                    </div>
                    <div @click="changeUserData" v-if="this.authenticated === false && this.cart?.data.attributes.hasUserData">
                        <ChangeSvgButton />
                    </div>
                </div>
                <div>
                    <div v-if="authInfo.authStatus && authInfo.identificationStatus"
                         class="text-11 text-gray-main lg:text-13">
                        <div>{{ authInfo.relations.authUser?.data.attributes.name }}
                            {{ authInfo.relations.authUser?.data.attributes.surname }}
                        </div>
                        <div>
                            {{ getUserEmailInfo }}
                        </div>
                        <div>{{ authInfo.relations.authUser?.data.attributes.phone }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <CartUserBlock :cart="cart"
                           :userWantsToUseNewPhoneNumberToAuth='userWantsToUseNewPhoneNumberToAuth'
                           @sendUserInfo='onUser'></CartUserBlock>
        </div>
        <!--Delivery-->
        <div v-if="this.isChangeDeliveryMethodActive === false">
            <div class="w-full py-5 border-b border-gray-lighter">
                <div @click="changeDeliveryMethod" class="flex items-center justify-between">
                    <div class="uppercase text-13 text-black text-left flex-1 lg:text-16">
                        Доставка
                    </div>
                    <ChangeSvgButton/>
                </div>
                <div v-if="this.cart?.data.attributes.hasDeliveryMethod">
                    <div class="text-11 text-gray-main lg:text-13">
                        <div>
                            {{
                                cart.data.relationships.cartDeliveryMethod.data.relationships.deliveryType.data.attributes.title
                            }}
                        </div>
                        <div>{{ cart.data.relationships.cartDeliveryMethod.data.attributes.country }}
                            {{ cart.data.relationships.cartDeliveryMethod.data.attributes.city }}
                        </div>
                        <div v-if="cart.data.relationships.cartDeliveryMethod.data.attributes.street !== null">
                            {{ cart.data.relationships.cartDeliveryMethod.data.attributes.street }}
                            {{ cart.data.relationships.cartDeliveryMethod.data.attributes.house }}
                            {{ cart.data.relationships.cartDeliveryMethod.data.attributes.building }},
                            {{ cart.data.relationships.cartDeliveryMethod.data.attributes.apartment }}
                        </div>
                        <div v-if="cart.data.relationships.cartDeliveryMethod.data.attributes.comment !== null">
                            {{ cart.data.relationships.cartDeliveryMethod.data.attributes.comment }}
                        </div>
                    </div>
                </div>
                <div v-else class="text-gray-main text-13">
                    Выберите способ доставки
                </div>
            </div>
        </div>
        <div v-else>
            <DeliveryCartBlock :cart="cart" @sendDeliveryInfo='onDelivery'
                               @getDeliveryTypes="onDeliveryTypes"></DeliveryCartBlock>
        </div>
        <!--Payment-->
        <div v-if="this.isChangePaymentMethodActive === false">
            <div class="w-full py-5">
                <div @click="changePaymentMethod" class="flex items-center">
                    <div class="uppercase text-13 text-black text-left flex-1 lg:text-16">
                        Оплата
                    </div>
                    <ChangeSvgButton/>
                </div>
                <div v-if="cart?.data.relationships.cartPaymentMethod !== null">
                    <div class="text-11 text-gray-main lg:text-13">
                        <div class="mt-2">
                            {{
                                this.cart.data.relationships.cartPaymentMethod.data.relationships.paymentType.data.attributes.title
                            }}
                        </div>
                    </div>
                </div>
                <div v-else class="text-gray-main text-13">
                    Выберите способ оплаты
                </div>
            </div>
        </div>
        <div v-else>
            <CartPaymentBlock :cart="cart" @sendPaymentInfo='onPayment'
                              @getPaymentTypes="onPaymentTypes"></CartPaymentBlock>
        </div>
    </div>
</template>

<script>
import CartUserBlock from "./CartUserBlock.vue";
import CartPaymentBlock from "./CartPaymentBlock.vue";
import DeliveryCartBlock from "../Cart/DeliveryCartBlock.vue";
import {mapActions, mapGetters} from "vuex";
import ChangeSvgButton from "./ChangeSvgButton.vue";

export default {
    name: "CartCheckoutUserDeliveryPaymentInfo",
    components: {ChangeSvgButton, DeliveryCartBlock, CartPaymentBlock, CartUserBlock},
    props: {
        cart: null,
        userWantsToUseNewPhoneNumberToAuth: null
    },
    data: () => ({
        submitted: false,
        isChangeUserDataActive: false,
        isChangeDeliveryMethodActive: false,
        isChangePaymentMethodActive: false,
    }),
    computed: {
        ...mapGetters({
            authenticated: 'authenticated',
            authStatus: 'authStatus',
            authInfo: 'authInfo',
            emailError: 'error',
        }),
        getUserEmailInfo() {
            return this.authInfo?.relations?.authUser?.data?.relations?.candidateMarketingEmail
                ? this.authInfo?.relations?.authUser?.data?.relations?.candidateMarketingEmail?.data?.attributes?.email
                : this.authInfo?.relations?.authUser?.data?.relations?.currentMarketingEmail?.data?.attributes?.email
        }
    },
    methods: {
        ...mapActions({
            getCart: 'getCart',
            userDataVuex: 'userData',
            deleteOrderLineFromCart: 'deleteOrderLineFromCart',
            changeOrderLineSizeInCartVuex: 'changeOrderLineSizeInCart',
            incrementCartOrderLineItem: 'incrementCartOrderLineItem',
            decrementCartOrderLineItem: 'decrementCartOrderLineItem',
            createOrderVuex: 'createOrder',
            deletePaymentTypeVuex: 'deletePaymentType',
            deliveryTypeVuex: 'setDeliveryType',
            fetchDeliveryTypes: 'fetchDeliveryTypes',
            getPaymentTypes: 'getPaymentTypes',
            getAuthStatus: 'getAuthStatus',
        }),
        changeUserData() {
            this.isChangeUserDataActive = true
            this.isChangeDeliveryMethodActive = false
            this.isChangePaymentMethodActive = false
        },
        changeDeliveryMethod(){
            if(this.cart?.data?.relationships?.userData?.data?.attributes?.hasBaseAttributes) {
                this.isChangeDeliveryMethodActive = true
                this.isChangeUserDataActive = false
                this.isChangePaymentMethodActive = false
            }
        },
        changePaymentMethod(){
            if(this.cart?.data.attributes.hasDeliveryMethod) {
                this.deletePaymentTypeVuex()
                this.isChangePaymentMethodActive = true
                this.isChangeUserDataActive = false
                this.isChangeDeliveryMethodActive = false
            }
        },
        async onUser(data) {
            await this.userDataVuex({
                name: data.name,
                surname: data.surname,
                email: data.email,
            })
            await this.getAuthStatus()
            if (data.isChangeUserDataActive === false && !this.emailError) {
                this.isChangeUserDataActive = false
                if (data.isChangeDeliveryMethodActive === true) {
                    this.isChangeDeliveryMethodActive = true
                }
            }
        },
        onDeliveryTypes(data) {
            this.fetchDeliveryTypes({
                iso: data.iso,
                country: data.country,
                reg: data.reg,
                city: data.city,
            })
        },
        onDelivery(data) {
            this.deliveryTypeVuex({
                deliveryRegionDetailId: data.deliveryRegionDetailId,
                country: data.country,
                countryCode: data.countryCode,
                city: data.city,
                street: data.street,
                house: data.house,
                building: data.building,
                postalCode: data.postalCode,
                region: data.region,
                apartment: data.apartment,
                comment: data.comment,
                pickUpPoint: data.pickUpPoint,
                pickUpPointId: data.pickUpPointId,
                deliveryDate: data.deliveryDate,
                deliveryTimeInterval: data.deliveryTimeInterval,
            })

            if (data.isChangeDeliveryMethodActive === false) {
                this.isChangeDeliveryMethodActive = false
                if (data.isChangePaymentMethodActive === true) {
                    this.isChangePaymentMethodActive = true
                }
            }
        },
        onPayment(data) {
            if (data.isChangePaymentMethodActive === false) {
                this.isChangePaymentMethodActive = false
            }
        },
        onPaymentTypes(data) {
            this.getPaymentTypes()
        }

    },
    mounted() {
        this.$store.dispatch('getAuthStatus')
        //В зависимости от флажков отображаем/скрываем поля для редактирования пользователя/доставки/оплаты
        if (!this.authInfo?.authStatus && !this.cart?.data.attributes.hasDeliveryMethod && !this.cart?.data.attributes.hasPaymentMethod) {
            this.isChangeUserDataActive = false
            this.isChangeDeliveryMethodActive = false
            this.isChangePaymentMethodActive = false
        } else if (this.authInfo?.authStatus && !this.cart?.data.attributes.hasDeliveryMethod && this.cart?.data.attributes.hasPaymentMethod) {
            this.isChangeUserDataActive = false
            this.isChangeDeliveryMethodActive = true
            this.isChangePaymentMethodActive = false
        } else if (this.authInfo?.authStatus && !this.cart?.data.attributes.hasDeliveryMethod && !this.cart?.data.attributes.hasPaymentMethod) {
            this.isChangeUserDataActive = false
            this.isChangeDeliveryMethodActive = true
            this.isChangePaymentMethodActive = false
        } else if (this.authInfo?.authStatus && this.cart?.data.attributes.hasDeliveryMethod && this.cart?.data.attributes.hasPaymentMethod) {
            this.isChangeUserDataActive = false
            this.isChangeDeliveryMethodActive = false
            this.isChangePaymentMethodActive = false
        } else {
            this.isChangeUserDataActive = false
            this.isChangeDeliveryMethodActive = false
            this.isChangePaymentMethodActive = true
        }

        if (!this.authInfo?.relations?.authUser?.data?.attributes?.email
            || !this.authInfo?.relations?.authUser?.data?.attributes?.surname
            || !this.authInfo?.relations?.authUser?.data?.attributes?.name) {
            this.isChangeUserDataActive = true
            this.isChangeDeliveryMethodActive = false
            this.isChangePaymentMethodActive = false
        }
    },
}
</script>

<style scoped>

</style>
