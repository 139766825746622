<template>
    <div>
        <div class="text-black">
            <div class="w-full">
                <div class="flex justify-between">
                    <div class="uppercase text-13 text-black text-left flex-1 lg:text-16">
                        Получатель
                    </div>
                    <div v-if="this.cart.data.attributes.hasUserData === true" class="flex items-center">
                        <button @click="closeForm" class="lg:hidden">
                            <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 1L1 4" stroke="black" stroke-linecap="square"/>
                                <path d="M7 3.99976L4 0.999756" stroke="black" stroke-linecap="square"/>
                            </svg>
                        </button>
                        <button @click="closeForm" class="hidden lg:block">
                            <svg width="11" height="7" viewBox="0 0 11 7" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 1.00049L1 5.50049" stroke="black" stroke-linecap="square"/>
                                <path d="M10 5.50024L5.5 1.00024" stroke="black" stroke-linecap="square"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <AuthByPhone v-if="!authInfo.authStatus
                             || !authInfo?.relations.authUser?.data.attributes.isPhoneVerified"
                             @authByPhoneSuccessClosePopup="userSuccessfullyLogin"/>
                <div v-else class="mt-10">
                    <div class="my-4 lg:my-2 flex items-center">
                        <div class="w-26 uppercase text-11 lg:text-13">Имя</div>
                        <div class="w-full ml-7 h-9 mr-20 lg:h-11">
                            <input
                                class="appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13"
                                v-model="name"
                                type="text"
                                name="name"
                                :class="[submitted && v$.name.$invalid ? 'border-red-400' : 'border-gray-lighter']">
                            <div v-if="submitted && !v$.name.required" class="mb-2 text-11 text-red-light ml-1">
                                Заполните имя
                            </div>
                        </div>
                    </div>
                    <div class="my-4 lg:my-2 flex items-center">
                        <div class="w-26 uppercase text-11 lg:text-13">Фамилия</div>
                        <div class="w-full ml-7 h-9 mr-20 lg:h-11">
                            <input
                                class="appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2 focus:outline-none focus:border-black lg:text-13"
                                v-model="surname"
                                type="text"
                                name="surname"
                                :class="[ submitted && v$.surname.$invalid ? 'border-red-400' : 'border-gray-lighter'] ">
                            <div v-if="submitted && !v$.surname.required" class="text-red-light text-11 ml-1">Заполните
                                фамилию
                            </div>
                        </div>
                    </div>
                    <div class="my-4 lg:my-2 flex items-center">
                        <div class="w-26 uppercase text-11 lg:text-13">Телефон</div>
                        <div class="w-full ml-7 h-9 mr-20 lg:h-11">
                            <input class="appearance-none text-11 text-gray-light rounded-none w-full h-9 border px-2
                            focus:outline-none focus:border-black lg:text-13" readonly
                                   v-model="phone"
                            >
                        </div>
                    </div>
                    <div class="my-4 lg:my-2 flex items-center">
                        <div class="w-26 uppercase text-11 lg:text-13">E-mail</div>
                        <div class="w-full ml-7 h-9 mr-20 lg:h-11">
                            <input
                                class="appearance-none text-11 text-gray-light rounded-none w-full h-9
                                border px-2 focus:outline-none focus:border-black lg:text-13"
                                v-model="email"
                                type="email"
                                name="email"
                                @focus="resetEmailError"
                                :class="[submitted && v$.email.$invalid ? 'border-red-400' : 'border-gray-lighter']">
                            <div v-if="submitted && v$.email.$invalid" class="  text-11   text-red-light ml-1">
                                <span v-if="!v$.email.required">Заполните email</span>
                                <span v-if="!v$.email.email">Неверный email</span>
                            </div>
                            <div v-if="emailError?.length && email.length"
                                  class="text-11 text-red-light ml-1">
                                {{ 'Данный email уже используется другим аккаунтом. Попробуйте другой' }}</div>
                        </div>
                    </div>
                    <div class="mt-9 mb-7">
                        <button @click="sendUserInfo(name,surname,email)"
                                class="w-full uppercase h-9 py-1 px-5 border border-black bg-black text-white text-11 r focus:outline-none lg:text-11 lg:text-13">
                            Далее
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { email, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { mapActions, mapGetters } from "vuex"
import AuthByPhone from "../Auth/AuthByPhone.vue"

export default {
    name: "CartUserBlock",
    components: {AuthByPhone},
    props: {
        cart: null,
        isChangeUserDataActive: null,
        isChangeDeliveryMethodActive: null,
        type: null,
    },
    data: () => ({
        submitted: false,
        userPhoneIsNotCorrect: false,
    }),
    setup () { return { v$: useVuelidate() } },
    validations: {
        name: {required},
        email: {required, email},
        surname: {required}
    },
    methods: {
        ...mapActions({
            userDataVuex: 'userData',
            flushDeliverySuggestions: 'flushDeliverySuggestions',
            setEmail: 'setEmail',
            setName: 'setName',
            setSurname: 'setSurname',
            setPhone: 'setPhone',
            getAuthStatus: 'getAuthStatus',
            resetCartUserDataPhoneStatus: 'resetCartUserDataPhoneStatus',
            authIdentifiedAndUnauthorizedUserByNewPhone: 'authIdentifiedAndUnauthorizedUserByNewPhone',
        }),
        resetEmailError() {
            this.$store.commit('setError', null)
        },
        async getUserAuthData() {
            await this.$store.dispatch('getAuthStatus')
            this.user.email = this.authInfo?.relations?.authUser?.data?.relations?.candidateMarketingEmail
                ? this.authInfo?.relations?.authUser?.data?.relations?.candidateMarketingEmail?.data?.attributes?.email
                : this.authInfo?.relations?.authUser?.data?.relations?.currentMarketingEmail?.data?.attributes?.email
            this.user.name = this.authInfo?.relations?.authUser?.data?.attributes?.name
            this.user.surname = this.authInfo?.relations?.authUser?.data?.attributes?.surname
            this.user.phone = this.authInfo?.relations?.authUser?.data?.attributes?.phone
        },
        closeForm() {
            this.$emit('sendUserInfo', {
                isChangeUserDataActive: false,
            })
        },
        userSuccessfullyLogin() {
            this.getUserAuthData()
        },
        telValidate(telnumber, telnumberObject) {
            if (telnumberObject.valid || telnumber.length < 1) {
                this.phone = telnumberObject.number
                this.userPhoneIsNotCorrect = false
                this.resetCartUserDataPhoneStatus()
            } else {
                this.phone = ''
                this.userPhoneIsNotCorrect = true
            }
        },
        changeUserData() {
            this.isChangeUserDataActive = true
        },
        sendUserInfo(name, surname, email) {
            this.submitted = true;
            this.v$.$touch();
            if (!this.v$.$invalid) {
                this.$emit('sendUserInfo', {
                    isChangeUserDataActive: false,
                    isChangeDeliveryMethodActive: true,
                    name,
                    surname,
                    email,
                })
            }
            this.getAuthStatus()
        },
    },
    computed: {
        ...mapGetters({
            userEmail: 'userEmail',
            userPhone: 'userPhone',
            userName: 'userName',
            userSurname: 'userSurname',
            user: 'user',

            authStatus: 'authStatus',
            authInfo: 'authInfo',
            emailError: 'error',
        }),
        email: {
            get() {
                return this.user.email
            },
            set: _.debounce(function (email) {
                this.setEmail(email);
            }, 300),
        },
        phone: {
            get() {
                return this.authInfo?.relations.authUser?.data.attributes.phone;
            },
            set(phone) {
                this.setPhone(phone);
            },
        },
        name: {
            get() {
                return this.user.name;
            },
            set: _.debounce(function (name) {
                this.setName(name);
            }, 300),
        },
        surname: {
            get() {
                return this.user.surname;
            },
            set: _.debounce(function (surname) {
                this.setSurname(surname);
            }, 300),
        },
    },
    mounted() {
        this.getUserAuthData()
    }
}
</script>

<style scoped>

</style>
