<template>
    <div class="overflow-hidden scroll-down">
        <div v-if="product.data.attributes.active === 0">
            <ProductNotActiveCard/>
        </div>
        <div v-else class="overflow-hidden lg:flex">
            <div class="lg:w-1/2">
                <ProductImagesBlock :product="product"/>
            </div>
            <div class="w-full px-5 lg:w-1/2 lg:px-15 font-normal">
                <div class="w-full lg:w-98 lg:mx-auto">
                    <ProductInfoBlock :product="product"/>
                    <div v-if="!product.data.attributes.not_for_sale">
                        <ProductMainBlock :product="product"/>
                        <ProductSizeTableBlock :product="product"/>
                    </div>
                    <div class="my-4 w-full">
                    <span class="text-black text-left text-14">
                        Артикул: {{ product.data.attributes.article_number }}
                    </span>
                    </div>
                    <div v-if="!product.data.attributes.not_for_sale && !product.data.attributes.coming_soon"
                         class="mb-7 mt-3">
                        <ProductAvailabilityInRetailStocks :product="product"/>
                    </div>
                    <ProductDetailsBlock :product="product"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductNotActiveCard from "./ProductNotActiveCard.vue";
import ProductMainBlock from "./product/ProductMainBlock.vue";
import ProductImagesBlock from "./product/ProductImagesBlock.vue";
import ProductDetailsBlock from "./product/ProductDetailsBlock.vue";
import ProductAvailabilityInRetailStocks from "./product/ProductAvailabilityInRetailStocks.vue";
import ProductSizeTableBlock from "./product/ProductSizeTableBlock.vue";
import ProductInfoBlock from "./product/ProductInfoBlock.vue";


export default {
    name: "ProductCard",
    components: {
        ProductInfoBlock,
        ProductSizeTableBlock,
        ProductAvailabilityInRetailStocks,
        ProductDetailsBlock,
        ProductImagesBlock,
        ProductMainBlock,
        ProductNotActiveCard,
    },
    props: {
        product: null,
    },
    mounted() {
        this.$gtm.trackEvent({
            event: "productView",
            productTitle: this.product.data.attributes.title,
            productCost: this.product.data.attributes.cost,
            productArticleNumber: this.product.data.attributes.article_number,
            noninteraction: false,
        });
        this.$gtm.trackEvent({
            event: 'flushVariables',
            ecommerce: null,
        });
        this.$gtm.trackEvent({
            event: 'view_item',
            pageType: 'productPage',
            ecommerce: {
                'detail': {
                    'products': [{
                        name: this.product.data.attributes.title,
                        id: this.product.data.attributes.article_number,
                        price: this.product.data.attributes.discount != null ? this.product.data.attributes.cost - this.product.data.attributes.discount.data.attributes.amount : this.product.data.attributes.cost,
                        category: this.product.data.attributes.category.data.attributes.title,
                    }]
                }
            },
        });
    },
}
</script>

<style scoped>
.scroll-down {
    overflow: hidden;
}
</style>
